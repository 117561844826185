import {
    MESSAGES_LOADING_INITIAL_FAILURE,
    MESSAGES_LOADING_INITIAL_INIT_SERVER_COUNT,
    MESSAGES_LOADING_INITIAL_REQUEST,
    MESSAGES_LOADING_INITIAL_SUCCESS,
    MESSAGES_LOADING_NEW_FAILURE,
    MESSAGES_LOADING_NEW_REQUEST,
    MESSAGES_LOADING_NEW_SUCCESS,
    MESSAGES_LOADING_OLDEST_FAILURE,
    MESSAGES_LOADING_OLDEST_REQUEST,
    MESSAGES_LOADING_OLDEST_SUCCESS,
} from '@actions/message';

import { LoadingStatus } from '@libs/LoadingStatus';
import { MongoId } from '@libs/types';

import { Message } from '@api/common/messages/fetchMessages';

type LoadingState<Data> = {
    status: LoadingStatus;
    error: unknown | null;
    data: Data | null;
};

const defaultLoadingState: LoadingState<unknown> = {
    status: 'idle',
    error: null,
    data: null,
};

export type MessageLoadingState = {
    mainState: {
        threadId: MongoId | null;
        requestedMessageId: MongoId | null;
        loadedRecentMsgAsc: Message[] | null;
        serverCount: number | null;
    };
    loadingInitial: LoadingState<unknown>;
    loadingNew: LoadingState<unknown>;
    loadingOldest: LoadingState<unknown>;
};

const initialState: MessageLoadingState = {
    mainState: {
        threadId: null,
        requestedMessageId: null,
        loadedRecentMsgAsc: null,
        serverCount: null,
    },
    loadingInitial: defaultLoadingState,
    loadingNew: defaultLoadingState,
    loadingOldest: defaultLoadingState,
};

export default function reducer(prevState = initialState, action): MessageLoadingState {
    switch (action.type) {
        case MESSAGES_LOADING_INITIAL_REQUEST: {
            const loadingInitial: typeof prevState.loadingInitial = {
                ...prevState.loadingInitial,
                status: 'loading',
            };

            const mainState = {
                threadId: action.threadId,
                requestedMessageId: action.requestedMessageId,
                loadedRecentMsgAsc: null,
                serverCount: null,
            };

            return {
                ...prevState,
                mainState,
                loadingInitial: loadingInitial,
                loadingNew: defaultLoadingState,
                loadingOldest: defaultLoadingState,
            };
        }

        case MESSAGES_LOADING_INITIAL_INIT_SERVER_COUNT: {
            const mainState = {
                ...prevState.mainState,
                serverCount: action.count,
            };

            return {
                ...prevState,
                mainState,
            };
        }

        case MESSAGES_LOADING_INITIAL_FAILURE: {
            const loadingInitial: typeof prevState.loadingInitial = {
                ...prevState.loadingInitial,
                status: 'failure',
                error: action.error,
            };
            return {
                ...prevState,
                loadingInitial,
            };
        }

        case MESSAGES_LOADING_INITIAL_SUCCESS: {
            const loadingInitial: typeof prevState.loadingInitial = {
                ...prevState.loadingInitial,
                status: 'success',
            };

            return {
                ...prevState,
                loadingInitial,
            };
        }

        case MESSAGES_LOADING_OLDEST_REQUEST: {
            const loadingOldest: typeof prevState.loadingOldest = {
                ...prevState.loadingOldest,
                status: 'loading',
            };

            return {
                ...prevState,
                loadingOldest,
            };
        }
        case MESSAGES_LOADING_OLDEST_FAILURE: {
            const loadingOldest: typeof prevState.loadingOldest = {
                ...prevState.loadingOldest,
                status: 'failure',
                error: action.error,
            };

            return {
                ...prevState,
                loadingOldest,
            };
        }

        case MESSAGES_LOADING_OLDEST_SUCCESS: {
            const { messages } = action;
            const loadingOldest: typeof prevState.loadingOldest = {
                ...prevState.loadingOldest,
                status: 'success',
                data: messages,
            };
            const mainState = {
                ...prevState.mainState,
                loadedRecentMsgAsc: messages.concat(prevState.mainState.loadedRecentMsgAsc || []),
            };

            return {
                ...prevState,
                mainState,
                loadingOldest,
            };
        }

        case MESSAGES_LOADING_NEW_REQUEST: {
            const loadingNew: typeof prevState.loadingNew = {
                ...prevState.loadingNew,
                status: 'loading',
            };

            return {
                ...prevState,
                loadingNew,
            };
        }
        case MESSAGES_LOADING_NEW_FAILURE: {
            const loadingNew: typeof prevState.loadingNew = {
                ...prevState.loadingNew,
                status: 'failure',
                error: action.error,
            };

            return {
                ...prevState,
                loadingNew,
            };
        }

        case MESSAGES_LOADING_NEW_SUCCESS: {
            const { messages } = action;
            const loadingNew: typeof prevState.loadingNew = {
                ...prevState.loadingNew,
                status: 'success',
                data: messages,
            };
            const mainState = {
                ...prevState.mainState,
                loadedRecentMsgAsc: (prevState.mainState.loadedRecentMsgAsc || []).concat(messages),
                serverCount: prevState.mainState.serverCount + messages.length,
            };

            return {
                ...prevState,
                mainState,
                loadingNew,
            };
        }

        default:
            return prevState;
    }
}
