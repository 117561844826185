import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import { selectApiContacts } from '@actions/message';
import { createAsyncThunk } from '@reduxjs/toolkit';

import IconButton from '@mui/material/IconButton';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';

import { LoadingHandler } from '@components/generic/LoadingView';
import PageTabTitle from '@components/generic/PageTabTitle/PageTabTitle';
import { AffiliateProfileSelect } from '@components/publisher/common/AffiliateProfileSelect';

import { ComplexSet } from '@libs/ComplexSet';
import { createAsyncThunkSlice } from '@libs/createAsyncThunkSlice';
import { getCurrentUser, ParamTypes } from '@libs/getSharedVar';
import { hasPermission } from '@libs/hasPermission';
import { RootState, useAppDispatch, useAppSelector } from '@libs/reduxHooks';

import {
    ApiData,
    listContacts as apiListContacts,
} from '@api/common/messages/listContactsPublisher';

import { MessageEditor } from '../MessageEditor';
import { ContactsPublisherSelect } from './ContactsPublisherSelect';
import { DebouncedInputBase } from './Search';

const NewMessagePublisher: React.FC = () => {
    const { t } = useTranslation();
    const apiData = useAppSelector((state) => selectApiContacts(state).data!);
    const [senderId, _setSenderId] = useState<string>();
    const [searchText, setSearchText] = useState('');
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
    const addContact = (contact: Contact) =>
        setSelectedContacts((selectedContacts) => selectedContacts.concat(contact));
    const removeContact = (contact: Contact) =>
        setSelectedContacts((selectedContacts) =>
            selectedContacts.filter((c) => !isContactEqual(c, contact)),
        );

    const clearContacts = () => setSelectedContacts([]);
    const setSenderId = (senderId: string) => {
        clearContacts();
        _setSenderId(senderId);
    };

    const selectableContacts: Contact[] = useMemo(
        () => getSelectableContacts(apiData, senderId),
        [apiData, senderId],
    );

    const matchedContacts: Contact[] = useMemo(
        () => searchContact(apiData, selectableContacts, searchText),
        [apiData, selectableContacts, searchText],
    );

    const pathParams = useParams<ParamTypes>();
    const pathPageThreadsList = generatePath('/:locale/:side/messages', pathParams);

    return (
        <div className='newMessageCommon'>
            <PageTabTitle>{t('helmet_title_message_new')}</PageTabTitle>

            <div className='newMessageHeader'>
                <div className='headerRow rowTitle'>
                    <Link to={pathPageThreadsList}>
                        <IconButton size='large'>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Link>
                    <span className='title'>{t('messaging_new_message_header_title')}</span>
                </div>

                <div className='headerRow rowFrom'>
                    <span className='recipientFrom'>
                        {t('messaging_new_message_header_from')} :
                    </span>
                    <AffiliateProfileSelect
                        value={senderId}
                        onChange={setSenderId}
                        filterEnabled={(affiliateProfile) =>
                            hasPermission(
                                getCurrentUser().permissions,
                                'messages',
                                affiliateProfile.id,
                            )
                        }
                        autoSelect
                    />
                </div>

                <div className='headerRow rowTo'>
                    <div className='recipientPart'>
                        <span className='recipientTo'>
                            {t('messaging_new_message_header_to')} :
                        </span>
                        <div className='recipientList'>
                            {selectedContacts.map((contact) => (
                                <TagContact
                                    key={JSON.stringify(contact)}
                                    contact={contact}
                                    onRemove={() => removeContact(contact)}
                                />
                            ))}
                            <DebouncedInputBase
                                className='searchInput'
                                placeholder={
                                    selectedContacts.length > 0
                                        ? ''
                                        : t('messaging_new_message_header_search_placeholder')
                                }
                                value={searchText}
                                onChange={setSearchText}
                            />
                        </div>
                        {selectedContacts.length > 0 && (
                            <span className='deleteRecipientIconWrapper'>
                                <CloseIcon
                                    className='deleteAllRecipientIcon'
                                    onClick={clearContacts}
                                />
                            </span>
                        )}
                    </div>
                    {selectedContacts.length > 0 && (
                        <span className='userCount'>
                            {selectedContacts.length} {t('messaging_new_message_header_recipient')}
                            {selectedContacts.length > 1 ? 's' : ' '}
                        </span>
                    )}
                </div>
            </div>

            <div className='selectorsArea'>
                <ContactsPublisherSelect
                    contacts={matchedContacts}
                    selected={selectedContacts}
                    onSelect={(contact, isSelected) =>
                        isSelected ? addContact(contact) : removeContact(contact)
                    }
                    searchText={searchText}
                />
            </div>

            <MessageEditor isGlobal sender={senderId} recipients={selectedContacts} />
        </div>
    );
};

const NewMessagePublisherWithLoadingHandler: React.FC = () => {
    const loadingStatus = useAppSelector((state) => selectApiContacts(state).status);
    const dispatch = useAppDispatch();
    return (
        <LoadingHandler status={loadingStatus} startLoading={() => dispatch(listContacts())}>
            <NewMessagePublisher />
        </LoadingHandler>
    );
};
NewMessagePublisherWithLoadingHandler.displayName = `withLoadingHandler(${NewMessagePublisher.displayName})`;
export default NewMessagePublisherWithLoadingHandler;

function TagContact({
    contact,
    onRemove,
}: {
    contact: Contact;
    onRemove: (contact: Contact) => void;
}) {
    const apiData = useAppSelector((state) => selectApiContacts(state).data);
    if (apiData == null) {
        throw new Error('api data not loaded');
    }
    const program = apiData.programs[contact.program];
    const user = apiData.users[contact.user];

    return (
        <Tag
            name={`${program.title} - ${user.firstname} ${user.lastname.toUpperCase()}`}
            onRemove={onRemove}
        />
    );
}

// todo extract
function Tag({ name, onRemove }) {
    return (
        <div className='recipient'>
            <span>{name}</span>
            <CloseIcon fontSize={'small'} onClick={onRemove} className='deleteRecipientIcon' />
        </div>
    );
}

//#region
function getSelectableContacts(apiData: ApiData, senderId: string): Contact[] {
    const programs: string[] = Object.values(apiData.partnerships)
        .filter((p) => p.affiliateProfile === senderId)
        .reduce((list: string[], p) => [...list, p.program], []);
    const contacts: Contact[] = programs
        .map((apId) => getContactForProgram(apiData, apId))
        .reduce((set, contacts) => set.union(new ComplexSet(contacts)), new ComplexSet<Contact>())
        .values();
    return contacts;
}

// A possible evolution could be that an affiliate profile could have multiple associated
// contact. Not only the one from its manager but also the other users related to its
// publisher account.
export function getContactForProgram(apiData: ApiData, programId: string): Contact[] {
    const program = apiData.programs[programId];
    if (program == null) {
        // throw new Error(`program ${programId} not loaded`);
        // todo report error to sentry
        return [];
    }

    const userId = program.manager;
    const user = apiData.users[userId];
    if (user == null) {
        // throw new Error(`user ${userId} not loaded`);
        // todo report error to sentry
        return [];
    }
    return [
        {
            program: programId,
            user: userId,
        },
    ];
}

export function searchContact(
    apiData: ApiData,
    contacts: Contact[],
    searchText: string,
): Contact[] {
    let regex;
    try {
        regex = new RegExp(searchText, 'i');
    } catch (error) {
        return [];
    }

    return contacts.filter((contact) => {
        const user = apiData.users[contact.user];
        const program = apiData.programs[contact.program];
        return regex.test(program.title) || regex.test(`${user.firstname} ${user.lastname}`);
    });
}
//#endregion

//#region

// todo rename to ContactPublisher
export interface Contact {
    program: string;
    user: string;
}

export function isContactEqual(contact: Contact, newContact: Contact): boolean {
    return contact.program === newContact.program && contact.user === newContact.user;
}
//#endregion

export const selectNewMessagePublisher = (state: RootState) => state.message.newMessagePublisher;
export const listContacts = createAsyncThunk('newMessagePublisher/listContacts', apiListContacts);
const slice = createAsyncThunkSlice('newMessagePublisher/listContacts', listContacts);
export const { reducer } = slice;
