import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { buildQueryString, checkStatus, getAPISubUrlForPublisher } from '@api/common/utils';

type Query = {
    affiliateProfile: MongoId;
    program?: MongoId;
};

export const getPublisherBankInfosDetails = async (profileId: MongoId, programId?: MongoId) => {
    const query: Query = { affiliateProfile: profileId };
    if (programId) {
        query.program = programId;
    }
    const URL = `${getAPISubUrlForPublisher()}/publishers/bankDetails.check${buildQueryString(
        query,
    )}`;
    const options = {
        method: 'GET',
        headers: { Authorization: getJwt() },
    };

    const response = await fetch(URL, options);
    await checkStatus(response, 200);
    const body = (await response.json()) as PublisherBankDetailsVerificationType;
    return body.payload.bankDetails;
};
export type PublisherBankDetailsVerificationType = {
    payload: {
        bankDetails: BankDetailsApiVerification;
    };
};
export type BankDetailsApiVerification = {
    countryRestriction: boolean;
    isVerified: boolean;
    missingData: string[];
    publisher: MongoId | undefined;
    affiliateProfile: MongoId | undefined;
    paymentIsAllowed: boolean;
    paymentAllowedReasons: string[] | undefined;
    preferredPaymentMethod: 'bank' | 'paypal' | undefined | null;
};
export type MemoizedBankDetailsApiVerification = {
    countryRestriction: boolean;
    isVerified: BankDetailsApiVerification['isVerified'];
    missingData: BankDetailsApiVerification['missingData'];
    publisher: MongoId | undefined;
    affiliateProfile: MongoId | undefined;
    paymentIsAllowed: boolean | undefined;
    paymentAllowedReasons: string[] | undefined;
    preferredPaymentMethod: BankDetailsApiVerification['preferredPaymentMethod'];
    entity: 'publisher' | 'affiliateProfile';
};

export const isBankDetailValid = (bankDetails: BankDetailsApiVerification): boolean =>
    bankDetails.paymentIsAllowed === true &&
    bankDetails.missingData.length === 0 &&
    bankDetails.paymentAllowedReasons?.length === 0;
