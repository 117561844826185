export function downloadFile(file: File): void {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadBlob(blob: Blob, filename: string): void {
    const file = new File([blob], filename);
    downloadFile(file);
}

export function openPdfInNewTab(blob: Blob, filename = '') {
    const file = new File([blob], filename, { type: 'application/pdf' });
    openFileInNewTab(file);
}

export function openFileInNewTab(file: File) {
    const url = URL.createObjectURL(file);
    window.open(url);
}
