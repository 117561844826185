import { getLocale } from '@libs/getSharedVar';

const currencyFormatter = (currencyCode: string, amount: number, isInteger?: boolean) => {
    if (currencyCode) {
        const locale = getLocale();
        const minimumFractionDigits = isInteger ? 0 : 2;
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: minimumFractionDigits,
        }).format(amount);
    }
};

export default currencyFormatter;
