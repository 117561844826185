import { getJwt } from '@libs/jwt';

import { getAPISubUrlForAdmin } from '../../common/utils';

export default function updateProductValidityStatus(productId, desiredStatus, message) {
    const request = buildRequest(productId, desiredStatus, message);
    const promise = fetch(request)
        .then((response) => {
            if (response.status !== 200) {
                throw new Error();
            }
            return response.json();
        })
        .then((body) => {
            const product = body.data;
            return product;
        });
    return promise;
}

function buildRequest(productId, desiredStatus, message) {
    const suffix = getEndpointSuffix(desiredStatus);
    const url = getAPISubUrlForAdmin() + `/products.${suffix}`;

    const payload = {
        id: productId,
    };
    if (message && desiredStatus == 'refused') {
        payload.reason = message;
    }
    const options = {
        method: 'Post',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    };

    return new Request(url, options);
}

function getEndpointSuffix(desiredStatus) {
    switch (desiredStatus) {
        case 'accepted':
            return 'accept';

        case 'refused':
            return 'refuse';

        default:
            throw new Error('incorrect status');
    }
}
