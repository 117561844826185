import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addFlash } from '@actions/common';
import {
    selectSelectedThread,
    sendGlobalMessage,
    sendMessage,
    updateEditorState,
} from '@actions/message';
import Editor from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import { EditorState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import _ from 'lodash';

import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SendIcon from '@mui/icons-material/Send';

import { usePromise } from '@hooks/usePromise';

import { getUserType } from '@libs/getSharedVar';
import { useAppDispatch, useAppSelector } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import { addMessageFile, deleteMessageFile } from '@api/common/messages';

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

interface ContactAdvertiser {
    affiliateProfile: string;
    user: string;
}

export interface MessagesOption {
    content: string;
    fileId?: string;
}

export interface UploadedFileInfo {
    s3FilePath: string;
    uploadId: string;
    filename: string;
    contentType: string;
}

type MessageEditorProps = {
    isDisabled?: boolean;
    isGlobal: boolean;
    sender: MongoId;
    recipients: ContactAdvertiser[];
};

export function MessageEditor({
    isDisabled = false,
    sender: _sender,
    recipients: _recipients,
    isGlobal = false,
}: MessageEditorProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const ref = useRef(null);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [wasEditorReset, setWasEditorReset] = useState(true);
    const shouldEditorReset = useAppSelector(
        (state) => state.message.message.messageEditor.shouldEditorReset,
    );
    const threadId = useAppSelector((state) => state.message.message.selectedThreadId);
    const currentThread = useAppSelector(selectSelectedThread);

    const [sendImageData, onSendImage, onSendImageReset] = usePromise(addMessageFile);
    const [deleteImageData, onDeleteImage, onDeleteImageReset] = usePromise(deleteMessageFile);
    const [attachment, setAttachment] = useState(null);

    function resetAttachment() {
        setAttachment(null);
        ref.current!.value = null;
    }

    function handleDeleteImage() {
        return onDeleteImage(sender, threadId, attachment?.uploadId);
    }

    function handleOnSendImage(event) {
        if (!event.target) return;
        if (attachment) {
            handleDeleteImage().then(() => {
                onSendImage(sender, event.target.files[0]);
            });
        } else {
            onSendImage(sender, event.target.files[0]);
        }
    }

    useEffect(() => {
        if (sendImageData.status === 'success') {
            setAttachment(sendImageData.data);
            onSendImageReset();
        } else if (sendImageData.status === 'failure') {
            addFlash('error', t('messaging_editor_send_file_failure'));
        } else if (deleteImageData.status === 'success') {
            resetAttachment();
            onDeleteImageReset();
        } else if (deleteImageData.status === 'failure') {
            addFlash('error', t('messaging_editor_delete_file_failure'));
        }
    }, [sendImageData.status, deleteImageData.status]);

    useEffect(() => {
        if (!attachment?.uploadId) return;
        resetAttachment();
        handleDeleteImage();
    }, [threadId]);

    let recipients:
            | { affiliateProfile: string; user: string }[]
            | { program: string; user: string }[],
        sender: string;
    if (isGlobal || !currentThread) {
        recipients = _recipients;
        sender = _sender;
    } else {
        const side = getUserType();
        sender =
            side === 'advertiser' ? currentThread.program.id : currentThread.affiliateProfile.id;

        recipients =
            side === 'advertiser'
                ? [
                      {
                          affiliateProfile: currentThread.affiliateProfile.id,
                          user: currentThread.affiliateProfileUser.id,
                      },
                  ]
                : [
                      {
                          program: currentThread.program.id,
                          user: currentThread.programUser.id,
                      },
                  ];
    }
    const onSend = (
        messagesOption: MessagesOption,
        sender: string,
        recipients: any[],
        isGlobal: boolean,
    ) => {
        if (isGlobal) {
            dispatch(sendGlobalMessage(messagesOption, sender, recipients));
        } else {
            dispatch(sendMessage(messagesOption, sender, recipients[0]));
        }
        resetAttachment();
    };

    const onChange = () => dispatch(updateEditorState());

    const EditorElRef = useRef(null);
    const toggleInlineStyle = (inlineStyle: string) => {
        const nextState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
        setEditorState(nextState);
    };
    // We use component state to have immediate feedback, and redux store to be hablee to empty it when needed
    const updateEditor = (newEditorState: SetStateAction<EditorState>) => {
        // update component state
        setEditorState(newEditorState);
        setWasEditorReset(false);
    };
    const resetEditor = () => {
        setWasEditorReset(true);
        const emptyState = EditorState.createEmpty();
        setEditorState(emptyState);
        onChange();
    };
    if (shouldEditorReset && !wasEditorReset) {
        resetEditor();
    }
    const messageOptions: MessagesOption = {
        content: stateToHTML(editorState.getCurrentContent()),
        fileId: attachment?.uploadId,
    };

    const regex = /(<([^>]+)>)/gi;
    const rawContent = messageOptions.content.replace(regex, '');

    return (
        <div className='messageEditorWrapper'>
            <div className='leftControls'>
                <div className='uploadIconContainers iconContainer'>
                    <IconButton
                        disableRipple
                        className='uploadButton'
                        size='small'
                        onClick={(event) => {
                            ref.current.click();
                        }}
                    >
                        <NoteAddIcon />
                    </IconButton>
                    <input
                        ref={ref}
                        type='file'
                        className='uploadInput'
                        id='uploadIput'
                        accept='image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/zip  '
                        onChange={(event) => handleOnSendImage(event)}
                    />
                </div>
                <div className='emojiSelectWrapper displayNoneMobile iconContainer'>
                    <EmojiSelect />
                </div>
            </div>
            <div className='middleBlock'>
                {attachment !== null ? (
                    <div className='filename'>
                        <span>{attachment?.filename}</span>
                        <CloseIcon
                            fontSize='small'
                            onClick={() => handleDeleteImage()}
                            className='deleteFileIcon'
                        />
                    </div>
                ) : (
                    ''
                )}
                <div className='draftWrapper' onClick={() => EditorElRef.current.focus()}>
                    <Editor
                        editorState={editorState}
                        placeholder={t('messaging_editor_placeholder')}
                        onChange={updateEditor}
                        plugins={[emojiPlugin]}
                        ref={EditorElRef}
                    />
                    <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
                </div>
            </div>
            <IconButton
                disableRipple
                className='sendButton'
                disabled={
                    isDisabled ||
                    (_.isEmpty(rawContent) && _.isEmpty(attachment)) ||
                    _.isEmpty(recipients)
                }
                onClick={() => {
                    onSend(messageOptions, sender, recipients, isGlobal);
                }}
                size='large'
            >
                <div className='buttonContainer'>
                    <SendIcon className='sendButtonIcon' />
                    <span className='sendButtonDisclaimer displayNoneMobile '>
                        {t('messaging_editor_send_disclaimer')}
                    </span>
                </div>
            </IconButton>
        </div>
    );
}

function StyleButton({ active, label, style, onToggle }) {
    const handleToggle = (e) => {
        e.preventDefault();
        onToggle(style);
    };

    let className = 'RichEditor-styleButton';
    if (active) {
        className += ' RichEditor-activeButton';
    }
    return (
        <span className={className} onMouseDown={handleToggle}>
            {label}
        </span>
    );
}

const INLINE_STYLES = [
    { label: 'B', style: 'BOLD' },
    { label: 'I', style: 'ITALIC' },
    { label: 'U', style: 'UNDERLINE' },
];
const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className='RichEditor-controls'>
            {INLINE_STYLES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </div>
    );
};
