import {
    FETCH_PUBLISHER_PARTNERSHIPS_FAILURE,
    FETCH_PUBLISHER_PARTNERSHIPS_SUCCESS,
    PUBLISHER_PARTNERSHIPS_LOADING,
    UPDATE_PUBLISHER_PARTNERSHIP_STATUS_SUCCESS,
} from '@actions/publisherPartnerships';

import { Status } from '@libs/lib_partnerships';
import { MongoId } from '@libs/types';

import { PartnershipPublisher, RequestPublisher } from '@api/common/partnerships/list';

export const rates = ['cpa', 'cpl', 'cpc'] as const;
export type Rates = (typeof rates)[number];

export type QueryCriteria = {
    affiliateProfile: string;
    programs: MongoId[];
    status: PartnershipPublisher['status'][];
    rates: ('cpc' | 'cpa' | 'cpl')[];
    search: string;
};

export type ReducerPublisherPartnershipState = {
    loading: boolean;
    data: PartnershipPublisher[] | null;
    error: unknown | null;
    queryCriteria: QueryCriteria;
};

const INITIAL_STATE: ReducerPublisherPartnershipState = {
    loading: false,
    data: null,
    error: null,
    queryCriteria: {
        affiliateProfile: '',
        programs: [],
        status: [],
        rates: [],
        search: '',
    },
};

export default function (state = INITIAL_STATE, action): ReducerPublisherPartnershipState {
    switch (action.type) {
        case PUBLISHER_PARTNERSHIPS_LOADING:
            return {
                ...state,
                loading: true,
                queryCriteria: action.payload,
            };
        case FETCH_PUBLISHER_PARTNERSHIPS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case FETCH_PUBLISHER_PARTNERSHIPS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case UPDATE_PUBLISHER_PARTNERSHIP_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        default:
            break;
    }

    return state;
}
