import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { buildQueryString, checkStatus, getAPISubUrlForPublisher } from '@api/common/utils';

export type listCurrenciesResponse = {
    affiliateProfile: MongoId;
    currencies: string[];
};

export async function listCurrencies(affiliateProfiles?: MongoId | MongoId[]): Promise<string[]> {
    const res = await fetch(
        getAPISubUrlForPublisher() +
            '/publishers.currency' +
            buildQueryString({ affiliateProfiles: affiliateProfiles }),
        { headers: { Authorization: getJwt() } },
    );
    await checkStatus(res);
    return await res.json();
}

export async function listCurrenciesBySubEntities({
    affiliateProfiles,
    groupBy,
}: {
    affiliateProfiles?: MongoId | MongoId[];
    groupBy?: 'affiliateProfile' | 'currency';
}): Promise<string[] | listCurrenciesResponse[]> {
    const res = await fetch(
        getAPISubUrlForPublisher() +
            '/publishers.currency' +
            buildQueryString({ affiliateProfiles: affiliateProfiles, groupBy: groupBy }),
        { headers: { Authorization: getJwt() } },
    );
    await checkStatus(res);
    return await res.json();
}
