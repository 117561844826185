import {
    CLOSE_DIALOGS,
    CREATE_AFFILIATE_PROFILE,
    CREATE_AFFILIATE_PROFILE_FAILURE,
    CREATE_AFFILIATE_PROFILE_SUCCESS,
    FETCH_PARTNERSHIPS,
    FETCH_PARTNERSHIPS_FAILURE,
    FETCH_PARTNERSHIPS_SUCCESS,
    UPDATE_AFFILIATE_PROFILE,
    UPDATE_AFFILIATE_PROFILE_FAILURE,
    UPDATE_AFFILIATE_PROFILE_SUCCESS,
} from '@actions/affiliateProfile';

import { PartnershipPublisher } from '@api/common/partnerships/list';

type ProfileState = {
    loading: boolean;
    initialProfile: Record<string, unknown>;
    profileDetails: Record<string, unknown>; // used for advertiser for now, see InfoPanelContainer
    isCreationOK: boolean;
    isCreationError: boolean;
    createdProfileId: string;

    isUpdateOK: boolean;
    isUpdateError: boolean;
    updatedProfileId: string;

    errorMessage: string;

    partnerships?: PartnershipPublisher[];
};

const INITIAL_STATE: ProfileState = {
    loading: false,
    initialProfile: {},
    profileDetails: {}, // used for advertiser for now, see InfoPanelContainer
    isCreationOK: false,
    isCreationError: false,
    createdProfileId: '',

    isUpdateOK: false,
    isUpdateError: false,
    updatedProfileId: '',

    errorMessage: '',
};

export default function (state = INITIAL_STATE, action): ProfileState {
    switch (action.type) {
        case CREATE_AFFILIATE_PROFILE:
            return {
                ...state,
                loading: true,
                isCreationOK: false,
            };
        case CREATE_AFFILIATE_PROFILE_SUCCESS:
            return {
                ...state,
                isCreationOK: true,
                // loading: false, the redirection is slow, so we leave the loader
                errorMessage: '',
            };
        case CREATE_AFFILIATE_PROFILE_FAILURE:
            return {
                ...state,
                isCreationError: true,
                loading: false,
                errorMessage: action.payload.error + ' : ' + action.payload.message,
            };
        case UPDATE_AFFILIATE_PROFILE:
            return {
                ...state,
                loading: true,
                isUpdateOK: false,
            };
        case UPDATE_AFFILIATE_PROFILE_SUCCESS:
            return {
                ...state,
                isUpdateOK: true,
                // loading: false, the redirection is slow, so we leave the loader
                errorMessage: '',
                updatedProfileId: action.affiliateProfileId,
            };
        case UPDATE_AFFILIATE_PROFILE_FAILURE:
            return {
                ...state,
                isUpdateError: true,
                loading: false,
                errorMessage: action.payload.error + ' : ' + action.payload.message,
            };
        case FETCH_PARTNERSHIPS:
            return {
                ...state,
            };
        case FETCH_PARTNERSHIPS_SUCCESS:
            return {
                ...state,
                partnerships: action.payload,
            };
        case FETCH_PARTNERSHIPS_FAILURE:
            return {
                ...state,
            };
        case CLOSE_DIALOGS:
            return {
                ...state,
                isUpdateOK: false,
                isUpdateError: false,
                isCreationOK: false,
                isCreationError: false,
            };
    }
    return state;
}
