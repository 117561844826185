import { ReactNode } from 'react';

import { BackgroundNotAuth } from '@components/notAuth/BackgroundNotAuth';
import { DialogNotAuth } from '@components/notAuth/DialogNotAuth';

export function PageNotAuthLayout({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) {
    return (
        <BackgroundNotAuth>
            <DialogNotAuth
                PaperProps={{
                    className,
                }}
            >
                {children}
            </DialogNotAuth>
        </BackgroundNotAuth>
    );
}
