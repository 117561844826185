import { CSSProperties } from 'react';
import { Helmet } from 'react-helmet';
import truncate from 'lodash/truncate';

import { useTakeOverSubEntityId } from '@providers/TakeOverProvider';

import { useOldAppState } from '@hooks/useOldAppState';

import { getCurrentSubEntityId, getUserType, getUserTypeFromUrl } from '@libs/getSharedVar';

export default function PageTabTitle({
    children,
    style,
}: {
    children: string;
    style?: CSSProperties;
}) {
    const side = getUserTypeFromUrl() || getUserType();
    const { programs, affiliateProfiles } = useOldAppState().state;

    const subEntities = side === 'advertiser' ? programs : affiliateProfiles;

    const takeOverSubEntityId = useTakeOverSubEntityId() ?? undefined;
    const activeSubEntityId = getCurrentSubEntityId() ?? takeOverSubEntityId;
    const activeSubEntity = (subEntities ?? []).find((el) => el.id === activeSubEntityId);
    const activeSubEntityShortName = truncate(activeSubEntity?.name, {
        length: 15,
        separator: ' ',
    });

    return (
        <Helmet>
            <title style={style}>
                {activeSubEntity
                    ? `${activeSubEntityShortName}` + ' - ' + `${children}`
                    : `${children}`}
            </title>
        </Helmet>
    );
}
