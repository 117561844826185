import { combineReducers } from 'redux';

import reducerFeedbackDialog from './feedbackDialog';
import reducerProductPanel from './productPanel';
import reducerProgramPanel from './programPanel';
import reducerTable from './table';

export default combineReducers({
    table: reducerTable,
    programPanel: reducerProgramPanel,
    productPanel: reducerProductPanel,
    feedbackDialog: reducerFeedbackDialog,
});
