import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import Dialog from '@mui/material/Dialog';

import { useOldAppState } from '@hooks/useOldAppState';

import { LoadingStatusCancelable } from '@libs/LoadingStatus';

import { MfaPaper } from './common';
import { MfaForm, MfaFormProps } from './MfaForm';

/**
 * This module provide a hook 'useMfa' to easily integrate a mfaDialog on any page.
 * Check the storybook story for an example.
 * For more advanced integration, like inserting the 2fa form inside another dialog, drawer, form, etc... use the MfaForm component
 */

type MfaContextType = {
    status: LoadingStatusCancelable;
    open: ({ onClose }: { onClose: MfaDialogProps['onClose'] }) => void;
};

const MfaContext = createContext<MfaContextType | null>(null);

export function MfaDialogProvider({ children }: { children: ReactNode }) {
    const user = useOldAppState().state.user!;
    const [status, setStatus] = useState<LoadingStatusCancelable>('idle');
    const [onClose, setOnClose] = useState<MfaDialogProps['onClose']>(() => undefined);

    const open: MfaContextType['open'] = useCallback(({ onClose }) => {
        setStatus('loading');
        setOnClose(() => onClose);
    }, []);

    const handleClose: MfaDialogProps['onClose'] = useCallback(
        (reason) => {
            switch (reason) {
                case 'cancel':
                    setStatus('cancel');
                    break;
                case 'success':
                    setStatus('success');
                    break;

                case 'failure':
                    setStatus('failure');
                    break;
            }
            onClose(reason);
        },
        [onClose],
    );

    return (
        <MfaContext.Provider value={{ status, open }}>
            {children}
            <MfaDialog isOpen={status === 'loading'} onClose={handleClose} user={user} />
        </MfaContext.Provider>
    );
}

export function useMfa() {
    const context = useContext(MfaContext);
    if (!context) {
        throw new Error('useMfa must be used within a MfaProvider');
    }
    return context;
}

export type MfaDialogProps = Omit<MfaFormProps, 'scenario'> & {
    isOpen: boolean;
};

export function MfaDialog({ isOpen, ...props }: MfaDialogProps) {
    return (
        <Dialog open={isOpen} onClose={() => props.onClose('cancel')} PaperComponent={MfaPaper}>
            <MfaForm scenario='confirm' {...props} />
        </Dialog>
    );
}
