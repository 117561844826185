import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { buildQueryString, checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

// todo check date format
export interface Quota {
    advertiser: MongoId;
    periodStart: unknown;
    periodEnd: unknown;
    createdAt: unknown;
    type: 'click';
    total: number;
    overQuota: number;
    stripeUnitUsed: number;
    stripeSubscriptionId: string;
}

export async function getQuota(): Promise<Quota | null> {
    const response = await fetch(
        getAPISubUrlForAdvertiser() +
            '/subscriptions/quotas.info' +
            buildQueryString({ type: 'click' }),
        { headers: { Authorization: getJwt() } },
    );
    await checkStatus(response);
    const body = await response.json();
    return body.quota;
}
