import './EmailDialogReminder.less';

import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { hasToken, tokenQueryKey } from '@pages/public/EmailValidationPage';

import LoadingButton from '@mui/lab/LoadingButton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import { DialogHeader } from '@components/generic/DialogHeader';
import { Tooltip } from '@components/generic/Tooltip';

import { usePromise } from '@hooks/usePromise';

import { emailSupport, getCurrentUser, isBlockedByMail } from '@libs/getSharedVar';

import { sendConfirmEmail } from '@api/user/sendConfirmEmail';

import mailboxIcon from './mailbox.svg';

export function EmailDialogReminder() {
    const { t } = useTranslation();
    const user = getCurrentUser();
    const token = new URL(window.location.href).searchParams.get(tokenQueryKey);
    const [isOpen, setIsOpen] = useState(
        user.emailConfirmedAt == null && !hasToken(token) && !isBlockedByMail(user),
    );
    const [loading, handleSend, resetLoading] = usePromise(sendConfirmEmail);

    function handleClose() {
        setIsOpen(false);
        resetLoading();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            className='emailDialogReminder'
            data-testid='emailDialogReminder'
        >
            <DialogHeader title={t('emailDialogReminder_title')} iconType='warning' />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ margin: '2rem' }}>
                    <img src={mailboxIcon} width={200} height={200} />
                </div>
                <div style={{ margin: '0 2rem 0 2rem', color: '#212121', textAlign: 'center' }}>
                    <Trans
                        i18nKey='emailDialogReminder_description'
                        components={{
                            linkto: <a href={`mailto:${emailSupport}`}>{emailSupport}</a>,
                        }}
                    />
                </div>
            </div>
            <DialogActions style={{ justifyContent: 'end' }}>
                <LoadingButton onClick={handleClose} color='quiet' variant='text'>
                    {t('generic_close')}
                </LoadingButton>
                <LoadingButton
                    onClick={handleSend}
                    color='primary'
                    variant='contained'
                    disabled={loading.status === 'loading' || loading.status === 'success'}
                    loading={loading.status === 'loading'}
                    data-testid='reSendMailBtn'
                    endIcon={
                        (loading.status === 'success' && (
                            <CheckIcon fontSize='inherit' style={{ color: 'green' }} />
                        )) ||
                        (loading.status === 'failure' && (
                            <Tooltip
                                title={
                                    loading.error.message ??
                                    t('messaging_error_view_basic_msg').toString()
                                }
                            >
                                <ErrorIcon color='error' fontSize='small' />
                            </Tooltip>
                        ))
                    }
                >
                    {loading.status !== 'success'
                        ? t('emailValidation_reSendMail')
                        : t('emailValidation_reSendMail_success')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
