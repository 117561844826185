import Box from '@mui/material/Box';

type FormPageTitleProps = {
    children: React.ReactNode;
    style?: React.CSSProperties;
};

const FormPageTitle = ({ children, style }: FormPageTitleProps) => {
    return (
        <Box
            role='heading'
            sx={{
                fontSize: '26px',
                fontWeight: '600',
                marginBottom: '2rem',
                color: '#1C2342',
                ...style,
            }}
        >
            {children}
        </Box>
    );
};

export default FormPageTitle;
