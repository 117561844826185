import { getJwt } from '@libs/jwt';

import { ApiError, checkStatus, getAPIBaseUrl } from '@api/common/utils';

import { User } from '../types';

export async function updateMethod(phone: string): Promise<User> {
    const response = await fetch(getAPIBaseUrl() + 'common/users/mfa.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone }),
    });
    await checkStatus(response, 200);
    const data = await response.json();
    return data.user as User;
}

export const errorIdMfaPhoneAlreadyTaken = 'mfa_phone_already_taken';
export function isErrorPhoneAlreadyTaken(error: unknown) {
    return error instanceof ApiError && error.errorMsg === errorIdMfaPhoneAlreadyTaken;
}
