import { AffiliateProfile } from '@components/admin/AffiliateProfiles/interfaces';

import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '../../common/utils';

export default async function apiList(fields?: string[]): Promise<AffiliateProfile[]> {
    // add eventual filters

    const params = new URLSearchParams();

    if (fields && fields.length > 0) {
        fields.forEach((field) => {
            params.append('fields', field);
        });
    }

    // Utiliser une valeur très grande pour simuler une limite "infinie"
    params.append('limit', Number.MAX_SAFE_INTEGER.toString());
    params.append('offset', '0');

    const url = getAPIBaseUrl() + 'admin/affiliateProfiles.list?' + params.toString();

    const response = await fetch(url, {
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });
    await checkStatus(response, 200);
    const body: AffiliateProfile[] = await response.json();
    return body;
}
