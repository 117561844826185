import { checkStatus, getAPIBaseUrl } from './utils';

export interface Countries {
    name: string;
    code: string;
}

export async function fetchCountries() {
    const response = await fetch(getAPIBaseUrl() + 'common/countries.list');
    await checkStatus(response, 200);
    const body = await response.json();
    return body.countries as Countries[];
}
