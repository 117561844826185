import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import { Tooltip } from '@components/generic/Tooltip';

import { usePromise } from '@hooks/usePromise';

import { sendConfirmEmail } from '@api/user/sendConfirmEmail';

import { unauthorizedPagesStyles } from './UnauthorizedBySubscription';
import UnauthorizedPage from './UnauthorizedPage';

export default function UnauthorizedByMailsPage() {
    const { t } = useTranslation();
    const [loading, handleSend] = usePromise(sendConfirmEmail);
    return (
        <UnauthorizedPage>
            <h1 style={unauthorizedPagesStyles.title}>
                {t('unauthorizedByMailsPage_description')}
            </h1>
            <div style={unauthorizedPagesStyles.container}>
                <Button component={Link} to='my-details'>
                    {t('unauthorizedByMailsPage_update_email')}
                </Button>
                <LoadingButton
                    onClick={handleSend}
                    color='secondary'
                    variant='contained'
                    disabled={loading.status === 'loading' || loading.status === 'success'}
                    loading={loading.status === 'loading'}
                    endIcon={
                        (loading.status === 'success' && (
                            <CheckIcon fontSize='inherit' style={{ color: 'green' }} />
                        )) ||
                        (loading.status === 'failure' && (
                            <Tooltip
                                title={
                                    loading.error.message ??
                                    t('messaging_error_view_basic_msg').toString()
                                }
                            >
                                <ErrorIcon color='error' fontSize='small' />
                            </Tooltip>
                        ))
                    }
                >
                    {loading.status !== 'success'
                        ? t('emailValidation_reSendMail')
                        : t('emailValidation_reSendMail_success')}
                </LoadingButton>
            </div>
        </UnauthorizedPage>
    );
}
