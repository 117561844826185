import {
    CLOSE_DOCKS,
    DELETE_SAVED_REPORT,
    DELETE_SAVED_REPORT_FAILURE,
    DELETE_SAVED_REPORT_SUCCESS,
    FETCH_PUBLISHER_REPORTING_DATA,
    FETCH_PUBLISHER_REPORTING_DATA_FAILURE,
    FETCH_PUBLISHER_REPORTING_DATA_SUCCESS,
    FETCH_SAVED_REPORTS,
    FETCH_SAVED_REPORTS_FAILURE,
    FETCH_SAVED_REPORTS_SUCCESS,
    FETCH_USER_PREFERENCES,
    FETCH_USER_PREFERENCES_FAILURE,
    FETCH_USER_PREFERENCES_SUCCESS,
    OPEN_AFFILIATE_PROFILE_SAVED_REPORT_DOCK,
    OPEN_SAVE_REPORT_DOCK,
    PUBLISHER_REPORTING_LOADING,
    SAVE_REPORT,
    SAVE_REPORT_FAILURE,
    SAVE_REPORT_SUCCESS,
    UPDATE_CURRENCIES,
    UPDATE_CURRENT_GRAPH_KPI_ID,
    UPDATE_SELECTED_ADS,
    UPDATE_SELECTED_PARTNERSHIPS,
    UPDATE_SELECTED_RULES,
    UPDATE_USER_PREFERENCES,
    UPDATE_USER_PREFERENCES_FAILURE,
    UPDATE_USER_PREFERENCES_SUCCESS,
    UPDATE_VISIBLE_COLUMNS,
} from '@actions/publisherReporting';

// Initial query is for the 30 last days
const initialFrom = new Date();
initialFrom.setDate(initialFrom.getDate() - 30);
initialFrom.setHours(0, 0, 0, 0);

const initialTo = new Date();
initialTo.setHours(23, 59, 59, 999);

const INITIAL_STATE = {
    publisher: {
        data: {},
        filteredData: {},
        loading: false,
        error: null,
        queryCriteria: {
            reportTitle: '',
            affiliateProfile: '',
            affiliateProfileName: '',
            currency: '',
            from: initialFrom.toISOString(), // must be ISO date
            to: initialTo.toISOString(), // must be ISO date
            timezone: '',
            scale: 'day', // can be 'year', 'month', 'day', 'hour'
            kind: 'partnerships', // can be 'partnerships', 'ads', 'rules'
            jwt: '',
            endpoint: '',
            status: 'all', // can be 'all', 'active', 'deleted'
            partnership: '',
            title: '',
        },
        visibleColumns: [
            'id',
            'name',
            'preview',
            'impressions',
            'clicks',
            'rpc',
            'allContributions',
            'allConversions',
            'allCommissions',
            'revenueOnConversions',
        ],

        selectedPartnerships: new Map(),
        selectedAds: new Map(),
        selectedRules: new Map(),
        selectedProducts: new Map(),
        selectedGroups: new Map(),

        currentGraphKpiId: 'events',
        currencies: [],
        isOpenSaveReportDock: false,
        isOpenAffiliateProfileSavedReportDock: false,

        savedReports: [],
    },
};

export default function (state = INITIAL_STATE, action) {
    let error;

    switch (action.type) {
        // ADVERTISER REPORTING
        case PUBLISHER_REPORTING_LOADING:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    queryCriteria: action.queryCriteria,
                    loading: true,
                },
            };
        case FETCH_PUBLISHER_REPORTING_DATA:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    loading: true,
                },
            };
        case FETCH_PUBLISHER_REPORTING_DATA_SUCCESS:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    data: action.payload,
                    queryCriteria: action.queryCriteria,
                    error: error,
                    loading: false,
                },
            };
        case FETCH_PUBLISHER_REPORTING_DATA_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    data: {},
                    error: error,
                    loading: false,
                },
            };
        case FETCH_SAVED_REPORTS:
            return {
                ...state,
            };
        case FETCH_SAVED_REPORTS_SUCCESS:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    savedReports: action.payload,
                },
            };
        case FETCH_SAVED_REPORTS_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    error: error,
                },
            };
        case UPDATE_SELECTED_PARTNERSHIPS:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    selectedPartnerships: action.selectedPartnerships,
                },
            };
        case UPDATE_VISIBLE_COLUMNS:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    visibleColumns: action.visibleColumns,
                },
            };
        case UPDATE_SELECTED_ADS:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    selectedAds: action.selectedAds,
                },
            };
        case UPDATE_SELECTED_RULES:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    selectedRules: action.selectedRules,
                },
            };
        case UPDATE_CURRENT_GRAPH_KPI_ID:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    currentGraphKpiId: action.graphKpiId,
                },
            };
        case UPDATE_CURRENCIES:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    currencies: action.currencies,
                },
            };

        case FETCH_USER_PREFERENCES:
            return {
                ...state,
            };
        case FETCH_USER_PREFERENCES_SUCCESS:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    visibleColumns: action.visibleColumns,
                },
            };
        case FETCH_USER_PREFERENCES_FAILURE:
            return {
                ...state,
            };

        case UPDATE_USER_PREFERENCES:
            return {
                ...state,
            };
        case UPDATE_USER_PREFERENCES_SUCCESS:
            return {
                ...state,
            };
        case UPDATE_USER_PREFERENCES_FAILURE:
            return {
                ...state,
            };

        case SAVE_REPORT:
            return {
                ...state,
            };
        case SAVE_REPORT_SUCCESS:
            return {
                ...state,
            };
        case SAVE_REPORT_FAILURE:
            return {
                ...state,
            };

        case DELETE_SAVED_REPORT:
            return {
                ...state,
            };
        case DELETE_SAVED_REPORT_SUCCESS:
            return {
                ...state,
            };
        case DELETE_SAVED_REPORT_FAILURE:
            return {
                ...state,
            };
        case OPEN_SAVE_REPORT_DOCK:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    isOpenSaveReportDock: true,
                },
            };
        case OPEN_AFFILIATE_PROFILE_SAVED_REPORT_DOCK:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    isOpenAffiliateProfileSavedReportDock: true,
                },
            };
        case CLOSE_DOCKS:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    isOpenSaveReportDock: false,
                    isOpenAffiliateProfileSavedReportDock: false,
                },
            };
        default:
            break;
    }

    return state;
}
