import { PermissionName, Permissions, PermissionsSubEntity, User } from '@api/user/types';

// todo: write unit test

/**
 * An utility function to easily check if a user have a permission
 */
export function hasPermission(
    userOrPermissions: User | Permissions,
    askedPermission: PermissionName | 'any',
    subEntityId: 'all' | 'any' | string | undefined = askedPermission !== 'admin'
        ? 'any'
        : undefined, // force to specify subEntityId Id when we want to check if admin on subEntityId
    // hasPermission(permission, 'admin') => check if global admin
    // hasPermission(permission, 'admin', program1Id) => check if admin for program1
    //
): boolean {
    const permissions = instanceOfUser(userOrPermissions)
        ? userOrPermissions.permissions
        : userOrPermissions;

    if (askedPermission === 'owner') {
        return permissions === 'owner';
    }

    if (askedPermission === 'admin' && subEntityId == null) {
        return permissions === 'owner' || permissions === 'admin';
    }

    if (permissions === 'owner' || permissions === 'admin') {
        return true;
    }

    if (['marketplace', 'edit', 'billing'].includes(askedPermission)) {
        return permissions[askedPermission];
    }

    const permissionsSubEntities = (function () {
        if (permissions.programs != null) {
            return permissions.programs;
        } else if (permissions.affiliateProfiles != null) {
            return permissions.affiliateProfiles;
        } else {
            throw new Error('incorrect permissions: missing program/affiliateProfile');
        }
    })();

    if (subEntityId == null) {
        throw new Error(`need a subEntity id to check permission '${askedPermission}'`);
    }

    if (permissionsSubEntities === 'admin') {
        return true;
    }

    switch (askedPermission) {
        case 'admin':
            return checkPermissionsSubEntities(
                permissionsSubEntities,
                isSubEntityAdmin,
                subEntityId,
            );
        case 'any':
            return checkPermissionsSubEntities(
                permissionsSubEntities,
                hasAnyPermissionOnSubEntity,
                subEntityId,
            );
        default:
            return checkPermissionsSubEntities(
                permissionsSubEntities,
                (permissions) => permissions[askedPermission] === true,
                subEntityId,
            );
    }
}

function checkPermissionsSubEntities(
    permissions: { [id: string]: PermissionsSubEntity },
    checkSubEntity: (permissions: PermissionsSubEntity) => boolean,
    subEntityId: string,
): boolean {
    if (subEntityId === 'all') {
        return Object.values(permissions).every(checkSubEntity);
    } else if (subEntityId === 'any') {
        return Object.values(permissions).some(checkSubEntity);
    } else {
        return permissions[subEntityId] != null ? checkSubEntity(permissions[subEntityId]) : false;
    }
}

function isSubEntityAdmin(permissions: PermissionsSubEntity) {
    return (Object.values(permissions) as Array<boolean>).every((bool) => bool);
}

function hasAnyPermissionOnSubEntity(permissions: PermissionsSubEntity) {
    return (Object.values(permissions) as Array<boolean>).some((e) => e);
}

function instanceOfUser(value: any): value is User {
    return typeof value === 'object' && 'firstname' in value;
}
