import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { getDefaultUserType } from '@libs/getSharedVar';

import { OldState, useOldAppState } from './useOldAppState';

export function useIsOnBoardingComplete(): boolean {
    const oldAppState = useOldAppState().state;
    const user = oldAppState.user;
    const isSessionLoaded = user != null;
    if (!isSessionLoaded) throw new Error('Session not loaded');

    const side = getDefaultUserType(user);
    const isOnBoardingComplete = useMemo(() => {
        switch (side) {
            case 'publisher':
                return isOnBoardingOverPublisher(oldAppState);
            case 'advertiser':
                return isOnBoardingOverAdvertiser(oldAppState);
            case 'admin':
                return true;
        }
    }, [side, oldAppState]);

    return isOnBoardingComplete;
}

type Step = Readonly<{
    name: string;
    done: (oldAppState: OldState) => boolean;
}>;

export const advertiserOnBoardingSteps: readonly Step[] = [
    {
        name: 'hasCreatedAProgram',
        done: (oldAppState) => oldAppState.programs!.length > 0,
    },
    {
        name: 'hasFillBillingDetails',
        done: (oldAppState) => !isEmpty(oldAppState.advertiser!.type), // type is always mandatory, So we can use it to know if user filled advertiser form
    },
    {
        name: 'hasFindAPartnership',
        done: () => false, // todo (condition never exist since creation...)
    },
];

export function getStepProgress(steps: readonly Step[], oldAppState: OldState) {
    let progress = 0;
    for (const step of steps) {
        if (step.done(oldAppState) === false) {
            break;
        }
        progress++;
    }
    return progress;
}

export function isOnBoardingOverAdvertiser(oldAppState: OldState) {
    return (
        getStepProgress(advertiserOnBoardingSteps, oldAppState) ===
        advertiserOnBoardingSteps.length - 1
    ); // deliberately ignore last steps
}

export const publisherOnBoardingSteps: Step[] = [
    {
        name: 'hasCreatedAProfile',
        done: (oldAppState) => !isEmpty(oldAppState.affiliateProfiles!),
    },
    {
        name: 'hasFillContactDetails',
        done: (oldAppState) => !isEmpty(oldAppState.publisher!.type),
    },
    {
        name: 'hasFillBillingDetails',
        done: (oldAppState) =>
            !isEmpty(oldAppState.publisher!.paypalAccount) ||
            !isEmpty(oldAppState.publisher!.iban) ||
            !isEmpty(oldAppState.publisher!.bic),
    },
];

export function isOnBoardingOverPublisher(oldAppState: OldState) {
    return (
        getStepProgress(publisherOnBoardingSteps, oldAppState) === publisherOnBoardingSteps.length
    );
}
