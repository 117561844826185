import _ from 'lodash';

import { FilterData } from '@api/admin/products/fetchProducts';

export function getDefaultFilterFromUrl() {
    const url = window.location.href;
    const parameters = getParametersFromUrl(url);
    const filterData = new FilterData();

    if (parameters.hasOwnProperty('statuses')) {
        const statuses = parameters.statuses;
        filterData.validityStatuses = toArray(statuses);
    }

    if (parameters.hasOwnProperty('brands')) {
        const brands = parameters.brands;
        filterData.programIds = toArray(brands);
    }

    if (parameters.hasOwnProperty('isFeatured')) {
        let isFeatured = stringToBoolean(parameters.isFeatured);
        if (isFeatured === undefined) {
            isFeatured = null;
        }
        filterData.isFeatured = isFeatured;
    }

    if (parameters.hasOwnProperty('search')) {
        const search = String(parameters.search);
        filterData.searchText = search;
    }

    return filterData;
}

const toArray = (value) => {
    const copy = _.cloneDeep(value);
    if (Array.isArray(copy)) {
        return copy;
    } else if (typeof copy === 'string') {
        return [copy];
    } else {
        return [];
    }
};

function stringToBoolean(str) {
    if (str === 'true') {
        return true;
    } else if (str === 'false') {
        return false;
    } else {
        return undefined;
    }
}

function getParametersFromUrl(urlStr) {
    const parameters = {};

    const url = new URL(urlStr);
    for (const [key, value] of url.searchParams) {
        if (!parameters.hasOwnProperty(key)) {
            parameters[key] = value;
        } else {
            if (!Array.isArray(parameters[key])) {
                parameters[key] = [parameters[key]];
            }
            parameters[key].push(value);
        }
    }
    return parameters;
}

/**
 *
 * @param {FilterData} filterData
 */
export function updateUrl(filterData) {
    const title = document.title;
    const url = buildUrlFromFilter(filterData);
    window.history.replaceState({}, title, url);
}

/**
 *
 * @param {FilterData} filterData
 */
function buildUrlFromFilter(filterData) {
    const baseUrl = getCurrentBaseUrl();

    const queryString = buildQueryString(filterData);
    if (_.isEmpty(queryString)) {
        return baseUrl;
    } else {
        return baseUrl + '?' + queryString;
    }
}

function buildQueryString(filterData) {
    const keysValues = [];
    if (!_.isEmpty(filterData.validityStatuses)) {
        const statuses = filterData.validityStatuses;
        for (const status of statuses) {
            keysValues.push(`statuses=${status}`);
        }
    }

    if (!_.isEmpty(filterData.programIds)) {
        const brands = filterData.programIds;
        for (const brand of brands) {
            keysValues.push(`brands=${brand}`);
        }
    }

    if (filterData.isFeatured != null) {
        keysValues.push(`isFeatured=${filterData.isFeatured}`);
    }

    if (!_.isEmpty(filterData.searchText)) {
        keysValues.push(`search=${filterData.searchText}`);
    }
    const queryString = keysValues.join('&');
    return queryString;
}

function getCurrentBaseUrl() {
    const { protocol, host, pathname } = window.location;
    return protocol + '//' + host + pathname;
}
