import { checkStatus, getAPIBaseUrl } from '@api/common/utils';

export type Plans = { [name: string]: Plan };

type PriceByCurrency = Record<Currency, number>;

export type Plan = {
    title: string;
    stripe_id: string;
    /** in cents */
    price: {
        monthly: PriceByCurrency;
        yearly: PriceByCurrency;
    };
    quota_impressions: number;
    quota_clicks: number;
    quota_conversions: number;
    quota_programs?: number;
    has_support: boolean;
    has_ssl: boolean;
};

export type Currency = 'eur' | 'usd' | 'gbp';

export default async function listPlans(): Promise<Plans> {
    const response = await fetch(getAPIBaseUrl() + 'common/plans.list');
    await checkStatus(response);
    return (await response.json()).data;
}
