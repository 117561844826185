import { getJwt } from '@libs/jwt';

import { buildQueryString, checkStatus, getAPISubUrlForCurrentUser } from '../common/utils';

export default async function fetchAds(queryParameters) {
    const url = getAPISubUrlForCurrentUser() + '/ads.list' + buildQueryString(queryParameters);
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response, 200);
    const body = await response.json();
    const ads = body;
    return ads;
}
