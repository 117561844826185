import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { MfaDialogAction } from '../common';
import { Scenario } from '../MfaForm';

export type MfaSuccessViewProps = {
    onContinue: () => void;
    onClickManageSettings?: () => void;
    /** 'setup' =  setup 2fa on use account, 'confirm' = use 2fa to confirm a sensible modification */
    scenario: Scenario;
};

export function MfaSuccessView({
    onContinue,
    onClickManageSettings,
    scenario,
}: MfaSuccessViewProps) {
    const { t } = useTranslation();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <VerifiedUserIcon sx={{ fontSize: '6rem', color: 'success.main', marginTop: '2rem' }} />

            <DialogContent>
                <DialogContentText
                    component='h2'
                    sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}
                >
                    {t('mfaForm_successView_title')}
                </DialogContentText>

                <DialogContentText component='h3' sx={{ fontSize: '1.5rem', textAlign: 'center' }}>
                    {t(
                        scenario === 'setup'
                            ? 'mfaForm_successView_description_forSetup'
                            : 'mfaForm_successView_description_forConfirm',
                    )}
                </DialogContentText>
            </DialogContent>
            <MfaDialogAction>
                <Button
                    onClick={onContinue}
                    variant='contained'
                    color='primary'
                    data-testid='continueBtn'
                >
                    {t('mfaForm_successView_btnContinue')}
                </Button>
            </MfaDialogAction>
            {onClickManageSettings && (
                <DialogContentText
                    sx={{
                        textAlign: 'center',
                        marginTop: '0.5rem',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    {t('mfaForm_successView_linkToMfaConfig')}
                    <OpenInNewIcon
                        fontSize='inherit'
                        sx={{
                            marginLeft: '0.2rem',
                        }}
                    />
                </DialogContentText>
            )}
        </div>
    );
}
