import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { checkStatus, getAPISubUrlForPublisher } from '../common/utils';
import { ProfileInfo } from './interfacesPublisher';

export default async function fetchProfileInfo(profileId: MongoId): Promise<ProfileInfo> {
    const url =
        getAPISubUrlForPublisher() + `/affiliateProfiles.info?affiliateProfile=${profileId}`;
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response, 200);
    const body = await response.json();
    const affiliateProfile = body.affiliateProfile.data;
    return affiliateProfile;
}
