import _ from 'lodash';

import { getUserType } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';

import { getAPISubUrlForCurrentUser } from '../utils';

export async function sendMessage(messageOptions, sender, recipients) {
    const url = getAPISubUrlForCurrentUser() + '/messages.add';
    const payload = getPayload(messageOptions, sender, recipients);
    let isError = null;
    return fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
        .then((response) => {
            if (response.status !== 201) {
                isError = true;
                throw new Error(response.status + ' ' + response.statusText);
            }
            return response.json();
        })
        .then((body) => {
            if (isError) {
                throw body;
            }
            return; // nothing useful is currently returned by the hapi
        });
}

function getPayload(messageOptions, sender, recipients) {
    const userType = getUserType();

    const payload = {};

    const regex = /(<([^>]+)>)/gi;
    const rawContent = messageOptions.content.replace(regex, '');

    if (!_.isEmpty(rawContent)) {
        payload.content = messageOptions.content;
    }

    if (messageOptions.fileId) {
        payload.attachment = messageOptions.fileId;
    }
    switch (userType) {
        case 'advertiser':
            payload.programId = sender;
            payload.to = _.map(recipients, function (recipient) {
                return {
                    affiliateProfileUserId: recipient.user,
                    affiliateProfileId: recipient.affiliateProfile,
                };
            });
            break;

        case 'publisher':
            payload.affiliateProfileId = sender;
            payload.to = _.map(recipients, function (recipient) {
                return {
                    programUserId: recipient.user,
                    programId: recipient.program,
                };
            });
            break;
    }

    return payload;
}
