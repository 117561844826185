import { Component, createContext, ReactNode, useContext } from 'react';

import { MongoId } from '@libs/types';

import { Advertiser } from '@api/advertiser/fetchCurrentAdvertiserData';
import { ProgramInfo } from '@api/advertiser/fetchProgramInfo';
import { Ad } from '@api/advertiser/interfacesAdvertiser';
import { PartnershipAdvertiser, PartnershipPublisher } from '@api/common/partnerships/list';
import { PaymentMethod, ProfileInfo } from '@api/publisher/interfacesPublisher';
import { User } from '@api/user/types';

// This old global state will be replaced by redux one day
// but to do so we will have to rework on ALL existing page using this global state and that is
// a lot of work so this state may still stay for a while
// in the mean time we add a context to prevent prop drilling and make it use easier

interface OldAppStateContextInterface {
    state: OldState;
    app: OldAppStateProvider;
}

export const OldAppStateContext = createContext<OldAppStateContextInterface | null>(null);

export interface OldState {
    currentProgram: ProgramInfo | null;
    currentAffiliateProfile: ProfileInfo | null;
    currentPartnerships: PartnershipPublisher[] | PartnershipAdvertiser[] | null;

    currentAds: Ad[] | null;
    advertiser: Advertiser | null;
    publisher: TPublisher | null;
    programs: Array<ProgramInfo> | null;
    affiliateProfiles: Array<ProfileInfo> | null;
    currencies: string[] | null;
    currentSubEntitiesCurrencies: string[] | null;
    currenciesBySubEntities: Record<MongoId, string[]>;

    user: User | null;
}

type OldAppStateProviderProps = { children?: ReactNode };

export class OldAppStateProvider extends Component<OldAppStateProviderProps, OldState> {
    constructor(props: OldAppStateProviderProps) {
        super(props);
        this.state = {
            currentProgram: null,
            currentAffiliateProfile: null,
            currentPartnerships: null,
            currentAds: null,
            advertiser: null,
            publisher: null,
            programs: null,
            affiliateProfiles: null,
            currencies: null,
            currentSubEntitiesCurrencies: null,
            user: null,
            currenciesBySubEntities: {},
        };
    }

    render() {
        return (
            <OldAppStateContext.Provider
                value={{
                    state: this.state,
                    app: this,
                }}
            >
                {this.props.children}
            </OldAppStateContext.Provider>
        );
    }
}

/**
 * ⚠️ return a class component (not the usual [state, setState])
 * (it does for retro-compatibility reasons)
 */
export function useOldAppState() {
    const app = useContext(OldAppStateContext);
    if (app == null) throw new Error('missing OldAppStateProvider');
    return app;
}

export type EntityType = 'company' | 'association' | 'private';

export type TPublisher = {
    id: MongoId;
    type: EntityType;
    company: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
    currency: string;
    paypalAccount: string;
    iban: string;
    bic: string;
    invoicingInformation: string;
    phone: string;
    companyId: string;
    createdAt: number;
    bankCity: string;
    bankZipCode: string;
    bankName: string;
    bankCountry: string;
    bankAddress: string;
    bankAccountOwner: string;
    bankAccountNumberType: 'NUMBER' | 'IBAN';
    bankAccountNumber: string;
    bankIdentifierType: 'BIC/SWIFT' | 'ABA' | 'BSB' | 'NSC';
    bankIdentifierCode: string;
    bankRoutingType: string;
    bankRoutingNumber: string;
    admin: string;
    companyVat: string;
    state?: string;
    bankCountryState?: string;
    bankInstitutionNumber?: string;
    vatRate: number;
    isVatFree: boolean;
    preferredPaymentMethod: PaymentMethod | null;
    bankBsbCode: string;
};
