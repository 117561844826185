import useBreakpoint from '@hooks/useBreakpoint';

const FormFields = ({
    style,
    singleColumn = false,
    ...props
}: JSX.IntrinsicElements['div'] & { singleColumn?: boolean }) => {
    const isMobile = useBreakpoint({ breakpoint: 'md', direction: 'down' });

    return (
        <div
            style={{
                ...(singleColumn || isMobile
                    ? { display: 'flex', flexDirection: 'column' }
                    : {
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, minmax(300px, 1fr))',
                      }),
                gap: '1.5rem',
                ...style,
            }}
            {...props}
        />
    );
};

export default FormFields;
