import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '@api/common/utils';
import { wait } from '@api/common/wait';

export type VerifyCodeResponse =
    | { isVerified: true; error: undefined }
    | { isVerified: false; error: VerifyCodeResponseError };

export type VerifyCodeResponseError = 'code-expired' | 'code-invalid' | 'internal';

export async function verifyCode(code: string): Promise<VerifyCodeResponse> {
    const response = await fetch(getAPIBaseUrl() + 'common/users/mfa.verify', {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
    });
    await checkStatus(response, 200);
    const data = await response.json();
    return data.userMFAStatus as VerifyCodeResponse;
}

export const verifyCodeMock: typeof verifyCode = async (code) => {
    await wait(1000);
    if (code !== '1234') {
        return {
            isVerified: false,
            error: 'code-invalid',
        };
    }
    return {
        isVerified: true,
    };
};
