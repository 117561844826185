import _ from 'lodash';

import { getJwt } from '@libs/jwt';

import { getAPISubUrlForPublisher } from '@api/common/utils';

export const PUBLISHER_PRODUCTS_LOADING = 'PUBLISHER_PRODUCTS_LOADING';

export const FETCH_PUBLISHER_PRODUCT_REQUESTS = 'FETCH_PUBLISHER_PRODUCT_REQUESTS';
export const FETCH_PUBLISHER_PRODUCT_REQUESTS_SUCCESS = 'FETCH_PUBLISHER_PRODUCT_REQUESTS_SUCCESS';
export const FETCH_PUBLISHER_PRODUCT_REQUESTS_FAILURE = 'FETCH_PUBLISHER_PRODUCT_REQUESTS_FAILURE';

export const CREATE_PUBLISHER_REVIEW = 'CREATE_PUBLISHER_REVIEW';
export const CREATE_PUBLISHER_REVIEW_SUCCESS = 'CREATE_PUBLISHER_REVIEW_SUCCESS';
export const CREATE_PUBLISHER_REVIEW_FAILURE = 'CREATE_PUBLISHER_REVIEW_FAILURE';

export const UPDATE_PUBLISHER_REVIEW = 'UPDATE_PUBLISHER_REVIEW';
export const UPDATE_PUBLISHER_REVIEW_SUCCESS = 'UPDATE_PUBLISHER_REVIEW_SUCCESS';
export const UPDATE_PUBLISHER_REVIEW_FAILURE = 'UPDATE_PUBLISHER_REVIEW_FAILURE';

export const OPEN_PUBLISHER_ADD_REVIEW_DIALOG = 'OPEN_PUBLISHER_ADD_REVIEW_DIALOG';
export const OPEN_PUBLISHER_EDIT_REVIEW_DIALOG = 'OPEN_PUBLISHER_EDIT_REVIEW_DIALOG';
export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';

export function publisherProductsLoading() {
    return {
        type: PUBLISHER_PRODUCTS_LOADING,
    };
}

export function fetchPublisherProductRequests(queryCriteria) {
    const qs = buildQueryString(queryCriteria);
    const promise = fetch(getAPISubUrlForPublisher() + '/products/request.list' + qs, {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
        },
    });

    return {
        type: FETCH_PUBLISHER_PRODUCT_REQUESTS,
        payload: promise,
    };

    function buildQueryString(queryCriteria) {
        const queries = [];
        if (!_.isEmpty(queryCriteria.affiliateProfiles)) {
            queryCriteria.affiliateProfiles.map((affiliateProfile) => {
                queries.push('affiliateProfiles=' + affiliateProfile);
            });
        }
        if (!_.isEmpty(queryCriteria.status)) {
            queryCriteria.status.map((status) => {
                queries.push('status=' + status);
            });
        }
        if (queryCriteria.product !== null) {
            queries.push('product=' + queryCriteria.product);
        }
        if (!_.isEmpty(queryCriteria.search)) {
            queries.push('search=' + queryCriteria.search);
        }
        if (queries.length > 0) {
            return '?' + queries.join('&');
        } else {
            return '';
        }
    }
}

export function fetchPublisherProductRequestsSuccess(data) {
    return {
        type: FETCH_PUBLISHER_PRODUCT_REQUESTS_SUCCESS,
        payload: data,
    };
}

export function fetchPublisherProductRequestsFailure(error) {
    return {
        type: FETCH_PUBLISHER_PRODUCT_REQUESTS_FAILURE,
        payload: error,
    };
}

export function createPublisherReview(fields) {
    const bodyObj = {
        request: fields.id,
        url: fields.url,
    };
    if (!_.isEmpty(fields.message)) {
        bodyObj.message = fields.message;
    }

    const promise = fetch(getAPISubUrlForPublisher() + '/products/review.create', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: CREATE_PUBLISHER_REVIEW,
        payload: promise,
    };
}

export function createPublisherReviewSuccess(review, allData) {
    const updatedDatas = _.map(allData, function (data) {
        if (data.id === review.id) {
            data.url = review.url;
            data.status = review.status;
            return data;
        } else {
            return data;
        }
    });

    return {
        type: CREATE_PUBLISHER_REVIEW_SUCCESS,
        payload: updatedDatas,
    };
}

export function createPublisherReviewFailure(error) {
    return {
        type: CREATE_PUBLISHER_REVIEW_FAILURE,
        payload: error,
    };
}

export function updatePublisherReview(fields) {
    const bodyObj = {
        review: fields.id,
        url: fields.url,
    };
    if (!_.isEmpty(fields.message)) {
        bodyObj.message = fields.message;
    }

    const promise = fetch(getAPISubUrlForPublisher() + '/products/review.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: UPDATE_PUBLISHER_REVIEW,
        payload: promise,
    };
}

export function updatePublisherReviewSuccess(review, allData) {
    const updatedDatas = _.map(allData, function (data) {
        if (data.id === review.id) {
            data.url = review.url;
            return data;
        } else {
            return data;
        }
    });

    return {
        type: UPDATE_PUBLISHER_REVIEW_SUCCESS,
        payload: updatedDatas,
    };
}

export function updatePublisherReviewFailure(error) {
    return {
        type: UPDATE_PUBLISHER_REVIEW_FAILURE,
        payload: error,
    };
}

export function openPublisherAddReviewDialog(review) {
    return {
        type: OPEN_PUBLISHER_ADD_REVIEW_DIALOG,
        review: review,
    };
}

export function openPublisherEditReviewDialog(review) {
    return {
        type: OPEN_PUBLISHER_EDIT_REVIEW_DIALOG,
        review: review,
    };
}

export function closeAllDialogs() {
    return {
        type: CLOSE_ALL_DIALOGS,
    };
}
