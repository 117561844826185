import './ProductPageRestriction.less';

import { Trans, useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import useViewport from '@hooks/useViewport';

import affilaeBlackLogo from './affilaeBlackLogo.svg';
import forbiddenAccessPicture from './partnerDiscoveryNoAccess.svg';

const ProductPageRestriction = () => {
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    const { width } = useViewport();
    const isMobile = width <= 900;
    return (
        <div className='productPageRestrictionContainer'>
            <div className='pageContainerHeader'>
                <img
                    data-test-id='forbiddenAccessImage'
                    className='affilaeLogo'
                    src={affilaeBlackLogo}
                    alt='forbiddenAccess'
                />
            </div>
            <div className='contentContainer'>
                <div className='textContainer'>
                    <h2 className='title'>{t('page_restriction_update_plan_title')}</h2>
                    {isMobile && (
                        <img
                            data-test-id='forbiddenAccessImage'
                            className='logoMobile'
                            src={forbiddenAccessPicture}
                            alt='forbiddenAccess'
                        />
                    )}
                    <span className='productPageRestrictionDescription'>
                        <Trans
                            i18nKey='page_restriction_update_plan_needed'
                            components={{ Bold: <b /> }}
                        />
                    </span>
                    <div className='buttonContainer'>
                        <Button
                            variant='outlined'
                            className='contactLink'
                            sx={{
                                fontSize: !isMobile ? '18px' : 'unset',
                                color: '#006d94',
                                borderColor: '#006d94',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    borderColor: '#005B7D',
                                    color: '#005B7D',
                                },
                            }}
                            href={
                                CONFIG.website_url +
                                t(`page_restriction_update_plan_app_contact_${locale}`)
                            }
                        >
                            <span>
                                <Trans
                                    i18nKey='page_restriction_update_plan_sales'
                                    components={{ bold: <b /> }}
                                />
                            </span>
                        </Button>
                        <Button
                            variant='contained'
                            href={t(`page_restriction_update_plan_sales_link_${locale}`)}
                            sx={{
                                fontSize: !isMobile ? '18px' : 'unset',
                                textTransform: 'none',
                                backgroundColor: '#006d94',
                                '&:hover': {
                                    backgroundColor: '#005B7D',
                                },
                            }}
                        >
                            <span style={{ color: 'white' }}>
                                {t('page_restriction_update_plan')}
                            </span>
                        </Button>
                    </div>
                </div>
                {!isMobile && (
                    <img
                        data-test-id='forbiddenAccessImage'
                        className='logoDesktop'
                        src={forbiddenAccessPicture}
                        alt='forbiddenAccess'
                    />
                )}
            </div>
        </div>
    );
};
export default ProductPageRestriction;
