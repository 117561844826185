import { getJwt } from '@libs/jwt';
import { AppDispatch } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import { getAPISubUrlForPublisher } from '@api/common/utils';
import { createAffiliateProfile } from '@api/publisher/profile/add';
import { updateAffiliateProfile } from '@api/publisher/profile/update';

export const CREATE_AFFILIATE_PROFILE = 'CREATE_AFFILIATE_PROFILE';
export const CREATE_AFFILIATE_PROFILE_SUCCESS = 'CREATE_AFFILIATE_PROFILE_SUCCESS';
export const CREATE_AFFILIATE_PROFILE_FAILURE = 'CREATE_AFFILIATE_PROFILE_FAILURE';
export const UPDATE_AFFILIATE_PROFILE = 'UPDATE_AFFILIATE_PROFILE';
export const UPDATE_AFFILIATE_PROFILE_SUCCESS = 'UPDATE_AFFILIATE_PROFILE_SUCCESS';
export const UPDATE_AFFILIATE_PROFILE_FAILURE = 'UPDATE_AFFILIATE_PROFILE_FAILURE';

export const FETCH_PARTNERSHIPS = 'FETCH_PARTNERSHIPS';
export const FETCH_PARTNERSHIPS_SUCCESS = 'FETCH_PARTNERSHIPS_SUCCESS';
export const FETCH_PARTNERSHIPS_FAILURE = 'FETCH_PARTNERSHIPS_FAILURE';

export const CLOSE_DIALOGS = 'CLOSE_DIALOGS';

export function createAffiliateProfileThunk(fields) {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch({ type: CREATE_AFFILIATE_PROFILE });
            const profile = await createAffiliateProfile(fields);
            dispatch({
                type: CREATE_AFFILIATE_PROFILE_SUCCESS,
                affiliateProfileId: profile._id,
            });
        } catch (error) {
            dispatch({
                type: CREATE_AFFILIATE_PROFILE_FAILURE,
                payload: error,
            });
        }
    };
}

export function updateAffiliateProfileThunk(fields) {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch({ type: UPDATE_AFFILIATE_PROFILE });
            const profile = await updateAffiliateProfile(fields);
            dispatch({
                type: UPDATE_AFFILIATE_PROFILE_SUCCESS,
                payload: profile._id,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_AFFILIATE_PROFILE_FAILURE,
                payload: error,
            });
        }
    };
}

export function fetchPartnerships(affiliateProfileId: MongoId) {
    const promise = fetch(
        getAPISubUrlForPublisher() +
            '/partnerships.list?affiliateProfile=' +
            affiliateProfileId +
            '&withProgramsDetails=true',
        {
            method: 'GET',
            headers: { Authorization: 'Bearer ' + getJwt() },
        },
    );

    return {
        type: FETCH_PARTNERSHIPS,
        payload: promise,
    };
}

export function fetchPartnershipsSuccess(partnerships) {
    return {
        type: FETCH_PARTNERSHIPS_SUCCESS,
        payload: partnerships,
    };
}

export function fetchPartnershipsFailure(error) {
    return {
        type: FETCH_PARTNERSHIPS_FAILURE,
        payload: error,
    };
}

export function closeDialogs() {
    return {
        type: CLOSE_DIALOGS,
    };
}

export const fetchPartnershipsThunk = (programId) => {
    return async (dispatch) => {
        dispatch(fetchPartnerships(programId))
            .then(function (response) {
                return response.payload.json();
            })
            .then(function (result) {
                if (result.statusCode !== 200) {
                    dispatch(fetchPartnershipsFailure());
                } else {
                    dispatch(fetchPartnershipsSuccess(result.partnerships.data));
                }
            });
    };
};
