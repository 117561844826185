import React, { CSSProperties, memo, ReactNode, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';

export interface HighlightProps {
    text: string;
    search: string | RegExp;
    classes?: Partial<Record<CustomizableElement, string>>;
    styles?: Partial<Record<CustomizableElement, CSSProperties>>;
}

type CustomizableElement = 'root' | 'matched' | 'unmatched';

const useDefaultStyles = makeStyles({
    root: {},
    matched: {
        color: '#548DAC',
    },
    unmatched: {
        color: 'inherit',
    },
});

/**
 * Display text and Highlight part of text that match search props
 */
export const Highlight = memo(function Highlight({
    text,
    search,
    classes,
    styles,
}: HighlightProps) {
    const defaultClasses = useDefaultStyles();
    const finalClasses = useMemo(
        () => ({ ...defaultClasses, ...classes }),
        [defaultClasses, classes],
    );

    const { Container, Matched, Unmatched } = useMemo(() => {
        function Container({ children }: { children: ReactNode }) {
            return (
                <span className={finalClasses.root} style={styles?.root}>
                    {children}
                </span>
            );
        }
        function Matched({ children }: { children: string }) {
            return (
                <span className={finalClasses.matched} style={styles?.matched}>
                    {children}
                </span>
            );
        }
        function Unmatched({ children }: { children: string }) {
            return (
                <span className={finalClasses.unmatched} style={styles?.unmatched}>
                    {children}
                </span>
            );
        }
        return { Container, Matched, Unmatched };
    }, [finalClasses]);

    const noResultOutput = (
        <Container>
            <Unmatched>{text}</Unmatched>
        </Container>
    );

    if (search === '') {
        return noResultOutput;
    }

    let regex: RegExp;
    try {
        if (search instanceof RegExp) {
            regex = search;
        } else {
            regex = new RegExp(escapeRegExp(search), 'i'); // test if it is as correct regex
        }
    } catch (error) {
        return noResultOutput;
    }

    const unmatchedSubstrings = text.split(regex);
    const matchedSubstring = text.match(new RegExp(regex, regex.flags + 'g')) as string[] | null;
    if (matchedSubstring == null) {
        return noResultOutput;
    }

    const fragments: ReactNode[] = [];
    for (let i = 0; i < unmatchedSubstrings.length - 1; i++) {
        fragments.push(<Unmatched>{unmatchedSubstrings[i]}</Unmatched>);
        fragments.push(<Matched>{matchedSubstring[i]}</Matched>);
    }
    fragments.push(<Unmatched>{unmatchedSubstrings[unmatchedSubstrings.length - 1]}</Unmatched>);

    return <Container>{fragments}</Container>;
});

function escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
