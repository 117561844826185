import { TPublisher } from '@hooks/useOldAppState';

import { getJwt } from '@libs/jwt';

import { User } from '@api/user/types';

import { checkStatus, getAPISubUrlForPublisher } from '../common/utils';
import { ProfileInfo } from './interfacesPublisher';

export default async function fetchCurrentPublisherData() {
    const url = getAPISubUrlForPublisher() + '/publishers.me';
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response, 200);
    const body = await response.json();
    const currentPublisher = body.publisher.data as TPublisher;
    const publisherAffiliateProfiles = body.affiliateProfiles.data as ProfileInfo[];
    const publisherUsers = body.users.data as User[];
    return { currentPublisher, publisherAffiliateProfiles, publisherUsers };
}
