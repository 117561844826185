import { configureStore } from '@reduxjs/toolkit';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

function configureAppStore() {
    const store = configureStore({
        reducer: rootReducer,
        middleware: [thunk, promise],
        // middleware: getDefaultMiddleware => getDefaultMiddleware().concat([thunk, promise]), // todo use this line once we solve pb with current state mutation
        // we can't use getDefaultMiddleware today because it include middlewares who stop the app and signal an error when an incorrect state operation is detected (ex: mutation...)
    });
    if (import.meta.hot) {
        import.meta.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    }
    return store;
}

const store = configureAppStore();

export default store;
