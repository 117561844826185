import { create } from 'zustand';

import {
    BankDetailsApiVerification,
    isBankDetailValid,
} from '@api/publisher/paymentRequests/verifyPublisher';

export type BannerStoreState = {
    isBannerOpen: boolean;
    setIsBannerOpen: (isOpen: boolean) => void;
    bankDetails: BankDetailsApiVerification[];
    setBankDetails: (details: BankDetailsApiVerification[]) => void;
};

export const useBannerStore = create<BannerStoreState>((set) => ({
    isBannerOpen: true,
    bankDetails: [],
    setBankDetails: (details) => {
        set({
            bankDetails: details,
            isBannerOpen: details.some((d) => isBankDetailValid(d) === false),
        });
    },
    setIsBannerOpen: (isOpen) => set({ isBannerOpen: isOpen }),
}));
