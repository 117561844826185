import { getJwt } from '@libs/jwt';

import { buildQueryString, checkStatus, getAPIBaseUrl } from '../../common/utils';

export interface Program {
    id: string;
    name: string;
    advertiser: string;
    status: unknown;
}

export default async function apiList<Field extends keyof Program>(
    fields: Field[],
): Promise<Pick<Program, Field>[]> {
    const url = getAPIBaseUrl() + 'admin/programs.list' + buildQueryString({ fields });
    const response = await fetch(url, {
        headers: {
            Authorization: getJwt() || '',
        },
    });
    await checkStatus(response);
    const body = await response.json();
    return body;
}
