import React from 'react';

import { BoxProps } from '@mui/material';

import { LayoutPage } from '@components/generic/LayoutPageTable';

export default function FormPageLayout({ sx, ...otherProps }: BoxProps) {
    return (
        <LayoutPage
            sx={{
                margin: 'auto',
                maxWidth: '80rem',
                ...sx,
            }}
            {...otherProps}
        />
    );
}
