import { useTranslation } from 'react-i18next';

const PublisherSupportPage = () => {
    const { i18n } = useTranslation();
    return (
        <iframe
            src={`//support.publishers.affilae.com/${i18n.language}/support/home`}
            style={{ height: '100vw', width: '100%' }}
        ></iframe>
    );
};
export default PublisherSupportPage;
