import { logout, userSlice } from '@slices/users';
import { combineReducers } from 'redux';

import { libsReducer } from '@providers/LibsProvider';

import AdvertiserReducer from './reducer_advertiser';
import AdvertiserAdsReducer from './reducer_advertiser_ads';
import AdvertiserBillingReducer from './reducer_advertiser_billing';
import AdvertiserPaymentsReducer from './reducer_advertiser_payments';
import AdvertiserProductsReducer from './reducer_advertiser_products';
import AdvertiserReportingReducer from './reducer_advertiser_reporting';
import AffiliateProfileReducer from './reducer_affiliate_profile';
import CommonReducer from './reducer_common';
import MessageReducer from './reducer_message';
import productsAm from './reducer_products_am';
import ProgramReducer from './reducer_program';
import ProgramsAmReducer from './reducer_programs_am';
import PublisherAdsReducer from './reducer_publisher_ads';
import PublisherPartnershipsReducer from './reducer_publisher_partnerships';
import PublisherPaymentsReducer from './reducer_publisher_payments';
import PublisherProductsReducer from './reducer_publisher_products';
import PublisherReportingReducer from './reducer_publisher_reporting';
import VouchersReducer from './reducer_vouchers';

const appReducer = combineReducers({
    common: CommonReducer,
    advertiserReporting: AdvertiserReportingReducer,
    publisherReporting: PublisherReportingReducer,
    affiliateProfile: AffiliateProfileReducer,
    advertiser: AdvertiserReducer,
    program: ProgramReducer,
    message: MessageReducer,
    vouchersReducer: VouchersReducer,
    programsAmReducer: ProgramsAmReducer,
    advertiserPaymentsReducer: AdvertiserPaymentsReducer,
    publisherPaymentsReducer: PublisherPaymentsReducer,
    advertiserAdsReducer: AdvertiserAdsReducer,
    publisherAdsReducer: PublisherAdsReducer,
    publisherPartnershipsReducer: PublisherPartnershipsReducer,
    advertiserBillingReducer: AdvertiserBillingReducer,
    advertiserProductsReducer: AdvertiserProductsReducer,
    publisherProductsReducer: PublisherProductsReducer,
    productsAm: productsAm,

    libs: libsReducer,
    session: combineReducers({
        users: userSlice.reducer,
    }),
});

function rootReducer(
    state: Parameters<typeof appReducer>[0],
    action: Parameters<typeof appReducer>[1],
) {
    if (action.type === logout.type) {
        const nextState: typeof state = {
            ...appReducer(undefined, action),
            libs: state!.libs,
        };
        return nextState;
    } else {
        return appReducer(state, action);
    }
}

export default rootReducer;
