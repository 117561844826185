import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItem from '@mui/material/ListItem';

import { Highlight } from '@components/generic/Highlight';

import { useAppSelector } from '@libs/reduxHooks';

import { Contact, isContactEqual, selectNewMessagePublisher } from './NewMessagePublisher';

const contactRowHeigh = 35; // warning: Must have the same value as the height property of the css "userItem" class

interface ContactsPublisherSelectProps {
    contacts: Contact[];
    selected: Contact[];
    onSelect: (contact: Contact, isSelected) => void;
    searchText: string;
}

export function ContactsPublisherSelect({
    contacts,
    selected,
    searchText,
    onSelect,
}: ContactsPublisherSelectProps) {
    if (searchText !== '' && contacts.length === 0) {
        return (
            <div className='profileUserPairSelector'>
                <NoSearchResultBlock />
            </div>
        );
    }
    // todo simplify css
    return (
        <div className='profileUserPairSelector'>
            <div className='mainList'>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            height={height}
                            width={width}
                            itemCount={contacts.length}
                            itemSize={contactRowHeigh}
                        >
                            {({ index, style }) => {
                                const contact = contacts[index];
                                const isSelected =
                                    selected.find((c) => isContactEqual(c, contact)) !== undefined;
                                return (
                                    <div style={style} key={JSON.stringify(contact)}>
                                        <ContactItem
                                            contact={contact}
                                            isSelected={isSelected}
                                            onSelect={(isSelected) => onSelect(contact, isSelected)}
                                            searchText={searchText}
                                        />
                                    </div>
                                );
                            }}
                        </List>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

interface ContactItemProps {
    contact: Contact;
    isSelected: boolean;
    onSelect: (isSelected: boolean) => void;
    searchText: string;
}

function ContactItem({ contact, isSelected, onSelect, searchText }: ContactItemProps) {
    const apiData = useAppSelector((state) => selectNewMessagePublisher(state).data!);
    const program = apiData.programs[contact.program];
    const user = apiData.users[contact.user];
    const fullName = `${user.firstname} ${user.lastname.toUpperCase()}`;
    const label = (
        <>
            <Highlight
                text={program.title}
                search={searchText}
                classes={{ root: 'listItemEntityName' }}
            />
            {' - '}
            <Highlight text={fullName} search={searchText} />
        </>
    );

    return (
        <ListItem className='userItem'>
            <FormControlLabel
                control={
                    <Checkbox
                        className='itemCheckbox'
                        checked={isSelected}
                        disableRipple
                        onChange={(e, isSelected) => onSelect(isSelected)}
                    />
                }
                label={label}
            />
        </ListItem>
    );
}

export function NoSearchResultBlock() {
    const { t } = useTranslation();
    return <div className='searchNoResult'>{t('messaging_new_message_no_search_result')}</div>;
}
