import { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import InputBase from '@mui/material/InputBase';

import { usePrevious } from '@hooks/usePrevious';

export function DebouncedInputBase({ value, onChange, ...otherProps }) {
    const [searchText, setSearchText] = useState(value);
    const prevValue = usePrevious(value);
    if (prevValue !== null && value !== prevValue && value !== searchText) {
        setSearchText(value);
    }

    const onChangeDebounced = useMemo(
        () =>
            debounce((text) => {
                onChange(text);
            }, 200),
        [onChange],
    );

    function update(text) {
        setSearchText(text);
        onChangeDebounced(text);
    }

    return (
        <InputBase
            {...otherProps}
            value={searchText}
            onChange={(event) => update(event.target.value)}
        />
    );
}
