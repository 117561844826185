import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '@api/common/utils';

import { User, UserForm } from './types';

export default async function userAdd(userForm: UserForm) {
    const payload = {
        firstname: userForm.firstname,
        lastname: userForm.lastname,
        email: userForm.email,
        phone: userForm.phone,
        timezone: userForm.timezone,
        lang: userForm.lang,
        newsletter: userForm.newsletter,
        permissions: userForm.permissions,
    };
    const url = getAPIBaseUrl() + 'common/users.create';
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
    await checkStatus(response, 201);
    const body = await response.json();
    return body as User;
}
