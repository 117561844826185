import { Trans, useTranslation } from 'react-i18next';

import { unauthorizedPagesStyles } from './UnauthorizedBySubscription';
import UnauthorizedPage from './UnauthorizedPage';

export default function UnauthorizedByPermissionPage() {
    const { t } = useTranslation();
    return (
        <UnauthorizedPage>
            <h1 style={unauthorizedPagesStyles.title}>
                <Trans i18nKey='unauthorized_view_msg'>{t('unauthorized_view_msg')}</Trans>
            </h1>
        </UnauthorizedPage>
    );
}
