import { useOldAppState } from '@hooks/useOldAppState';
import { usePromise } from '@hooks/usePromise';

import { getCurrentUser, getUserType } from '@libs/getSharedVar';
import { hasPermission } from '@libs/hasPermission';
import { MongoId } from '@libs/types';

import { getPublisherBankInfosDetails } from '@api/publisher/paymentRequests/verifyPublisher';

import { useBannerStore } from './useBannerStore';

const usePublisherBankDetailsQuery = () => {
    if (getUserType() !== 'publisher') {
        throw new Error('Only for publisher');
    }

    const profiles = useOldAppState().state.affiliateProfiles ?? [];
    const defaultProfilesIds = profiles.map((p) => p.id);
    const userPermissions = getCurrentUser().permissions;
    const setBankDetails = useBannerStore((state) => state.setBankDetails);

    const [bankDetailsQuery, loadBankDetails] = usePromise(
        async (_profileIds: MongoId | MongoId[] = defaultProfilesIds) => {
            const profilesIds = typeof _profileIds === 'string' ? [_profileIds] : _profileIds;

            const subEntitiesWithPermissions = profilesIds.filter(
                (subEntity) =>
                    hasPermission(userPermissions, 'payments', subEntity) &&
                    hasPermission(userPermissions, 'billing', subEntity),
            );

            const bankDetails = await Promise.all(
                subEntitiesWithPermissions.map((subEntity) =>
                    getPublisherBankInfosDetails(subEntity),
                ),
            );
            setBankDetails(bankDetails);
            return bankDetails;
        },
    );

    return { bankDetailsQuery: { data: bankDetailsQuery }, loadBankDetails };
};

export default usePublisherBankDetailsQuery;
