import { getUserType } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { checkStatus, getAPISubUrlForCurrentUser } from '../utils';

export async function deleteMessageFile(sender: MongoId, threadId: MongoId | null, fileId: string) {
    const url = getAPISubUrlForCurrentUser() + '/messages/media.delete';
    const payload = getPayload(sender, threadId, fileId);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
    await checkStatus(response, 204);
}

function getPayload(sender: MongoId, threadId: string | null, fileId: string) {
    const userType = getUserType();

    const payload: IPayload = {
        uploadId: fileId,
    };
    if (threadId) {
        payload.threadId = threadId;
    }
    switch (userType) {
        case 'advertiser':
            payload.programId = sender;
            break;

        case 'publisher':
            payload.affiliateProfileId = sender;
            break;
    }

    return payload;
}

interface IPayload {
    threadId?: string;
    uploadId: string;
    programId?: MongoId;
    affiliateProfileId?: MongoId;
}
