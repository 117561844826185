import { getJwt } from '@libs/jwt';

import { checkStatus, getAPISubUrlForPublisher } from '../utils';

export interface ApiData {
    partnerships: Record<string, Partnership>;
    programs: Record<string, Program>;
    users: Record<string, User>;
}

export interface Partnership {
    id: string;
    program: string;
    affiliateProfile: string;
    group: string;
}

export interface Program {
    id: string;
    title: string;
    manager: string;
}

export interface User {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
}

export async function listContacts(): Promise<ApiData> {
    const url = getAPISubUrlForPublisher() + '/contacts.list';
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response);
    return await response.json();
}
