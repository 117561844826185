import {
    PAGE_PRODUCT_AM_PROGRAM_PANEL_CLOSE,
    PAGE_PRODUCT_AM_PROGRAM_PANEL_OPEN,
} from '@actions/productsAm';

const initialState = {
    isVisible: false,
    programId: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PAGE_PRODUCT_AM_PROGRAM_PANEL_OPEN: {
            return {
                isVisible: true,
                programId: action.programId,
            };
        }

        case PAGE_PRODUCT_AM_PROGRAM_PANEL_CLOSE: {
            return {
                isVisible: false,
                programId: null,
            };
        }

        default:
            return state;
    }
}
