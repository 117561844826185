import {
    PAGE_PRODUCT_AM_PRODUCT_PANEL_CLOSE,
    PAGE_PRODUCT_AM_PRODUCT_PANEL_OPEN,
} from '@actions/productsAm';

const initialState = {
    isVisible: false,
    productId: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PAGE_PRODUCT_AM_PRODUCT_PANEL_OPEN: {
            return {
                isVisible: true,
                productId: action.productId,
            };
        }

        case PAGE_PRODUCT_AM_PRODUCT_PANEL_CLOSE: {
            return {
                isVisible: false,
                productId: null,
            };
        }

        default:
            return state;
    }
}
