import moment, { Moment } from 'moment';

import { Timestamp } from '@libs/types';

export type VerifyState = {
    isVerified: boolean;
    tryCount: number;
    currentMethod: VerifyMethod;
    nextTryAt: Moment | null;
    nextMethod: VerifyMethod;
};

export type VerifyMethod = 'sms' | 'email' | 'support';

export type VerifyStateRowResponse = {
    statusCode: 200;
    userMFAStatus: {
        isVerified: boolean;
        try: number;
        nextTryAt: Timestamp | null;
        currentMethod: VerifyMethod;
        nextMethod: VerifyMethod;
    };
};

export function refineVerifyState(state: VerifyStateRowResponse['userMFAStatus']): VerifyState {
    return {
        isVerified: state.isVerified,
        tryCount: state.try,
        nextTryAt: state.nextTryAt ? moment.unix(state.nextTryAt) : null,
        currentMethod: state.currentMethod,
        nextMethod: state.nextMethod,
    };
}
