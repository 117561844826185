export const AFFILAE_CONSOLE_WELCOME = `

              .:==+++++=-::-++++++++++++= 
           .-++++++++=.     +++++++++++=  
         -+++++++++=.      -++++++++++=   
       -++++++++++-       .++++++++++=    
     -+++++++++++:       .+++++++++++     
   .=+++++++++++.       .+++++++++++.     
  :+++++++++++=        .+++++++++++.      
 :+++++++++++=        .+++++++++++.       
 ++++++++++++        .+++++++++++.        
-+++++++++++.       :+++++++++++:   :+=   
+++++++++++-       =+++++++++++:  .=+:    
+**********:     -+--*********= .=+-      
-**********+:::=+-  +***********+-        
 :+**********+=:    -*********=:          
   .:-===--:.        .:-==--.  


Welcome to the Affilae application!

🎉 We're delighted to welcome you to the world of influence & affiliate marketing.
Affilae is your partner of choice for optimizing your marketing campaigns:

 - Innovative affiliate platform
 - Advanced tracking tools
 - Real-time performance analysis
 - Simplified management of your partnerships

Ready to boost your revenues? Let's go! 💪💼
For any questions, our team is at your disposal.

Happy browsing and excellent results!

`;
