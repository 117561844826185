import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { buildQueryString, checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

export type listCurrenciesResponse = {
    program: MongoId;
    currencies: string[];
};

export async function listCurrencies(programId?: MongoId | MongoId[]): Promise<string[]> {
    const res = await fetch(
        getAPISubUrlForAdvertiser() +
            '/advertisers.currency' +
            buildQueryString({ programs: programId }),
        { headers: { Authorization: getJwt() } },
    );
    await checkStatus(res);
    return await res.json();
}

export async function listCurrenciesBySubEntities(
    programId?: MongoId | MongoId[],
    groupBy?: 'program' | 'currency',
): Promise<string[] | listCurrenciesResponse[]> {
    const res = await fetch(
        getAPISubUrlForAdvertiser() +
            '/advertisers.currency' +
            buildQueryString({ programs: programId, groupBy: groupBy }),
        { headers: { Authorization: getJwt() } },
    );
    await checkStatus(res);
    return await res.json();
}
