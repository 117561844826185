import './initAppConfig'; // need to be first to init the config before anything else
import '../less/app.less';
import '@draft-js-plugins/emoji/lib/plugin.css';
import '@styles/muiGlobal.less';
import 'whatwg-fetch';

import { ReactElement, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import setupLocatorUI from '@locator/runtime';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { ErrorMonitoringProvider } from '@providers/ErrorMonitoringProvider';
import { LocalizationProvider } from '@providers/LocalizationProvider';
import NotificationsProvider from '@providers/NotificationsProvider';
import store from '@providers/reduxStore';
import { lightTheme } from '@providers/theme';

import { LoadingView } from '@components/generic/LoadingView';

import { AFFILAE_CONSOLE_WELCOME } from '@libs/affilaeConsoleWelcome';
import { history } from '@libs/getSharedVar';
import { cookies } from '@libs/jwt';

import App from './App';

if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
    setupLocatorUI();
}
if (import.meta.env.PROD) {
    console.log(AFFILAE_CONSOLE_WELCOME); // eslint-disable-line no-console
}

const queryClient = new QueryClient();
initGtag();
initGtm();

function Providers({ children }: { children: ReactElement }) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={lightTheme}>
                {/* The error boundary is after mui provider
                because the error page use mui and if app
                crash, I bet it is not in those theme provider*/}
                <ErrorMonitoringProvider>
                    <QueryClientProvider client={queryClient}>
                        <ReduxProvider store={store}>
                            <Router history={history}>
                                <LocalizationProvider>
                                    <CookiesProvider cookies={cookies}>
                                        <NotificationsProvider>
                                            <Suspense fallback={<LoadingView />}>
                                                {children}
                                            </Suspense>
                                        </NotificationsProvider>
                                    </CookiesProvider>
                                </LocalizationProvider>
                            </Router>
                        </ReduxProvider>
                        <ReactQueryDevtools />
                    </QueryClientProvider>
                </ErrorMonitoringProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

const container = document.getElementById('app')!;
const root = createRoot(container);
root.render(
    <Providers>
        <App />
    </Providers>,
);

function initGtag() {
    if (CONFIG.gadsId == null) return;
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    const el = document.createElement('script');
    el.async = true;
    el.src = 'https://www.googletagmanager.com/gtag/js?id=' + CONFIG.gadsId;

    const firstScriptEl = document.getElementsByTagName('script')[0];
    firstScriptEl.parentNode.insertBefore(el, firstScriptEl);

    gtag('js', new Date());
    gtag('config', CONFIG.gadsId);
}

function initGtm() {
    if (CONFIG.gtm == null) return;
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' +
            i +
            dl +
            `&gtm_auth=${CONFIG.gtm.auth}&gtm_preview=${CONFIG.gtm.previewEnv}&gtm_cookies_win=x`;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', CONFIG.gtm.containerId);
}
