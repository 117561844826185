import {
    listContacts as listContactsAdvertiser,
    selectNewMessageAdvertiser,
} from '@pages/common/MessagePage/NewMessage/advertiserSlice';
import {
    listContacts as listContactsPublisher,
    selectNewMessagePublisher,
} from '@pages/common/MessagePage/NewMessage/NewMessagePublisher';
import { createSelector } from '@reduxjs/toolkit';
//#endregion

//#region action thunks
import { isEmpty } from 'lodash';

import { getUserType } from '@libs/getSharedVar';
import { AppDispatch, RootState } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import * as api from '@api/common/messages/index';

import { addFlash } from './common';

//#region action types
export const SEARCH_MESSAGE_REQUEST = 'SEARCH_MESSAGE_REQUEST';
export const SEARCH_MESSAGE_SUCCESS = 'SEARCH_MESSAGE_SUCCESS';
export const SEARCH_MESSAGE_FAILURE = 'SEARCH_MESSAGE_FAILURE';
export const CLOSE_SEARCH_RESULTS = 'CLOSE_SEARCH_RESULTS';

export const THREAD_FILTER_CHANGE_REQUEST = 'THREAD_FILTER_CHANGE_REQUEST';
export const THREAD_FILTER_CHANGE_SUCCESS = 'THREAD_FILTER_CHANGE_SUCCESS';
export const THREAD_FILTER_CHANGE_FAILURE = 'THREAD_FILTER_CHANGE_FAILURE';

export const LOAD_THREADS_REQUEST = 'LOAD_THREADS_REQUEST';
export const LOAD_THREADS_SUCCESS = 'LOAD_THREADS_SUCCESS';
export const LOAD_THREADS_FAILURE = 'LOAD_THREADS_FAILURE';

export const MARK_THREAD_AS_UNREAD_REQUEST = 'MARK_THREAD_AS_UNREAD_REQUEST';
export const MARK_THREAD_AS_UNREAD_SUCCESS = 'MARK_THREAD_AS_UNREAD_SUCCESS';
export const MARK_THREAD_AS_UNREAD_FAILURE = 'MARK_THREAD_AS_UNREAD_FAILURE';

export const MARK_THREAD_AS_READ_REQUEST = 'MARK_THREAD_AS_READ_REQUEST';
export const MARK_THREAD_AS_READ_SUCCESS = 'MARK_THREAD_AS_READ_SUCCESS';
export const MARK_THREAD_AS_READ_FAILURE = 'MARK_THREAD_AS_READ_FAILURE';

export const SELECT_THREAD = 'SELECT_THREAD';
export const UNSELECTED_THREAD = 'UNSELECTED_THREAD';

export const DOWNLOAD_THREAD_REQUEST = 'DOWNLOAD_THREAD_REQUEST';
export const DOWNLOAD_THREAD_SUCCESS = 'DOWNLOAD_THREAD_SUCCESS';
export const DOWNLOAD_THREAD_FAILURE = 'DOWNLOAD_THREAD_FAILURE';

export const DELETE_THREAD_FILE = 'DELETE_THREAD_FILE';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

export const SEND_GLOBAL_MESSAGE_REQUEST = 'SEND_GLOBAL_MESSAGE_REQUEST';
export const SEND_GLOBAL_MESSAGE_SUCCESS = 'SEND_GLOBAL_MESSAGE_SUCCESS';
export const SEND_GLOBAL_MESSAGE_FAILURE = 'SEND_GLOBAL_MESSAGE_FAILURE';

export const OPEN_THREAD_CREATION_PANEL = 'OPEN_THREAD_CREATION_PANEL';
export const CLOSE_THREAD_CREATION_PANEL = 'CLOSE_THREAD_CREATION_PANEL';

export const UPDATE_EDITOR_STATE = 'UPDATE_EDITOR_STATE';

export const MESSAGES_LOADING_INITIAL_REQUEST = 'MESSAGES_LOADING_INITIAL_REQUEST';
export const MESSAGES_LOADING_INITIAL_INIT_SERVER_COUNT =
    'MESSAGES_LOADING_INITIAL_INIT_SERVER_COUNT';
export const MESSAGES_LOADING_INITIAL_FAILURE = 'MESSAGES_LOADING_INITIAL_FAILURE';
export const MESSAGES_LOADING_INITIAL_SUCCESS = 'MESSAGES_LOADING_INITIAL_SUCCESS';

export const MESSAGES_LOADING_OLDEST_REQUEST = 'MESSAGES_LOADING_OLDEST_REQUEST';
export const MESSAGES_LOADING_OLDEST_FAILURE = 'MESSAGES_LOADING_OLDEST_FAILURE';
export const MESSAGES_LOADING_OLDEST_SUCCESS = 'MESSAGES_LOADING_OLDEST_SUCCESS';

export const MESSAGES_LOADING_NEW_REQUEST = 'MESSAGES_LOADING_NEW_REQUEST';
export const MESSAGES_LOADING_NEW_FAILURE = 'MESSAGES_LOADING_NEW_FAILURE';
export const MESSAGES_LOADING_NEW_SUCCESS = 'MESSAGES_LOADING_NEW_SUCCESS';

export function searchMessages(text: string) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: SEARCH_MESSAGE_REQUEST, searchText: text });
        return api
            .searchMessage(text)
            .then((results) => {
                dispatch({ type: SEARCH_MESSAGE_SUCCESS, results });
            })
            .catch((error) => {
                dispatch({ type: SEARCH_MESSAGE_FAILURE, error });
                addFlash('error', _t('messaging_failed_to_load_threads'));
            });
    };
}

// todo fix
export function threadFilterChange(filter: boolean) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: THREAD_FILTER_CHANGE_REQUEST, filter });
        return api
            .fetchThreads(filter)
            .then((threads) => {
                dispatch({ type: THREAD_FILTER_CHANGE_SUCCESS, threads });
            })
            .catch((error) => {
                dispatch({ type: THREAD_FILTER_CHANGE_FAILURE, error });
                addFlash('error', _t('messaging_failed_to_change_filter'));
            });
    };
}

export function fetchThreads() {
    return (dispatch: AppDispatch) => {
        dispatch({ type: LOAD_THREADS_REQUEST });
        return api
            .fetchThreads(false)
            .then((threads) => {
                dispatch({ type: LOAD_THREADS_SUCCESS, threads });
            })
            .catch((error) => {
                dispatch({ type: LOAD_THREADS_FAILURE, error });
                addFlash('error', _t('messaging_failed_to_load_threads'));
            });
    };
}

export function markThreadAsUnread(threadId: MongoId) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: MARK_THREAD_AS_UNREAD_REQUEST, threadId });
        return api
            .markThreadAsUnread(threadId)
            .then((thread) => {
                dispatch({ type: MARK_THREAD_AS_UNREAD_SUCCESS, thread });
            })
            .catch((error) => {
                dispatch({ type: MARK_THREAD_AS_UNREAD_FAILURE, error });
                addFlash('error', _t('messaging_failed_to_mark_thread_as_unread'));
            });
    };
}

export function markThreadAsRead(threadId: MongoId) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: MARK_THREAD_AS_READ_REQUEST, threadId });
        return api
            .markThreadAsRead(threadId)
            .then((thread) => {
                dispatch({ type: MARK_THREAD_AS_READ_SUCCESS, thread });
            })
            .catch((error) => {
                dispatch({ type: MARK_THREAD_AS_READ_FAILURE, error });
                addFlash('error', _t('messaging_failed_to_mark_thread_as_read'));
            });
    };
}

// todo
export function selectThread(threadId: MongoId, messageId: MongoId | null = null) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: SELECT_THREAD, threadId, messageId });
    };
}

// todo
export function unSelectedThread() {
    return (dispatch: AppDispatch) => {
        dispatch({ type: UNSELECTED_THREAD });
    };
}

export function downloadThread(threadId: MongoId) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: DOWNLOAD_THREAD_REQUEST, threadId });
        return api
            .downloadThread(threadId)
            .then((text) => {
                dispatch({ type: DOWNLOAD_THREAD_SUCCESS, text, threadId });
            })
            .catch((error) => {
                dispatch({ type: DOWNLOAD_THREAD_FAILURE, error });
                addFlash('error', _t('messaging_failed_to_download_thread'));
            });
    };
}

// todo
export function updateEditorState() {
    return (dispatch: AppDispatch) => {
        dispatch({ type: UPDATE_EDITOR_STATE });
    };
}

// todo
export function deleteThreadFile(threadId: MongoId) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: DELETE_THREAD_FILE, threadId });
    };
}

export function sendMessage(messageOptions, sender, recipient) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: SEND_MESSAGE_REQUEST, messageOptions });
        return api
            .sendMessage(messageOptions, sender, [recipient])
            .then((message) => {
                dispatch({ type: SEND_MESSAGE_SUCCESS, message });
                dispatch(fetchNewMsg());
            })
            .catch((error) => {
                dispatch({ type: SEND_MESSAGE_FAILURE, error });
                addFlash('error', _t('messaging_failed_to_send_message'));
            });
    };
}

export function sendGlobalMessage(messageOptions, sender, recipients) {
    return (dispatch: AppDispatch) => {
        dispatch({ type: SEND_GLOBAL_MESSAGE_REQUEST, messageOptions, sender, recipients });
        return api
            .sendMessage(messageOptions, sender, recipients)
            .then((result) => {
                // ns what to do with result? what result look like?
                dispatch({ type: SEND_GLOBAL_MESSAGE_SUCCESS });
                dispatch(fetchThreads()(dispatch));
            })
            .catch((error) => {
                dispatch({ type: SEND_GLOBAL_MESSAGE_FAILURE, error });
                addFlash('error', _t('messaging_failed_to_send_global_message'));
            });
    };
}

export function listContacts() {
    return (dispatch: AppDispatch) => {
        const userType = getUserType();
        if (userType === 'advertiser') {
            return dispatch(listContactsAdvertiser());
        } else if (userType === 'publisher') {
            return dispatch(listContactsPublisher());
        }
    };
}

// todo
export function closeSearchResults() {
    return (dispatch: AppDispatch) => {
        dispatch({ type: CLOSE_SEARCH_RESULTS });
    };
}

const steps = 30;

export function fetchOlderMsg() {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { status } = getMessageLoadingState(getState()).loadingOldest;
        if (status === 'loading') {
            throw new Error('There is already a loading in progress');
        }

        dispatch({ type: MESSAGES_LOADING_OLDEST_REQUEST });
        const { threadId, loadedRecentMsgAsc, serverCount } =
            getMessageLoadingState(getState()).mainState;

        if (serverCount == null) {
            const error = new Error(
                "Can't load older messages when server count is not initialized",
            );
            dispatch({ type: MESSAGES_LOADING_OLDEST_FAILURE, error });
        }

        const paginationOption = {
            offset: Math.max(serverCount - (loadedRecentMsgAsc || []).length - steps, 0),
            limit: Math.min(steps, serverCount - (loadedRecentMsgAsc || []).length),
        };

        let messages, totalCount;
        try {
            ({ messages, totalCount } = await api.fetchMessages(threadId, paginationOption));
        } catch (error) {
            dispatch({ type: MESSAGES_LOADING_OLDEST_FAILURE, error });
            return;
        }

        const isNewMsgNotLoaded = serverCount != totalCount;
        if (isNewMsgNotLoaded) {
            const error = {
                type: 'Latest messages not loaded',
                error: new Error('Latest messages are not loaded. Load them then try again'),
            };
            // because if there is new unload message the pagination option become
            // wrong and we don't load the correct next older messages
            dispatch({ type: MESSAGES_LOADING_OLDEST_FAILURE, error });
        }

        dispatch({ type: MESSAGES_LOADING_OLDEST_SUCCESS, messages });
    };
}

export function fetchNewMsg() {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { status } = getMessageLoadingState(getState()).loadingNew;
        if (status === 'loading') {
            throw new Error('There is already a loading in progress');
        }

        dispatch({ type: MESSAGES_LOADING_NEW_REQUEST });
        const mainState = getMessageLoadingState(getState()).mainState;
        const { serverCount: currentCount } = mainState;
        try {
            const newMsgCount = await getNewMsgCount(mainState);
            if (newMsgCount === 0) {
                dispatch({ type: MESSAGES_LOADING_NEW_SUCCESS, messages: [] });
                return;
            }
            const { messages, totalCount } = await api.fetchMessages(mainState.threadId, {
                offset: currentCount || 0,
                limit: newMsgCount,
            });
            dispatch({ type: MESSAGES_LOADING_NEW_SUCCESS, messages });
        } catch (error) {
            dispatch({ type: MESSAGES_LOADING_NEW_FAILURE, error });
        }
    };

    async function getNewMsgCount(mainState) {
        const { serverCount: currentCount } = mainState;
        if (currentCount == null) {
            throw new Error("Can't check if newest message are available if none is loaded");
        }
        const nextCount = await getServerMsgCount(mainState.threadId);
        const diff = nextCount - currentCount;
        return diff;
    }
}

async function getServerMsgCount(threadId: MongoId) {
    const { totalCount } = await api.fetchMessages(threadId, { offset: 0, limit: 0 });
    return totalCount;
}

export function fetchInitialMessage(threadId: MongoId, requestedMessageId: MongoId | null = null) {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { status } = getMessageLoadingState(getState()).loadingInitial;
        if (status === 'loading') {
            throw new Error('There is already a loading in progress');
        }
        dispatch({ type: MESSAGES_LOADING_INITIAL_REQUEST, threadId, requestedMessageId });

        try {
            const { threadId } = getMessageLoadingState(getState()).mainState;
            const count = await getServerMsgCount(threadId);
            dispatch({ type: MESSAGES_LOADING_INITIAL_INIT_SERVER_COUNT, count });
        } catch (error) {
            dispatch({ type: MESSAGES_LOADING_INITIAL_FAILURE, error });
            return;
        }

        if (requestedMessageId == null) {
            await dispatch(fetchOlderMsg());
            dispatch({ type: MESSAGES_LOADING_INITIAL_SUCCESS });
            return;
        }

        let handledErrorCount = 0;
        const handledErrorCountLimit = 5;
        do {
            await dispatch(fetchOlderMsg());

            const { status, error } = getMessageLoadingState(getState()).loadingOldest;
            if (status === 'failure' && error.type === 'Latest messages not loaded') {
                await dispatch(fetchNewMsg());
                handledErrorCount += 1;
                const { status } = getMessageLoadingState(getState()).loadingNew;
                if (status === 'failure') {
                    const error = new Error('Error with new messages loading');
                    dispatch({ type: MESSAGES_LOADING_INITIAL_FAILURE, error });
                    return;
                }
            }
        } while (
            !(hasRequestedMsg() || hasFetchAllMsg() || handledErrorCount > handledErrorCountLimit)
        );

        if (handledErrorCount > handledErrorCountLimit) {
            const error = new Error('Fetching aborted: too many new message are being received');
            dispatch({ type: MESSAGES_LOADING_INITIAL_FAILURE, error });
        }

        if (hasFetchAllMsg() && !hasRequestedMsg()) {
            const error = new Error('Requested Message not found');
            dispatch({ type: MESSAGES_LOADING_INITIAL_FAILURE, error });
        }

        dispatch({ type: MESSAGES_LOADING_INITIAL_SUCCESS });
        return;

        function hasRequestedMsg() {
            const { loadedRecentMsgAsc } = getMessageLoadingState(getState()).mainState;
            return loadedRecentMsgAsc?.find((msg) => msg.id === requestedMessageId) != null;
        }

        function hasFetchAllMsg() {
            const { loadedRecentMsgAsc, serverCount } =
                getMessageLoadingState(getState()).mainState;
            return loadedRecentMsgAsc.length === serverCount;
        }
    };
}

//#endregion

//#region redux selector
// ns of place in file structure...
// keep them here?
// they are used by multiple containers and some actions
export function getMessageState(globalState: RootState) {
    return globalState.message.message;
}

export function getMessageLoadingState(globalState: RootState) {
    return globalState.message.messageLoading;
}

export const selectSelectedThread = createSelector(
    (state: RootState) => getMessageState(state).threads.data,
    (state: RootState) => getMessageState(state).selectedThreadId,
    (threads, selectedId) => {
        if (threads == null || selectedId == null) {
            return undefined;
        }
        return threads.find((thread) => thread.id === selectedId);
    },
);

export function selectApiContacts(state: RootState) {
    const userType = getUserType();
    switch (userType) {
        case 'advertiser':
            return selectNewMessageAdvertiser(state).api;
        case 'publisher':
            return selectNewMessagePublisher(state);
    }
}

export const selectUnreadThreads = createSelector(
    (state: RootState) => getMessageState(state).threads.data,
    (threads) => {
        if (threads == null) return [];
        switch (getUserType()) {
            case 'advertiser':
                return threads.filter((thread) => thread.readAtAdvertiser === null);
            case 'publisher':
                return threads.filter((thread) => thread.readAtPublisher === null);
        }
    },
);

export const selectCountUnreadThread = createSelector(
    selectUnreadThreads,
    (threads) => threads.length,
);

export const selectHasPartnerships = createSelector(
    (state: RootState) => selectApiContacts(state).data?.partnerships,
    (partnerships) => (partnerships != null ? Object.keys(partnerships).length > 0 : false),
);

export const selectSearchResults = (state: RootState) => state.message.message.search.data;

export const selectUserSearchResults = createSelector(selectSearchResults, (results) =>
    (results ?? []).filter((r) => isEmpty(r.messages)),
);

export const selectMessageSearchResults = createSelector(selectSearchResults, (results) =>
    (results ?? []).filter((r) => !isEmpty(r.messages)),
);
//#endregion
