import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import SecurityIcon from '@mui/icons-material/Security';

/** A dialog used to preview the Two factor auth view in storybook */
export function MfaPreviewDialog({ children, ...props }: DialogProps) {
    return (
        <Dialog PaperComponent={MfaPaper} scroll='body' {...props}>
            {children}
        </Dialog>
    );
}

/** To be used for advanced integration */
export function MfaPaper({ sx, ...props }: PaperProps) {
    return (
        <Paper
            sx={{
                minWidth: {
                    xs: null,
                    sm: '500px',
                },
                padding: '1rem',
                ...sx,
            }}
            {...props}
        />
    );
}
//#endregion

export function MfaDialogTitle() {
    const { t } = useTranslation();
    return (
        <DialogTitle
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            })}
        >
            <SecurityIcon
                fontSize='inherit'
                sx={{
                    fontSize: '3rem',
                }}
            />
            <Box
                sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                        textAlign: 'center',
                    },
                })}
            >
                <Typography sx={{ fontSize: '1rem' }}> {t('mfaForm_subTitle')}</Typography>
                <Typography sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                    {t('mfaForm_title')}
                </Typography>
            </Box>
        </DialogTitle>
    );
}

export function MfaDialogAction({ sx, ...props }: DialogActionsProps) {
    return (
        <DialogActions
            sx={{
                gap: '0.5rem',
                flexDirection: {
                    xs: 'column',
                    sm: 'row',
                },
                ...sx,
            }}
            {...props}
        />
    );
}
