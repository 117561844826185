import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '@api/common/utils';
import { wait } from '@api/common/wait';

import { setCurrentVerifyState, verifyStateGen } from './getVerifyState';
import { refineVerifyState, VerifyState, VerifyStateRowResponse } from './types';

export async function sendCode(): Promise<VerifyState> {
    const response = await fetch(getAPIBaseUrl() + 'common/users/mfa.send', {
        headers: {
            Authorization: getJwt() || '',
        },
    });
    await checkStatus(response, 200);
    const data = (await response.json()) as VerifyStateRowResponse;
    return refineVerifyState(data.userMFAStatus);
}

export const sendCodeMock: typeof sendCode = async () => {
    await wait(1000);
    const state = verifyStateGen.next().value as VerifyState;
    setCurrentVerifyState(state);
    return state;
};
