import { getJwt } from '@libs/jwt';

import { getAPISubUrlForCurrentUser } from '../utils';

export function markThreadAsRead(ThreadId) {
    const request = buildRequest(ThreadId);
    return fetch(request)
        .then((response) => {
            if (response.status !== 200) {
                throw new Error(response.status + ' ' + response.statusText);
            }
            return response.json();
        })
        .then((body) => {
            return body.thread.data;
        });
}

function buildRequest(ThreadId) {
    const url = getAPISubUrlForCurrentUser() + '/messages/threads.read';
    const option = {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ threadId: ThreadId }),
    };
    return new Request(url, option);
}
