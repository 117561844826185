import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';

import { FormFields } from '@components/generic/Form';
import {
    PailheAutoComplete,
    PailheSelectCountry,
    PailheTelInput,
    PailheTextField,
} from '@components/generic/PailheComponents';

import { isInEu } from '@libs/lib_countries';
import { useAppSelector } from '@libs/reduxHooks';

import { AdvertiserType } from '@api/advertiser/fetchCurrentAdvertiserData';
import { User } from '@api/user/types';

import { BillingDetails } from './AdvertiserDetailsTab';

export const DetailsForm = ({
    fields,
    isCustomBilling,
    isSubscribed,
    hasTryToSubmit,
}: {
    fields: BillingDetails;
    isCustomBilling: boolean;
    isSubscribed: boolean;
    hasTryToSubmit: boolean;
}) => {
    const { t } = useTranslation();
    const users = useAppSelector((state) => Object.values(state.session.users.entities) as User[]);

    const isEuCountry = isInEu(fields.country.value);

    useEffect(() => {
        if (fields.isVatFree.value === true || fields.type.value === 'association') {
            fields.companyVat.setValue('');
            //fields.isVatFree.setValue(true);
        }
    }, [fields.isVatFree.value, fields.type.value]);

    return (
        <FormFields>
            <div style={{ width: '100%' }}>
                <AdvertiserTypeSelect
                    value={fields.type.value}
                    onChange={(option) => fields.type.setValue(option)}
                    disabled={isSubscribed && !isCustomBilling}
                    id='type'
                />
            </div>
            <PailheTextField
                label={t('publisher_form_companyName')}
                fullWidth
                required={fields.type.value === 'company'}
                value={fields.companyName.value}
                onChange={(event) => fields.companyName.setValue(event.target.value)}
                error={
                    hasTryToSubmit &&
                    fields.companyName.isValid === false &&
                    fields.type.value === 'company'
                }
                helperText={
                    hasTryToSubmit &&
                    fields.companyName.isValid === false &&
                    fields.type.value === 'company'
                        ? fields.companyName.helperText
                        : undefined
                }
                id='companyName'
            />

            <PailheTextField
                label={t('publisher_form_companyId')}
                fullWidth
                required={fields.type.value === 'company'}
                value={fields.companyId.value}
                onChange={(event) => fields.companyId.setValue(event.target.value)}
                error={
                    hasTryToSubmit &&
                    fields.companyId.isValid === false &&
                    fields.type.value === 'company'
                }
                helperText={
                    hasTryToSubmit &&
                    fields.companyId.isValid === false &&
                    fields.type.value === 'company'
                        ? fields.companyId.helperText
                        : undefined
                }
                id='companyId'
            />
            <div>
                <PailheTextField
                    label={t('advertiser_form_companyVat' + (isEuCountry ? '_eu' : ''))}
                    fullWidth
                    required={fields.isVatFree.value === false && fields.type.value === 'company'}
                    value={fields.isVatFree.value === false ? fields.companyVat.value : ''}
                    onChange={(event) => fields.companyVat.setValue(event.target.value)}
                    error={
                        hasTryToSubmit &&
                        fields.companyVat.isValid === false &&
                        fields.type.value === 'company' &&
                        fields.isVatFree.value === false
                    }
                    helperText={
                        hasTryToSubmit &&
                        fields.companyVat.isValid === false &&
                        fields.type.value === 'company' &&
                        fields.isVatFree.value === false
                            ? fields.companyVat.helperText
                            : undefined
                    }
                    disabled={fields.isVatFree.value === true}
                    id='companyVat'
                />
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={fields.isVatFree.value}
                                onChange={() => fields.isVatFree.setValue(!fields.isVatFree.value)}
                                id='isVatFree'
                            />
                        }
                        label={t('billing_details_is_vat_free_label' + (isEuCountry ? '_eu' : ''))}
                    />
                </FormGroup>
            </div>
            <PailheAutoComplete
                id='billingUsers'
                TextFieldProps={{
                    label: t('advertiser_form_billing_users'),
                    fullWidth: true,
                }}
                options={users}
                value={users.filter((user) => fields.billingUsers.value.includes(user.id))}
                onChange={(_, newUsers) => {
                    fields.billingUsers.setValue(newUsers.map((user) => user.id));
                }}
                multiple
                disableClearable
                getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                renderOption={(props, option) => (
                    <Box component='li' {...props}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Box sx={{ fontWeight: 500 }}>
                                {option.firstname} {option.lastname}
                            </Box>
                            <Box sx={{ fontSize: 12, color: 'text.secondary' }}>{option.email}</Box>
                        </Box>
                    </Box>
                )}
            />

            <PailheTelInput
                label={t('publisher_form_phone')}
                required
                style={{ width: '100%' }}
                value={fields.companyPhone.value}
                onChange={(_, { numberValue: value }) => fields.companyPhone.setValue(value ?? '')}
                variant='outlined'
                id='phone'
                autoComplete='tel'
                defaultCountry='FR'
                fullWidth
                error={hasTryToSubmit && fields.companyPhone.isValid === false}
                helperText={
                    hasTryToSubmit && fields.companyPhone.isValid === false
                        ? fields.companyPhone.helperText
                        : undefined
                }
            />

            <PailheTextField
                label={t('publisher_form_address')}
                fullWidth
                required
                value={fields.address.value}
                onChange={(event) => fields.address.setValue(event.target.value)}
                error={hasTryToSubmit && fields.address.isValid === false}
                helperText={
                    hasTryToSubmit && fields.address.isValid === false
                        ? fields.address.helperText
                        : undefined
                }
                id='address'
            />
            <PailheTextField
                label={t('publisher_form_zipcode')}
                fullWidth
                required
                value={fields.zipcode.value}
                onChange={(event) => fields.zipcode.setValue(event.target.value)}
                error={hasTryToSubmit && fields.zipcode.isValid === false}
                helperText={
                    hasTryToSubmit && fields.zipcode.isValid === false
                        ? fields.zipcode.helperText
                        : undefined
                }
                id='zipCode'
            />

            <PailheTextField
                label={t('publisher_form_city')}
                fullWidth
                required
                value={fields.city.value}
                onChange={(event) => fields.city.setValue(event.target.value)}
                error={hasTryToSubmit && fields.city.isValid === false}
                helperText={
                    hasTryToSubmit && fields.city.isValid === false
                        ? fields.city.helperText
                        : undefined
                }
                id='city'
            />

            <div>
                <PailheSelectCountry
                    id='country'
                    value={fields.country.value}
                    onChange={(code) => fields.country.setValue(code)}
                    disabled={isSubscribed && !isCustomBilling}
                    TextFieldProps={{
                        label: t('publisher_form_country'),
                        fullWidth: true,
                        required: true,
                    }}
                />
            </div>
        </FormFields>
    );
};

export function AdvertiserTypeSelect({
    value: selectedOptionValue,
    onChange,
    disabled,
    ...props
}: {
    value: AdvertiserType;
    onChange: (value: AdvertiserType) => void;
    disabled: boolean;
}) {
    // ns: Should check selectedOptionValue possible values?
    const { t } = useTranslation();
    const options = [
        { value: 'company' as AdvertiserType, label: t('publisher_form_type_company') },
        { value: 'association' as AdvertiserType, label: t('publisher_form_type_association') },
    ];

    const selectedOption = options.find(({ value }) => value === selectedOptionValue);

    return (
        <PailheAutoComplete
            TextFieldProps={{
                label: t('publisher_form_type'),
                fullWidth: true,
                placeholder: t('publisher_form_type'),
            }}
            options={options}
            value={selectedOption}
            disableClearable
            onChange={(event, option) => onChange(option.value)}
            disabled={disabled}
            {...props}
        />
    );
}
