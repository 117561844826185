import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

import { LoadingStatus } from '@libs/LoadingStatus';
import { RootState } from '@libs/reduxHooks';

import apiAddUser from '@api/user/add';
import apiDeleteUser from '@api/user/delete';
import apiListUsers from '@api/user/list';
import { User } from '@api/user/types';
import apiUpdateUser from '@api/user/update';

const sliceName = 'session/users';
export const listUsers = createAsyncThunk(`${sliceName}/getAll`, apiListUsers);
export const addUser = createAsyncThunk(`${sliceName}/addOne`, apiAddUser);
export const updateUser = createAsyncThunk(`${sliceName}/updateOne`, apiUpdateUser);
export const deleteUser = createAsyncThunk(`${sliceName}/deleteOne`, apiDeleteUser);

const userAdapter = createEntityAdapter<User>();
export const userSlice = createSlice({
    name: sliceName,
    initialState: userAdapter.getInitialState({
        getLoading: 'idle' as LoadingStatus,
        addLoading: 'idle' as LoadingStatus,
        updateLoading: 'idle' as LoadingStatus,
        deleteLoading: 'idle' as LoadingStatus,
        confirmEmailLoading: 'idle' as LoadingStatus,
    }),
    reducers: {
        logout(state) {
            userAdapter.removeAll(state);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(listUsers.pending, (state) => {
            state.getLoading = 'loading';
        });
        builder.addCase(listUsers.fulfilled, (state, action) => {
            state.getLoading = 'success';
            userAdapter.setAll(state, action.payload);
        });
        builder.addCase(listUsers.rejected, (state) => {
            state.getLoading = 'failure';
        });

        builder.addCase(addUser.pending, (state) => {
            state.addLoading = 'loading';
        });
        builder.addCase(addUser.fulfilled, (state, action) => {
            state.addLoading = 'success';
            userAdapter.addOne(state, action.payload);
        });
        builder.addCase(addUser.rejected, (state) => {
            state.addLoading = 'failure';
        });

        builder.addCase(updateUser.pending, (state) => {
            state.updateLoading = 'loading';
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.updateLoading = 'success';
            userAdapter.setOne(state, action.payload);
        });
        builder.addCase(updateUser.rejected, (state) => {
            state.updateLoading = 'failure';
        });

        builder.addCase(deleteUser.pending, (state) => {
            state.deleteLoading = 'loading';
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.deleteLoading = 'success';
            userAdapter.removeOne(state, action.meta.arg);
        });
        builder.addCase(deleteUser.rejected, (state) => {
            state.deleteLoading = 'failure';
        });
    },
});

export const { logout } = userSlice.actions;

/**
 * Search users by fullname and email
 */
function searchUsers(users: User[], searchText?: string) {
    if (searchText == null || searchText === '') return users;
    const regex = new RegExp(searchText, 'i');
    return users.filter(
        (user) => regex.test(user.firstname + ' ' + user.lastname) || regex.test(user.email),
    );
}

export const selectUsers = (state: RootState) =>
    Object.values(state.session.users.entities) as User[];

export const selectSearchedUser = createSelector(
    selectUsers,
    (_, searchText?: string) => searchText,
    (users, searchText) => searchUsers(users, searchText),
);

export const selectUsersCount = createSelector(selectUsers, (users) => users.length);
