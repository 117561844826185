import {
    BUILD_URL,
    BUILD_URL_FAILURE,
    BUILD_URL_SUCCESS,
    FETCH_AFFILAE_ADMINS,
    FETCH_AFFILAE_ADMINS_FAILURE,
    FETCH_AFFILAE_ADMINS_SUCCESS,
    FETCH_PRODUCT_CATEGORIES,
    FETCH_PRODUCT_CATEGORIES_FAILURE,
    FETCH_PRODUCT_CATEGORIES_SUCCESS,
    listPlans,
    UPDATE_LINK_BUILDER_FIELDS,
} from '@actions/common';

import { Plans } from '@api/advertiser/listPlans';
import { User } from '@api/user/types';

const INITIAL_STATE: CommonState = {
    affilaeAdmins: [],
    productCategories: [],
    plans: {},
    linkBuilder: {
        result: '',
    },
};

type CommonState = {
    affilaeAdmins: User[];
    productCategories: never[];
    plans: Plans;
    linkBuilder: {
        result: string;
    };
};

export default function (state: CommonState = INITIAL_STATE, action): CommonState {
    switch (action.type) {
        case FETCH_PRODUCT_CATEGORIES:
            return {
                ...state,
            };
        case FETCH_PRODUCT_CATEGORIES_SUCCESS:
            return {
                ...state,
                productCategories: action.payload,
            };
        case FETCH_PRODUCT_CATEGORIES_FAILURE:
            return {
                ...state,
            };
        case FETCH_AFFILAE_ADMINS:
            return {
                ...state,
            };
        case FETCH_AFFILAE_ADMINS_SUCCESS:
            return {
                ...state,
                affilaeAdmins: action.payload,
            };
        case FETCH_AFFILAE_ADMINS_FAILURE:
            return {
                ...state,
            };
        case listPlans.pending.type:
            return {
                ...state,
            };
        case listPlans.fulfilled.type:
            return {
                ...state,
                plans: action.payload,
            };
        case listPlans.rejected.type:
            return {
                ...state,
            };
        case UPDATE_LINK_BUILDER_FIELDS:
            return {
                ...state,
            };
        case BUILD_URL:
            return {
                ...state,
            };
        case BUILD_URL_SUCCESS:
            return {
                ...state,
                linkBuilder: {
                    ...state.linkBuilder,
                    result: action.payload,
                },
            };
        case BUILD_URL_FAILURE:
            return {
                ...state,
            };
    }
    return state;
}
