import { reducer as newMessageAdvertiser } from '@pages/common/MessagePage/NewMessage/advertiserSlice';
import { reducer as newMessagePublisher } from '@pages/common/MessagePage/NewMessage/NewMessagePublisher';
import { combineReducers } from 'redux';

import message from './message';
import messageLoading from './messageLoading';

const reducer = combineReducers({
    message,
    newMessageAdvertiser,
    newMessagePublisher,
    messageLoading,
});

export default reducer;
