import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { ErrorView } from '@components/generic/ErrorView';
import { LoadingView } from '@components/generic/LoadingView';

import { useOldAppState } from '@hooks/useOldAppState';

import { ParamTypesAdvertiser } from '@libs/getSharedVar';
import { LoadingStatus } from '@libs/LoadingStatus';

import fetchProgramInfo from '@api/advertiser/fetchProgramInfo';
import { listCurrencies as listCurrenciesAdvertiser } from '@api/advertiser/listCurrencies';
import fetchAds from '@api/common/fetchAds';
import fetchPartnerships from '@api/common/partnerships/list';

export function ProgramProvider({ children }: { children: JSX.Element }) {
    const { app } = useOldAppState();
    const { params } = useRouteMatch<ParamTypesAdvertiser>();
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('idle');
    const currentProgramId = params.programId;
    const currentProgramIdPreviousRef = useRef(null);

    useEffect(() => {
        if (
            currentProgramId == null ||
            currentProgramIdPreviousRef.current != null ||
            currentProgramId === currentProgramIdPreviousRef.current
        ) {
            return;
        }

        currentProgramIdPreviousRef.current = currentProgramId;

        (async function () {
            setLoadingStatus('pending');
            try {
                await Promise.all([
                    handleFetchCurrentProgram(),
                    handleFetchProgramCurrencies(),
                    handleFetchCurrentProgramPartnerships(),
                    handleFetchCurrentProgramAds(),
                ]);
                setLoadingStatus('success');
            } catch (error) {
                setLoadingStatus('failure');
            }
        })();
    }, [currentProgramId]);

    if (
        currentProgramIdPreviousRef.current != null &&
        currentProgramId !== currentProgramIdPreviousRef.current
    ) {
        // when we detect that the user want to consult a
        // page related to a different program that the first he consulted, we need to
        // reload the application because our pages don't support
        // currentProgram change on the fly without full app reloading
        window.location.reload();
        return null;
    }

    if (loadingStatus === 'idle' || loadingStatus === 'pending') {
        return <LoadingView />;
    } else if (loadingStatus === 'failure') {
        return <ErrorView />;
    } else {
        return children;
    }

    async function handleFetchProgramCurrencies() {
        try {
            const currencies = await listCurrenciesAdvertiser(currentProgramId);
            app.setState({ currentSubEntitiesCurrencies: currencies });
        } catch (error) {
            window.addFlash('error', 'Fail to fetch currencies associated to program');
            console.error(error);
            throw error;
            // todo: report to sentry
        }
    }

    async function handleFetchCurrentProgram() {
        try {
            const currentProgram = await fetchProgramInfo(currentProgramId);
            if (currentProgram.advertiser !== app.state.advertiser.id) {
                throw new Error(
                    `The program '${currentProgram.id}' you try to access is not yours. It is a program from another advertiser`,
                );
            }
            app.setState({ currentProgram });
        } catch (error) {
            window.addFlash('error', 'Fail to fetch current program');
            console.error(error);
            throw error;
            // todo: report to sentry
        }
    }

    async function handleFetchCurrentProgramPartnerships() {
        try {
            const currentProgramPartnerships = await fetchPartnerships('advertiser', {
                program: currentProgramId,
                orderBy: 'name',
                sort: 'asc',
            });
            app.setState({
                currentPartnerships: currentProgramPartnerships,
            });
        } catch (error) {
            window.addFlash('error', 'Fail to fetch partnerships');
            console.error(error);
            throw error;
            // todo: report to sentry
        }
    }

    async function handleFetchCurrentProgramAds() {
        try {
            const currentAds = await fetchAds({ program: currentProgramId });
            app.setState({ currentAds });
        } catch (error) {
            window.addFlash('error', 'Fail to fetch ads');
            console.error(error);
            throw error;
            // todo: report to sentry
        }
    }
}
