import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniqWith from 'lodash/uniqWith';

import { LoadingState } from '@libs/LoadingStatus';
import { RootState } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import {
    AffiliateProfile,
    ApiData,
    listContacts as apiListContact,
    Category,
    getCategoryTrad,
    getUserFullName,
    Group,
    Partnership,
    User,
} from '@api/common/messages/listContactsAdvertiser';
import { PartnershipStatus } from '@api/common/partnerships/list';
import { ApiError } from '@api/common/utils';

export interface NewMessageState {
    api: LoadingState<ApiData>;
    senderId: MongoId | undefined;
    searchText: string;
    contacts: Array<Contact>;
    filter: ContactFilter;
}
export type Contact = ContactSimple | ContactFilter;
export interface ContactSimple {
    subEntity: MongoId;
    user: MongoId;
}

export type ContactFilter = AtLeastOne<{
    group: MongoId;
    status: PartnershipStatus;
    category: MongoId;
    active: boolean;
}>;

export const newMessageStateInitial: NewMessageState = {
    api: { status: 'idle' },
    senderId: undefined,
    searchText: '',
    contacts: [],
    filter: {
        status: 'approved',
    },
};

const slicePrefix = 'newMessage';

export const listContacts = createAsyncThunk(`${slicePrefix}/listContacts`, apiListContact);
const slice = createSlice({
    name: slicePrefix,
    initialState: newMessageStateInitial,
    reducers: {
        changeSender(state, action: PayloadAction<MongoId>) {
            state.senderId = action.payload;
            state.contacts = [];
            contactRelatedDataCache = {};
        },
        changeSearchText(state, action: PayloadAction<string>) {
            state.searchText = action.payload;
        },
        addContact(state, action: PayloadAction<Contact>) {
            state.contacts.push(action.payload);
        },
        removeContact(state, action: PayloadAction<Contact>) {
            // could improve perf by changing data structure by a dictionary
            state.contacts = state.contacts.filter((r) => !isEqualContact(r, action.payload));
        },
        removeAllContact(state) {
            state.contacts = [];
        },
        changeFilterGroup(state, action: PayloadAction<MongoId | undefined>) {
            state.filter = {
                ...state.filter,
                group: action.payload,
            };
        },
        changeFilterStatus(state, action: PayloadAction<PartnershipStatus | undefined>) {
            state.filter = {
                ...state.filter,
                status: action.payload,
            };
        },
        changeFilterCategory(state, action: PayloadAction<MongoId | undefined>) {
            state.filter = {
                ...state.filter,
                category: action.payload,
            };
        },
        changeFilerActive(state, action: PayloadAction<boolean | undefined>) {
            state.filter = {
                ...state.filter,
                active: action.payload,
            };
        },
        changeFilter(state, action: PayloadAction<ContactFilter>) {
            state.filter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(listContacts.pending, (state) => {
            state.api = {
                ...state.api,
                status: 'loading',
            };
        });
        builder.addCase(listContacts.fulfilled, (state, action) => {
            state.api = {
                ...state.api,
                status: 'success',
                data: action.payload,
            };
        });
        builder.addCase(listContacts.rejected, (state, action) => {
            state.api = {
                ...state.api,
                status: 'failure',
                error: action.error as ApiError,
            };
        });
    },
});

export const { reducer } = slice;

export const {
    changeSender,
    changeSearchText,
    addContact,
    removeContact,
    removeAllContact,
    changeFilterCategory,
    changeFilterGroup,
    changeFilterStatus,
    changeFilerActive,
    changeFilter,
} = slice.actions;

//#region utility
export function isEqualContact(a: Contact, b: Contact): boolean {
    const isASimple = isContactSimple(a);
    const isBSimple = isContactSimple(b);
    if (isASimple && isBSimple) {
        return isEqualContactSimple(a, b);
    } else if (!isASimple && !isBSimple) {
        return isEqualContactFilter(a, b);
    } else {
        return false;
    }
}

export function isEqualContactSimple(a: ContactSimple, b: ContactSimple): boolean {
    return a.user === b.user && a.subEntity === b.subEntity;
}

export function isEqualContactFilter(a: ContactFilter, b: ContactFilter): boolean {
    return (
        a.active === b.active &&
        a.category === b.category &&
        a.group === b.group &&
        a.status === b.status
    );
}

export function isContactSimple(contact: Contact): contact is ContactSimple {
    return Object.keys(contact).includes('user');
}

export function isContactFilter(contact: Contact): contact is ContactFilter {
    return !isContactSimple(contact);
}
//#endregion

// todo extract?
/** https://stackoverflow.com/a/48244432/7213091 */
type AtLeastOne<
    T,
    U = {
        [K in keyof T]: Pick<T, K>;
    },
> = Partial<T> & U[keyof U];

export const selectNewMessageAdvertiser = (state: RootState) => state.message.newMessageAdvertiser;
export const selectFinalContacts = createSelector(
    (state: RootState) => selectNewMessageAdvertiser(state).contacts,
    (state: RootState) => selectNewMessageAdvertiser(state).api.data!,
    (state: RootState) => selectNewMessageAdvertiser(state).senderId!,
    getFinalContacts,
);

//#region computation
function getFinalContacts(
    contacts: Contact[],
    apiData: ApiData,
    senderId: string,
): ContactSimple[] {
    const contactsAvailableForSender = getContacts(apiData, senderId);
    const contactsMerged: ContactSimple[] = contacts
        .map((contact) => {
            return isContactFilter(contact)
                ? filterContact(contactsAvailableForSender, contact, apiData, senderId)
                : contact;
        })
        .flat();
    const contactsUniq = uniqWith(contactsMerged, isEqualContact);
    return contactsUniq;
}

export function filterContact(
    contacts: ContactSimple[],
    filter: ContactFilter,
    apiData: ApiData,
    senderId: string,
    search?: string,
) {
    const contactsData = contacts
        .map((contact) => getContactRelatedData(contact, apiData, senderId))
        .filter((el): el is ContactRelatedData => el != null);
    const contactsDataFiltered = filterContactRelatedData(contactsData, filter, search);
    const contactsFiltered = contactsDataFiltered.map(getContactFromContactRelatedData);
    return contactsFiltered;
}

export function getContacts(apiData: ApiData, senderId: string): ContactSimple[] {
    return Object.values(apiData.partnerships)
        .filter((p) => p.program === senderId)
        .map((partnership): ContactSimple | undefined => {
            const affiliateProfile = apiData.affiliateProfiles[partnership.affiliateProfile];
            if (affiliateProfile == null) return;
            const manager = apiData.users[affiliateProfile.manager];
            if (manager == null) return;
            return {
                subEntity: affiliateProfile.id,
                user: manager.id,
            };
        })
        .filter((el): el is ContactSimple => el != null);
}

// todo reconsider name
export interface ContactRelatedData {
    affiliateProfile: AffiliateProfile;
    user: User;
    partnership: Partnership;
    group: Group;
    categories: Category[];
}

let contactRelatedDataCache: Record<string, ContactRelatedData> = {};

export function getContactRelatedData(
    contact: ContactSimple,
    apiData: ApiData,
    senderId: string,
): ContactRelatedData | undefined {
    const contactId = JSON.stringify(contact);

    if (contactRelatedDataCache[contactId] != null) {
        return contactRelatedDataCache[contactId];
    }

    const affiliateProfile = apiData.affiliateProfiles[contact.subEntity];
    const user = apiData.users[contact.user];

    if (affiliateProfile == null || user == null) return;

    const partnership = Object.values(apiData.partnerships).find(
        (p) => p.program === senderId && p.affiliateProfile === affiliateProfile.id,
    )!;
    const group = apiData.groups[partnership.group];
    const categories = affiliateProfile.categories.map((id) => apiData.categories[id]);

    if (partnership == null || group == null || categories.some((el) => el == null)) return;

    const relateData: ContactRelatedData = {
        affiliateProfile,
        user,
        partnership,
        group,
        categories,
    };
    contactRelatedDataCache[contactId] = relateData;
    return relateData;
}

export function getContactFromContactRelatedData(contactData: ContactRelatedData): ContactSimple {
    return {
        subEntity: contactData.affiliateProfile.id,
        user: contactData.user.id,
    };
}

export function filterContactRelatedData(
    contactsData: ContactRelatedData[],
    filter: ContactFilter,
    search?: string,
) {
    let filtered = contactsData;
    const { group, category, status, active } = filter;
    if (group != null) filtered = filtered.filter((data) => data.group.id === group);
    if (category != null)
        filtered = filtered.filter((data) => data.categories.map((c) => c.id).includes(category));
    if (status != null)
        filtered = filtered.filter((data) => data.partnership.status.includes(status));
    if (active != null) filtered = filtered.filter((data) => data.partnership.active === active);

    if (search != null && search.trim() !== '') {
        let regex: RegExp;
        try {
            regex = new RegExp(search, 'i');
        } catch (error) {
            return [];
        }

        filtered = filtered.filter((data) => {
            return (
                regex.test(data.affiliateProfile.title) ||
                regex.test(getUserFullName(data.user)) ||
                regex.test(data.group.title) ||
                data.categories.some((c) => regex.test(getCategoryTrad(c)))
            );
        });
    }
    return filtered;
}

export interface MessageEditorAdvertiserContact {
    affiliateProfile: string;
    user: string;
}

export function transformToAdvertiserContact(
    contact: ContactSimple,
): MessageEditorAdvertiserContact {
    return {
        affiliateProfile: contact.subEntity,
        user: contact.user,
    };
}

//#endregion
