import { ADVERTISER_SET_PROGRAMS, INIT_CURRENT_ADVERTISER } from '@actions/advertiser';

import { Advertiser } from '@api/advertiser/fetchCurrentAdvertiserData';

const INITIAL_STATE: AdvertiserState = {
    currentAdvertiser: null,
    programs: [],
};

type AdvertiserState = {
    currentAdvertiser: Advertiser | null;
    programs: unknown[];
};

export default function (state: AdvertiserState = INITIAL_STATE, action): AdvertiserState {
    switch (action.type) {
        case INIT_CURRENT_ADVERTISER:
            return {
                ...state,
                currentAdvertiser: action.currentAdvertiser,
            };

        case ADVERTISER_SET_PROGRAMS: {
            return {
                ...state,
                programs: action.programs,
            };
        }
    }
    return state;
}
