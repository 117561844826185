import {
    PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_FAILURE,
    PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_REQUEST,
    PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_SUCCESS,
    PAGE_PRODUCT_AM_TABLE_SET_FILTER,
    PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_REQUEST,
    PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_SUCCESS,
    PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_FAILURE,
    PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_REQUEST,
    PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_SUCCESS,
} from '@actions/productsAm';
import _ from 'lodash';

import { getDefaultFilterFromUrl } from '@components/admin/Products/ProductsTable/TableFilter/tableFilterUrlUtils';

const initialState = {
    products: [],
    isLoading: false,
    filter: getDefaultFilterFromUrl(),
    productsUpdatingIsFeatured: {},
    alreadyInitialized: false,
};

export default function reducer(state = initialState, action) {
    const newState = _.cloneDeep(state);
    switch (action.type) {
        //#region PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT
        case PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_REQUEST:
            newState.isLoading = true;
            break;

        case PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_SUCCESS:
            newState.products = action.products;
            newState.isLoading = false;
            newState.alreadyInitialized = true;
            break;

        case PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_FAILURE:
            newState.products = [];
            newState.isLoading = false;
            newState.alreadyInitialized = true;
            break;
        //#endregion

        //#region PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS
        case PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_REQUEST: {
            // todo do something ?
            break;
        }

        case PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_SUCCESS: {
            const { productId, updatedProduct } = action;
            updateProduct(newState, productId, updatedProduct);
            break;
        }
        //#endregion

        case PAGE_PRODUCT_AM_TABLE_SET_FILTER: {
            newState.filter = action.filter;
            break;
        }

        //#region PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED
        case PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_REQUEST: {
            newState.productsUpdatingIsFeatured[action.productId] = true;
            break;
        }

        case PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_FAILURE: {
            const { productId } = action;
            delete newState.productsUpdatingIsFeatured[productId];
            break;
        }
        case PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_SUCCESS: {
            const { productId, updatedProduct } = action;
            delete newState.productsUpdatingIsFeatured[productId];
            updateProduct(newState, productId, updatedProduct);
            break;
        }
        //#endregion

        default:
            break;
    }
    return newState;
}

//#region useful state modifier
function updateProduct(state, productId, updatedProduct) {
    const index = state.products.findIndex((product) => product.id === productId);
    const hasToKeepProduct = isProductMatchingFilter(updatedProduct, state.filter);
    if (hasToKeepProduct) {
        state.products[index] = updatedProduct;
    } else {
        state.products.splice(index, 1);
    }
}

function isProductMatchingFilter(product, filter) {
    const isMatching =
        isProductMatchingStatuses(product, filter.validityStatuses) &&
        isProductMatchingIsFeatured(product, filter.isFeatured);
    return isMatching;
}

function isProductMatchingStatuses(product, statuses) {
    const status = product.statusAdmin;
    if (_.isEmpty(statuses)) {
        return true;
    } else {
        return statuses.includes(status);
    }
}

function isProductMatchingIsFeatured(product, isFeatured) {
    if (isFeatured == null) {
        return true;
    }
    const isProductFeatured = product.featuredAt != null;
    return isProductFeatured === isFeatured;
}

//#endregion
