import _ from 'lodash';
import { PublisherPaymentState } from 'reducers/reducer_publisher_payments';

import {
    formatMomentForApi,
    getExplicitPeriodFromPeriodRef,
    PeriodRef,
} from '@components/generic/DateRangePicker';

import { downloadBlob } from '@libs/fileHelper';
import { getCurrentAffiliateProfileId } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';
import { AppDispatch } from '@libs/reduxHooks';

import { getAPISubUrlForPublisher } from '@api/common/utils';

export const INIT_AFFILIATE_PROFILE = 'INIT_AFFILIATE_PROFILE';

export const PUBLISHER_PAYMENT_LOADING = 'PUBLISHER_PAYMENT_LOADING';
export const PUBLISHER_PAYMENT_EARNINGS_LOADING = 'PUBLISHER_PAYMENT_EARNINGS_LOADING';

// export const UPDATE_ADVERTISER_PAYMENTS_CRITERIA = 'UPDATE_ADVERTISER_PAYMENTS_CRITERIA';
// export const UPDATE_ADVERTISER_EARNINGS_CRITERIA = 'UPDATE_ADVERTISER_EARNINGS_CRITERIA';

export const FETCH_PUBLISHER_PAYMENTS_DATA = 'FETCH_PUBLISHER_PAYMENTS_DATA';
export const FETCH_PUBLISHER_PAYMENTS_DATA_SUCCESS = 'FETCH_PUBLISHER_PAYMENTS_DATA_SUCCESS';
export const FETCH_PUBLISHER_PAYMENTS_DATA_FAILURE = 'FETCH_PUBLISHER_PAYMENTS_DATA_FAILURE';

export const FETCH_PUBLISHER_PAYMENT_REQUEST = 'FETCH_PUBLISHER_PAYMENT_REQUEST';
export const FETCH_PUBLISHER_PAYMENT_REQUEST_SUCCESS = 'FETCH_PUBLISHER_PAYMENT_REQUEST_SUCCESS';
export const FETCH_PUBLISHER_PAYMENT_REQUEST_FAILURE = 'FETCH_PUBLISHER_PAYMENT_REQUEST_FAILURE';

export const FETCH_PUBLISHER_EARNINGS_DATA = 'FETCH_PUBLISHER_EARNINGS_DATA';
export const FETCH_PUBLISHER_EARNINGS_DATA_SUCCESS = 'FETCH_PUBLISHER_EARNINGS_DATA_SUCCESS';
export const FETCH_PUBLISHER_EARNINGS_DATA_FAILURE = 'FETCH_PUBLISHER_EARNINGS_DATA_FAILURE';

export const OPEN_INVOICE = 'OPEN_INVOICE';
export const OPEN_INVOICE_SUCCESS = 'OPEN_INVOICE_SUCCESS';
export const OPEN_INVOICE_FAILURE = 'OPEN_INVOICE_FAILURE';

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';

export const ADD_INVOICE = 'ADD_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_FAILURE = 'ADD_INVOICE_FAILURE';

export const OPEN_DELETE_CUSTOM_INVOICE_DIALOG = 'OPEN_DELETE_CUSTOM_INVOICE_DIALOG';

export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';

export function initAffiliateProfile(affiliateProfile) {
    return {
        type: INIT_AFFILIATE_PROFILE,
        affiliateProfile: affiliateProfile,
    };
}

export function publisherPaymentLoading() {
    return {
        type: PUBLISHER_PAYMENT_LOADING,
    };
}

export function publisherPaymentEarningsLoading() {
    return {
        type: PUBLISHER_PAYMENT_EARNINGS_LOADING,
    };
}

export function fetchPublisherPaymentsDataThunk(queryCriteria) {
    return async (dispatch: AppDispatch) => {
        let statusCode: number;
        dispatch(publisherPaymentLoading());
        dispatch(fetchPublisherPaymentsData(queryCriteria))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(fetchPublisherPaymentsDataFailure(statusCode));
                } else {
                    dispatch(fetchPublisherPaymentsDataSuccess(result));
                }
            });
    };
}

export function fetchPublisherPaymentsData(queryCriteria) {
    const periodRef = queryCriteria.dateRange as PeriodRef;
    const { from, to } = getExplicitPeriodFromPeriodRef(periodRef);
    const bodyObj = {
        affiliateProfile: queryCriteria.affiliateProfile,
        currency: queryCriteria.currency,
        from: formatMomentForApi(from),
        to: formatMomentForApi(to),
    };
    if (!_.isEmpty(queryCriteria.partnership)) {
        bodyObj.partnership = queryCriteria.partnership;
    }
    if (!_.isEmpty(queryCriteria.status)) {
        bodyObj.status = queryCriteria.status;
    }
    if (!_.isEmpty(queryCriteria.search)) {
        bodyObj.filter = queryCriteria.search;
    }
    const promise = fetch(getAPISubUrlForPublisher() + '/paymentRequests.list', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: FETCH_PUBLISHER_PAYMENTS_DATA,
        payload: promise,
    };
}

function fetchPublisherPaymentsDataSuccess(data) {
    return {
        type: FETCH_PUBLISHER_PAYMENTS_DATA_SUCCESS,
        payload: data,
    };
}

function fetchPublisherPaymentsDataFailure(error) {
    return {
        type: FETCH_PUBLISHER_PAYMENTS_DATA_FAILURE,
        payload: error,
    };
}

export function fetchPublisherPaymentRequest() {
    return {
        type: FETCH_PUBLISHER_PAYMENT_REQUEST,
    };
}

export function fetchPublisherPaymentRequestSuccess(data) {
    return {
        type: FETCH_PUBLISHER_PAYMENT_REQUEST_SUCCESS,
        payload: data,
    };
}

export function fetchPublisherPaymentRequestFailure(error) {
    return {
        type: FETCH_PUBLISHER_PAYMENT_REQUEST_FAILURE,
        payload: error,
    };
}

export function fetchPublisherEarningsDataThunk(
    queryCriteria: PublisherPaymentState['earnings']['queryCriteria'],
) {
    return (dispatch: AppDispatch) => {
        let statusCode: number;
        dispatch(publisherPaymentEarningsLoading());
        dispatch(fetchPublisherEarningsData(queryCriteria))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(fetchPublisherEarningsDataFailure(statusCode));
                } else {
                    dispatch(fetchPublisherEarningsDataSuccess(result, queryCriteria));
                }
            });
    };
}

export function fetchPublisherEarningsData(
    queryCriteria: PublisherPaymentState['earnings']['queryCriteria'],
) {
    const periodRef = queryCriteria.dateRange as PeriodRef;
    const { from, to } = getExplicitPeriodFromPeriodRef(periodRef);
    const bodyObj = {
        affiliateProfile: getCurrentAffiliateProfileId(),
        currency: queryCriteria.currency,
        from: formatMomentForApi(from),
        to: formatMomentForApi(to),
    };
    if (!_.isEmpty(queryCriteria.partnership)) {
        bodyObj.partnership = queryCriteria.partnership;
    }
    if (!_.isEmpty(queryCriteria.status)) {
        bodyObj.status = queryCriteria.status;
    }
    if (!_.isEmpty(queryCriteria.search)) {
        bodyObj.filter = queryCriteria.search;
    }
    const promise = fetch(getAPISubUrlForPublisher() + '/affiliateEarnings.list', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: FETCH_PUBLISHER_EARNINGS_DATA,
        payload: promise,
    };
}

export function fetchPublisherEarningsDataSuccess(data, queryCriteria) {
    const currency = queryCriteria.currency;
    // We filter earnings to exclude commissions zero
    const positiveEarnings = _.filter(data, function (earningObject) {
        return (
            earningObject.commissions[currency] && earningObject.commissions[currency].total !== 0
        );
    });
    return {
        type: FETCH_PUBLISHER_EARNINGS_DATA_SUCCESS,
        payload: positiveEarnings,
    };
}

export function fetchPublisherEarningsDataFailure(error) {
    return {
        type: FETCH_PUBLISHER_EARNINGS_DATA_FAILURE,
        payload: error,
    };
}

export function openInvoiceThunk(paymentRequestId) {
    return (dispatch: any) => {
        let statusCode: number;
        dispatch(openInvoicePublisher(paymentRequestId))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.blob();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(openInvoicePublisherFailure(statusCode));
                } else {
                    dispatch(openInvoicePublisherSuccess(result));
                }
            });
    };
}

export function openInvoicePublisher(paymentRequestId) {
    let ids = [];
    if (typeof paymentRequestId === 'string') {
        ids.push(paymentRequestId);
    } else {
        ids = paymentRequestId;
    }
    const bodyObj = {
        id: ids,
    };
    const promise = fetch(getAPISubUrlForPublisher() + '/paymentRequests/invoices.get', {
        method: 'POST',
        responseType: 'blob',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: OPEN_INVOICE,
        payload: promise,
    };
}

export function openInvoicePublisherSuccess(data, identifier) {
    let filename;
    if (data.type === 'application/pdf') {
        filename = 'invoice_' + identifier + '.pdf';
    } else {
        filename = 'invoices.zip';
    }
    downloadBlob(data, filename);
    return {
        type: OPEN_INVOICE_SUCCESS,
        payload: data,
    };
}

export function openInvoicePublisherFailure(error) {
    return {
        type: OPEN_INVOICE_FAILURE,
        payload: error,
    };
}

export function deleteInvoice(paymentRequestId) {
    const bodyObj = {
        id: paymentRequestId,
    };
    const promise = fetch(getAPISubUrlForPublisher() + '/paymentRequests/invoices.delete', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: DELETE_INVOICE,
        payload: promise,
    };
}

export function deleteInvoiceSuccess(deletedId, allPayments) {
    const updatedPayments = [];

    _.each(allPayments, function (paymentData) {
        if (paymentData.paymentRequestId === deletedId) {
            paymentData.invoice = null;
        }
        updatedPayments.push(paymentData);
    });

    return {
        type: DELETE_INVOICE_SUCCESS,
        payload: updatedPayments,
    };
}

export function deleteInvoiceFailure(error) {
    return {
        type: DELETE_INVOICE_FAILURE,
        payload: error,
    };
}

export function addInvoice(paymentRequestId, file) {
    const form = new FormData();
    form.append('file', file);
    form.append('size', file.size);
    form.append('id', paymentRequestId);

    const promise = fetch(getAPISubUrlForPublisher() + '/paymentRequests/invoices.add', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
        },
        body: form,
    });

    return {
        type: ADD_INVOICE,
        payload: promise,
    };
}

export function addInvoiceSuccess(paymentRequestId, invoiceId, allPayments) {
    const updatedPayments = [];

    _.each(allPayments, function (paymentData) {
        if (paymentData.paymentRequestId === paymentRequestId) {
            paymentData.invoice = invoiceId;
        }
        updatedPayments.push(paymentData);
    });

    return {
        type: ADD_INVOICE_SUCCESS,
        payload: updatedPayments,
    };
}

export function addInvoiceFailure(error) {
    return {
        type: ADD_INVOICE_FAILURE,
        payload: error,
    };
}

export function openDeleteCustomInvoiceDialog(paymentRequestId) {
    return {
        type: OPEN_DELETE_CUSTOM_INVOICE_DIALOG,
        paymentRequestId: paymentRequestId,
    };
}

export function closeAllDialogs() {
    return {
        type: CLOSE_ALL_DIALOGS,
    };
}
