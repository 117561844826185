import backgroundImg from './bgBlurred.jpg';

export function BackgroundNotAuth({ style, ...props }: React.ComponentPropsWithoutRef<'div'>) {
    return (
        <div
            style={{
                height: '100%',
                backgroundSize: 'cover',
                backgroundImage: `url(${backgroundImg})`,
                backgroundAttachment: 'fixed',
                ...style,
            }}
            {...props}
        />
    );
}
