import fetchProduct from '@api/admin/products/fetchProducts';
import updateIsFeatured from '@api/admin/products/updateProductIsFeatured';
import updateStatus from '@api/admin/products/updateProductValidityStatus';

import { addFlash } from './common';

//#region table action
export const PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_REQUEST =
    'page_products_am/table/FETCH_PRODUCT:request';
export const PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_SUCCESS =
    'page_products_am/table/FETCH_PRODUCT:success';
export const PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_FAILURE =
    'page_products_am/table/FETCH_PRODUCT:failure';

export const PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_REQUEST =
    'page_products_am/table/UPDATE_PRODUCT_ADMIN_STATUS:request';
export const PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_SUCCESS =
    'page_products_am/table/UPDATE_PRODUCT_ADMIN_STATUS:success';
export const PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_FAILURE =
    'page_products_am/table/UPDATE_PRODUCT_ADMIN_STATUS:failure';

export const PAGE_PRODUCT_AM_TABLE_SET_FILTER = 'page_products_am/table/SET_FILTER';

export const PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_REQUEST =
    'page_products_am/table/UPDATE_PRODUCT_IS_FEATURED:request';
export const PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_SUCCESS =
    'page_products_am/table/UPDATE_PRODUCT_IS_FEATURED:success';
export const PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_FAILURE =
    'page_products_am/table/UPDATE_PRODUCT_IS_FEATURED:failure';
//#endregion

export const PAGE_PRODUCT_AM_PROGRAM_PANEL_OPEN = 'page_products_am/program_panel/OPEN';
export const PAGE_PRODUCT_AM_PROGRAM_PANEL_CLOSE = 'page_products_am/program_panel/CLOSE';

export const PAGE_PRODUCT_AM_PRODUCT_PANEL_OPEN = 'page_products_am/product_panel/OPEN';
export const PAGE_PRODUCT_AM_PRODUCT_PANEL_CLOSE = 'page_products_am/product_panel/CLOSE';

export const PAGE_PRODUCT_AM_PRODUCT_FEEDBACK_DIALOG_OPEN =
    'page_products_am/product_feedback_dialog/OPEN';
export const PAGE_PRODUCT_AM_PRODUCT_FEEDBACK_DIALOG_CLOSE =
    'page_products_am/product_feedback_dialog/CLOSE';

export function getProductsRequest(filterData) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_REQUEST,
        filter: filterData,
    };
}

export function getProductsSuccess(products) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_SUCCESS,
        products,
    };
}

export function getProductsFailed(error) {
    return { type: PAGE_PRODUCT_AM_TABLE_FETCH_PRODUCT_FAILURE };
}

// a redux thunk (not a classic action) (https://github.com/reduxjs/redux-thunk)
export function getProducts(filterData) {
    return (dispatch) => {
        dispatch(getProductsRequest(filterData));
        return fetchProduct(filterData)
            .then((products) => dispatch(getProductsSuccess(products)))
            .catch((error) => {
                dispatch(getProductsFailed(error));
                dispatch(addFlash('error', 'Fail to load products')); // todo trad // ns should we let front handle error display?
            });
    };
}

export function updateProductStatusRequest(id, status) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_REQUEST,
        productId: id,
        status: status,
    };
}

export function updateProductStatusSuccess(id, updatedProduct) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_SUCCESS,
        productId: id,
        updatedProduct,
    };
}

export function updateProductStatusFailed(id) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_ADMIN_STATUS_FAILURE,
        productId: id,
    };
}

export function updateProductStatus(id, status, message) {
    return (dispatch) => {
        dispatch(updateProductStatusRequest(id, status));
        return updateStatus(id, status, message)
            .then((product) => {
                dispatch(updateProductStatusSuccess(id, product));
            })
            .catch((error) => {
                dispatch(updateProductStatusFailed(id));
                dispatch(addFlash('error', 'Fail to update product')); // todo trad // ns should we let front handle error display?
            });
    };
}

export function setFilter(filterData) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_SET_FILTER,
        filter: filterData,
    };
}

export function programPanelOpen(programId) {
    return {
        type: PAGE_PRODUCT_AM_PROGRAM_PANEL_OPEN,
        programId,
    };
}

export function programPanelClose() {
    return {
        type: PAGE_PRODUCT_AM_PROGRAM_PANEL_CLOSE,
    };
}

export function productPanelOpen(productId) {
    return {
        type: PAGE_PRODUCT_AM_PRODUCT_PANEL_OPEN,
        productId,
    };
}

export function productPanelClose() {
    return {
        type: PAGE_PRODUCT_AM_PRODUCT_PANEL_CLOSE,
    };
}

export function productFeedbackOpen(productId) {
    return {
        type: PAGE_PRODUCT_AM_PRODUCT_FEEDBACK_DIALOG_OPEN,
        productId,
    };
}

export function productFeedbackClose() {
    return {
        type: PAGE_PRODUCT_AM_PRODUCT_FEEDBACK_DIALOG_CLOSE,
    };
}

export function updateProductIsFeaturedRequest(id, isFeatured) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_REQUEST,
        productId: id,
        isFeatured: isFeatured,
    };
}

export function updateProductIsFeaturedSuccess(id, updatedProduct) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_SUCCESS,
        productId: id,
        updatedProduct,
    };
}

export function updateProductIsFeaturedFailed(id) {
    return {
        type: PAGE_PRODUCT_AM_TABLE_UPDATE_PRODUCT_IS_FEATURED_FAILURE,
        productId: id,
    };
}

export function updateProductIsFeatured(id, isFeatured) {
    return (dispatch) => {
        dispatch(updateProductIsFeaturedRequest(id, isFeatured));
        return updateIsFeatured(id, isFeatured) // todo...
            .then((product) => {
                dispatch(updateProductIsFeaturedSuccess(id, product));
            })
            .catch((error) => {
                dispatch(updateProductIsFeaturedFailed(id));
                dispatch(addFlash('error', 'Fail to update product')); // todo trad // ns should we let front handle error display?
            });
    };
}
