import _ from 'lodash';

import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

import { Advertiser } from '../fetchCurrentAdvertiserData';

export interface UpdateBillingDetailsRequestPayload {
    companyName: string;
    type: string;
    country: string;
    companyId: string;
    companyVat: string;
    isVatFree: boolean;
    companyPhone: string;
    email: string;
    billingUsers: MongoId[];
    address: string;
    zipCode: string;
    city: string;
    billingInfoType: string;
    billingInfoCompanyName: string;
    billingInfoCompanyPhone: string;
    billingInfoEmail: string;
    billingInfoBillingUsers: MongoId[];
    billingInfoAddress: string;
    billingInfoZipcode: string;
    billingInfoCity: string;
    billingInfoCountry: string;
    billingInfoIsVatFree: boolean;
    billingInfoCompanyId: string;
    billingInfoCompanyVat: string;
}

export async function updateBillingDetails(
    payload: UpdateBillingDetailsRequestPayload,
): Promise<Advertiser> {
    const url = getAPISubUrlForAdvertiser() + '/details.update';
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
    await checkStatus(response);
    const body = (await response.json()) as {
        status: number;
        advertiser: {
            data: Advertiser;
        };
    };

    return body.advertiser.data;
}
