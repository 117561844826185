import { createAsyncThunk } from '@reduxjs/toolkit';

import { AddFlash } from '@components/common/Snackbars';

import { getJwt } from '@libs/jwt';

import apiListPlans from '@api/advertiser/listPlans';
import { getAPIBaseUrl, getAPISubUrlForAdmin } from '@api/common/utils';

export const BUILD_URL = 'BUILD_URL';
export const BUILD_URL_SUCCESS = 'BUILD_URL_SUCCESS';
export const BUILD_URL_FAILURE = 'BUILD_URL_FAILURE';

export const FETCH_AFFILAE_ADMINS = 'FETCH_AFFILAE_ADMINS';
export const FETCH_AFFILAE_ADMINS_SUCCESS = 'FETCH_AFFILAE_ADMINS_SUCCESS';
export const FETCH_AFFILAE_ADMINS_FAILURE = 'FETCH_AFFILAE_ADMINS_FAILURE';

export const FETCH_PRODUCT_CATEGORIES = 'FETCH_PRODUCT_CATEGORIES';
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = 'FETCH_PRODUCT_CATEGORIES_SUCCESS';
export const FETCH_PRODUCT_CATEGORIES_FAILURE = 'FETCH_PRODUCT_CATEGORIES_FAILURE';

export const ADD_FLASH = 'ADD_FLASH';

export const UPDATE_LINK_BUILDER_FIELDS = 'UPDATE_LINK_BUILDER_FIELDS';

export function fetchProductCategories() {
    const promise = fetch(getAPIBaseUrl() + 'common/productCategories.list');

    return {
        type: FETCH_PRODUCT_CATEGORIES,
        payload: promise,
    };
}

export function fetchProductCategoriesSuccess(productCategoriesList) {
    return {
        type: FETCH_PRODUCT_CATEGORIES_SUCCESS,
        payload: productCategoriesList,
    };
}

export function fetchProductCategoriesFailure(error) {
    return {
        type: FETCH_PRODUCT_CATEGORIES_FAILURE,
        payload: error,
    };
}

export function fetchAffilaeAdminsThunk() {
    return (dispatch) => {
        dispatch(fetchAffilaeAdmins())
            .then(function (response) {
                return response.payload.json();
            })
            .then(function (result) {
                if (!result.users || result.statusCode !== 200) {
                    dispatch(fetchAffilaeAdminsFailure(result.statusCode));
                } else {
                    dispatch(fetchAffilaeAdminsSuccess(result.users));
                }
            });
    };
}

function fetchAffilaeAdmins() {
    const promise = fetch(getAPISubUrlForAdmin() + '/admins.list', {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + getJwt() },
    });

    return {
        type: FETCH_AFFILAE_ADMINS,
        payload: promise,
    };
}

function fetchAffilaeAdminsSuccess(adminsList) {
    return {
        type: FETCH_AFFILAE_ADMINS_SUCCESS,
        payload: adminsList,
    };
}

function fetchAffilaeAdminsFailure(error) {
    return {
        type: FETCH_AFFILAE_ADMINS_FAILURE,
        payload: error,
    };
}

export function updateLinkBuilderFields(fields) {
    return {
        type: UPDATE_LINK_BUILDER_FIELDS,
        payload: fields,
    };
}

export function buildUrl(url, partnership, product, subId, forceGlobalUTM = true) {
    const promise = fetch(getAPIBaseUrl() + 'common/tracking/url.build', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            urls: [
                {
                    partnershipId: partnership,
                    landingPage: url,
                    productId: product,
                    subId,
                    injectProgramQueryString: forceGlobalUTM,
                },
            ],
        }),
    });

    return {
        type: BUILD_URL,
        payload: promise,
    };
}

export function buildUrlSuccess(result) {
    // if country not detected, set default to France
    return {
        type: BUILD_URL_SUCCESS,
        payload: result,
    };
}

export function buildUrlFailure(error) {
    return {
        type: BUILD_URL_FAILURE,
        payload: error,
    };
}

export const listPlans = createAsyncThunk('common/listPlans', apiListPlans);

export function addFlash(kind: Parameters<AddFlash>[0], message: Parameters<AddFlash>[1]) {
    // Use common reducer to store flashs when root component (see App.tsx) is migrated to redux
    window.addFlash(kind, message);
    return {
        type: ADD_FLASH,
    };
}
