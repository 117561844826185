import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styleLightSelect } from '@pages/admin/AffiliateProfileAmPage/AffiliateProfileAmFilters';
import i18next from 'i18next';
import moment, { Moment } from 'moment-timezone';

import { StaticDateRangePicker, StaticDateRangePickerProps } from '@mui/lab';

import {
    Box,
    BoxProps,
    Button,
    ButtonProps,
    ClickAwayListener,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    TextField,
} from '@mui/material';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { lightTheme } from '@providers/theme';

import useBreakpoint from '@hooks/useBreakpoint';

import { getCurrentUser } from '@libs/getSharedVar';

import { ProgramInfo } from '@api/advertiser/fetchProgramInfo';
import { ProfileInfo } from '@api/publisher/interfacesPublisher';

export interface PeriodExplicit {
    from: Moment;
    to: Moment;
}

const t = i18next.t.bind(i18next);
// we voluntary avoid to use i18n hook here to allow to retrieve period options outside of a component where translation not matter.
// and at the same time this should not perturb the translation of options since most of the time the component wil re render when the language change.

type DateRangePickerProps<Options extends readonly PeriodOption[]> = {
    options: Options;
    value: PeriodExplicit | Options[number]['id'];
    onChange: (value: PeriodExplicit | Options[number]['id']) => void;
    id?: string;
    variant?: 'outlined' | 'standard' | 'light'; // light is a special variant for page am profiles
    styles?: Partial<{
        container: React.CSSProperties;
        select: React.CSSProperties;
        btnCalendar: React.CSSProperties;
    }>;
    slotProps?: Partial<{
        container: BoxProps;
        select: SelectProps;
        btnCalendar: ButtonProps;
        StaticDateRangePicker: Partial<StaticDateRangePickerProps<Moment>>;
    }>;
    showBtnCalendar?: boolean;
} & Omit<StaticDateRangePickerProps<Moment>, 'value' | 'onChange' | 'renderInput'>;

export const DateRangePicker = <Options extends readonly PeriodOption[]>({
    options,
    value,
    onChange,
    id = 'selectPeriod',
    variant = 'outlined',
    styles: stylesOverride,
    showBtnCalendar = false,
    slotProps,
}: DateRangePickerProps<Options>) => {
    const { i18n, t } = useTranslation();
    const isMobile = useBreakpoint({ breakpoint: 'md', direction: 'down' });
    const locale = i18n.language;
    const [showCalendar, setShowCalendar] = useState(false);
    const [periodRange, setPeriodRange] = useState<PeriodExplicit | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickAway = () => {
        setShowCalendar(false);
    };

    const handlePeriodChange = (event: SelectChangeEvent<Options[number]['id'] | 'custom'>) => {
        const period = event.target.value;
        if (period === 'custom') {
            setShowCalendar(true);
            setPeriodRange(null);
        } else {
            onChange(period);
        }
    };

    const handleSubmit = () => {
        if (periodRange) {
            onChange(periodRange);
        }
        setShowCalendar(false);
    };

    const explicitPeriod = useMemo(
        () => periodRange || getExplicitPeriod(options, value),
        [value, options, periodRange],
    );

    const staticDateRangePicker = (
        <Box
            sx={{
                ...styles.dateRangePicker,
                ...(isMobile && styles.dateRangePickerMobile),
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
            }}
        >
            <StaticDateRangePicker
                value={explicitPeriod ? [explicitPeriod.from, explicitPeriod.to] : [null, null]}
                onChange={(date) => {
                    if (date[0] == null || date[1] == null) return;
                    setPeriodRange({
                        from: date[0].startOf('day'),
                        to: date[1].endOf('day'),
                    });
                }}
                renderInput={(startProps, endProps) => (
                    <>
                        <TextField {...startProps} style={{ width: '8rem' }} />
                        <Box sx={{ mx: 1 }}> to </Box>
                        <TextField {...endProps} style={{ width: '8rem' }} />
                    </>
                )}
                showToolbar
                {...slotProps?.StaticDateRangePicker}
            />
            <Button
                style={{
                    width: 'fit-content',
                    alignSelf: 'flex-end',
                    marginBottom: '1rem',
                    marginRight: '1rem',
                }}
                color='primary'
                variant='contained'
                onClick={handleSubmit}
            >
                {t('submit')}
            </Button>
        </Box>
    );

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box
                sx={[
                    styles.container,
                    isMobile && styles.containerMobile,
                    stylesOverride?.container ?? false,
                ]}
                {...slotProps?.container}
            >
                <Select
                    id={id}
                    name='referencePeriodSelector'
                    variant={variant === 'light' ? 'outlined' : variant}
                    value={typeof value === 'string' ? value : 'custom'}
                    onChange={handlePeriodChange}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    sx={[
                        variant === 'light'
                            ? styleLightSelect
                            : {
                                  width: '15rem',
                                  height: '2.5rem',
                                  bgcolor: variant === 'outlined' ? 'white' : 'transparent',
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                      borderColor: '#c9c9c9',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: lightTheme.palette.secondary.main,
                                  },
                              },
                        stylesOverride?.select ?? false,
                    ]}
                    {...slotProps?.select}
                >
                    {options.map((option) => {
                        return (
                            <MenuItem key={option.id} value={option.id}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                    {(showBtnCalendar === false || typeof value !== 'string') && (
                        <MenuItem onClick={() => setShowCalendar(true)} value='custom'>
                            {isOpen || typeof value === 'string'
                                ? t('am_page_filter_subscription_custom')
                                : getPeriodDisplayLabel(explicitPeriod!, locale)}
                        </MenuItem>
                    )}
                </Select>

                {showBtnCalendar && explicitPeriod != null && (
                    <div style={{ position: 'relative', borderRadius: '1rem' }}>
                        <Button
                            id={`${id}-button`}
                            onClick={() => {
                                setShowCalendar(!showCalendar);
                            }}
                            variant='contained'
                            color='secondary'
                            sx={[
                                styles.buttonCalendar,
                                isMobile && styles.buttonCalendarMobile,
                                stylesOverride?.btnCalendar ?? false,
                            ]}
                            startIcon={<CalendarTodayIcon />}
                            {...slotProps?.btnCalendar}
                        >
                            {getPeriodDisplayLabel(explicitPeriod, locale)}
                        </Button>
                        {showCalendar && staticDateRangePicker}
                    </div>
                )}

                {!showBtnCalendar && showCalendar && staticDateRangePicker}
            </Box>
        </ClickAwayListener>
    );
};

export const DateRangePickerRef = (
    props: Omit<DateRangePickerProps<ReturnType<typeof getReferencePeriodOptions>>, 'options'>,
) => {
    const options = getReferencePeriodOptions();
    return <DateRangePicker options={options} {...props} />;
};

export const DateRangePickerCmp = ({
    refPeriod,
    slotProps,
    ...props
}: Omit<DateRangePickerProps<ReturnType<typeof getAllComparisonPeriodOptions>>, 'options'> & {
    refPeriod: PeriodRef;
}) => {
    const options = getComparisonPeriodOptions(refPeriod);
    return (
        <DateRangePicker
            options={options}
            slotProps={{
                ...slotProps,
                btnCalendar: {
                    ...slotProps?.btnCalendar,
                    color: 'info',
                },
            }}
            {...props}
        />
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        gap: '0.5rem',
    },
    containerMobile: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
    },
    buttonCalendar: {
        marginLeft: '1rem',
        borderRadius: '4px',
        padding: '7px 21px',
        whiteSpace: 'nowrap',
    },
    buttonCalendarMobile: {
        width: '10rem',
        marginLeft: '0rem',
    },
    dateRangePicker: {
        display: 'flex',
        position: 'absolute',
        top: '3.5rem',
        left: '0',
        marginLeft: '0rem',
        zIndex: 100,
    },
    dateRangePickerMobile: {
        top: '2.5rem',
    },
} as const satisfies Record<string, React.CSSProperties>;

function getExplicitPeriod<Options extends PeriodOption[]>(
    options: Options,
    period: PeriodExplicit | Options[number]['id'],
) {
    if (typeof period !== 'string') {
        return period;
    } else {
        const option = options.find((o) => o.id === period);
        if (option == null) {
            throw new Error('Invalid period id');
        }
        return option.getValue();
    }
}

export function getExplicitPeriodFromPeriodRef(period: PeriodRef, tz?: string) {
    const options = getReferencePeriodOptions(tz);
    return getExplicitPeriod(options, period);
}

export function getExplicitPeriodFromPeriodComp(
    referencePeriod: PeriodRef,
    comparisonPeriod: PeriodCmp,
) {
    const options = getAllComparisonPeriodOptions(referencePeriod);
    return getExplicitPeriod(options, comparisonPeriod);
}

export function getPeriodDisplayLabel(
    period: PeriodExplicit,
    locale: string,
    withWrittenLabels?: boolean,
): string {
    const { from, to } = period;
    const now = moment();

    moment.locale(locale);

    const today = locale === 'en' ? 'Today' : locale === 'fr' ? "Aujourd'hui" : 'Hoy';
    const yesterday = locale === 'en' ? 'Yesterday' : locale === 'fr' ? 'Hier' : 'Ayer';

    const formatFullDate = (date: Moment) => {
        return locale === 'en' ? date.format('MMM D YYYY') : date.format('D MMM YYYY');
    };
    const formatDateWithoutYear = (date: Moment) => {
        return locale === 'en' ? date.format('MMM D') : date.format('D MMM');
    };
    const formatDayOnly = (date: Moment) => date.format('D');

    const isCurrentYear = (date: Moment) => date.year() === now.year();

    if (from.isSame(to, 'day')) {
        if (from.isSame(now, 'day')) return withWrittenLabels ? today : formatDateWithoutYear(from);
        if (from.isSame(now.clone().subtract(1, 'day'), 'day'))
            return withWrittenLabels ? yesterday : formatDateWithoutYear(from);
        return isCurrentYear(from) ? formatDateWithoutYear(from) : formatFullDate(from);
    }

    if (from.isSame(to, 'month') && from.isSame(to, 'year')) {
        const monthYear = isCurrentYear(from)
            ? from.format('MMMM')
            : locale === 'en'
              ? from.format('MMM YYYY')
              : from.format('MMM YYYY');
        return `${formatDayOnly(from)}-${formatDayOnly(to)} ${monthYear}`;
    }

    if (from.isSame(to, 'year')) {
        const year = isCurrentYear(from) ? '' : ` ${from.format('YYYY')}`;
        return `${formatDateWithoutYear(from)} - ${formatDateWithoutYear(to)}${year}`;
    }

    return `${formatFullDate(from)} - ${formatFullDate(to)}`;
}

export type PeriodOption = {
    id: string;
    label: string;
    getValue: () => PeriodExplicit | null;
};

export function usePeriod<Options extends PeriodOption[]>(
    options: Options,
    initialValue: PeriodExplicit | Options[number]['id'],
) {
    const [period, setPeriod] = useState<PeriodExplicit | Options[number]['id']>(initialValue);
    const explicitPeriod = getExplicitPeriod(options, period);

    return {
        period: period,
        explicitPeriod,
        setPeriod,
    };
}

export type PeriodRef = PeriodRefId | PeriodExplicit;

export function useRefPeriod(initialValue: PeriodRef) {
    const options = getReferencePeriodOptions();
    return usePeriod(options, initialValue);
}

export type PeriodCmp = PeriodCmpId | PeriodExplicit;

export function useCpmPeriod(refPeriod: PeriodRef, initialValue: PeriodCmp) {
    const options = getAllComparisonPeriodOptions(refPeriod);
    return usePeriod(options, initialValue);
}

export function getSubEntityPeriod(subEntity: ProgramInfo | ProfileInfo): PeriodExplicit {
    return {
        from: moment.unix(subEntity.createdAt),
        to: moment().endOf('day'), // use 'endOf day' so query criteria match payment request that could be created later today
    };
}

export function formatMomentForApi(m: Moment) {
    return moment(m).utc().toISOString();
}

export function formatPeriodForExport({ from, to }: PeriodExplicit): string {
    const dateFormat = 'YYYY-MM-DD';
    return `${from.format(dateFormat)}-${to.format(dateFormat)}`;
}

export const periodScales = ['year', 'month', 'day', 'hour'] as const;

export type PeriodScale = (typeof periodScales)[number];

export function getScale({ from, to }: PeriodExplicit): PeriodScale {
    const duration = moment.duration(moment(to).diff(from));
    if (duration.asDays() <= 2) {
        return 'hour';
    } else if (duration.asDays() <= 75) {
        return 'day';
    } else if (duration.asMonths() <= 24) {
        return 'month';
    } else {
        return 'year';
    }
}

type PeriodRefId = ReturnType<typeof getReferencePeriodOptions>[number]['id'];

function getDefaultTimezone() {
    try {
        return getCurrentUser()?.timezone;
    } catch {
        return moment.tz.guess();
    }
}

export function getReferencePeriodOptions(tz = getDefaultTimezone()) {
    const now = moment().tz(tz);

    const referencePeriodOptions = [
        {
            id: 'today',
            label: t('program_dashboard_advertiser_filters_select_reference_period_today'),
            getValue() {
                return {
                    from: moment(now).startOf('day'),
                    to: moment(now).endOf('day'),
                };
            },
        },
        {
            id: 'yesterday',
            label: t('program_dashboard_advertiser_filters_select_reference_period_yesterday'),
            getValue() {
                return {
                    from: moment(now).subtract(1, 'days').startOf('day'),
                    to: moment(now).subtract(1, 'days').endOf('day'),
                };
            },
        },
        {
            id: 'week_to_date',
            label: t('program_dashboard_advertiser_filters_select_reference_period_weekToDate'),
            getValue() {
                return {
                    from: moment(now).startOf('isoWeek'),
                    to: moment(now).endOf('day'),
                };
            },
        },
        {
            id: 'last_week',
            label: t('program_dashboard_advertiser_filters_select_reference_period_lastWeek'),
            getValue() {
                return {
                    from: moment(now).subtract(1, 'week').startOf('isoWeek'),
                    to: moment(now).subtract(1, 'week').endOf('isoWeek'),
                };
            },
        },
        {
            id: 'month_to_date',
            label: t('program_dashboard_advertiser_filters_select_reference_period_monthToDate'),
            getValue() {
                return {
                    from: moment(now).startOf('month'),
                    to: moment(now).endOf('day'),
                };
            },
        },
        {
            id: 'last_month',
            label: t('program_dashboard_advertiser_filters_select_reference_period_lastMonth'),
            getValue() {
                return {
                    from: moment(now).subtract(1, 'month').startOf('month'),
                    to: moment(now).subtract(1, 'month').endOf('month'),
                };
            },
        },
        {
            id: 'last_30_days',
            label: t('program_dashboard_advertiser_filters_select_reference_period_last30Days'),
            getValue() {
                return {
                    from: moment(now).subtract(29, 'days').startOf('day'),
                    to: moment(now).endOf('day'),
                };
            },
        },
        {
            id: 'last_90_days',
            label: t('program_dashboard_advertiser_filters_select_reference_period_last90Days'),
            getValue() {
                return {
                    from: moment(now).subtract(89, 'days').startOf('day'),
                    to: moment(now).endOf('day'),
                };
            },
        },
        {
            id: 'last_12_months',
            label: t('program_dashboard_advertiser_filters_select_reference_period_last12Months'),
            getValue() {
                return {
                    from: moment(now).subtract(11, 'months').startOf('month'),
                    to: moment(now).endOf('month'),
                };
            },
        },
        {
            id: 'year_to_date',
            label: t('program_dashboard_advertiser_filters_select_reference_period_yearToDate'),
            getValue() {
                return {
                    from: moment(now).startOf('year'),
                    to: moment(now).endOf('day'),
                };
            },
        },
    ] as const satisfies PeriodOption[];
    return referencePeriodOptions;
}

type PeriodCmpId = ReturnType<typeof getAllComparisonPeriodOptions>[number]['id'];

export function getAllComparisonPeriodOptions(
    refPeriod: PeriodExplicit | PeriodRefId,
    tz = getDefaultTimezone(),
) {
    const autoPreviousPeriod = getAutoPreviousPeriod(refPeriod, tz);
    const refPeriodOptions = getReferencePeriodOptions();
    const refPeriodExplicit =
        typeof refPeriod === 'string'
            ? refPeriodOptions.find((o) => o.id === refPeriod)!.getValue()
            : refPeriod;
    const comparisonPeriodOptions = [
        {
            id: 'autoPrevious',
            label: t('program_dashboard_advertiser_filters_select_comparison_period_autoPrevious'),
            getValue() {
                return autoPreviousPeriod;
            },
        },
        {
            id: 'previousMonth',
            label: t('program_dashboard_advertiser_filters_select_comparison_period_previousMonth'),
            getValue() {
                return {
                    from: moment(refPeriodExplicit.from).subtract(1, 'month').startOf('month'),
                    to: moment(refPeriodExplicit.from).subtract(1, 'month').endOf('month'),
                };
            },
        },
        {
            id: 'previousYear',
            label: t('program_dashboard_advertiser_filters_select_comparison_period_previousYear'),
            getValue() {
                return {
                    from: moment(refPeriodExplicit.from).subtract(1, 'year'),
                    to: moment(refPeriodExplicit.to).subtract(1, 'year'),
                };
            },
        },
        {
            id: 'none',
            label: `${t('program_dashboard_advertiser_filters_select_comparison_period_none')}`,
            getValue() {
                return null;
            },
        },
    ] as const satisfies PeriodOption[];
    return comparisonPeriodOptions;
}

type PeriodOptionCmp = ReturnType<typeof getAllComparisonPeriodOptions>[number];

export function getComparisonPeriodOptions(
    refPeriod: PeriodExplicit | PeriodRefId,
    tz = getDefaultTimezone(),
): PeriodOptionCmp[] {
    const allOptions = getAllComparisonPeriodOptions(refPeriod, tz);
    const options = allOptions.filter((o) => ['previousMonth'].includes(o.id) === false);

    if (refPeriod === 'month_to_date') {
        options.splice(1, 0, allOptions.find((o) => o.id === 'previousMonth')!);
    }
    return options;
}

function getAutoPreviousPeriod(
    refPeriod: PeriodExplicit | PeriodRefId,
    tz = getDefaultTimezone(),
): PeriodExplicit {
    const now = moment().tz(tz);
    if (refPeriod === 'month_to_date') {
        return {
            from: moment(now).subtract(1, 'month').startOf('month'),
            to: moment(now).subtract(1, 'month').endOf('day'),
        };
    }
    if (refPeriod === 'year_to_date') {
        return {
            from: moment(now).subtract(1, 'year').startOf('year'),
            to: moment(now).subtract(1, 'year').endOf('day'),
        };
    }
    if (refPeriod === 'last_30_days') {
        return {
            from: moment(now).subtract(59, 'days').startOf('day'),
            to: moment(now).subtract(30, 'days').endOf('day'),
        };
    }

    const referencePeriodExplicit = getExplicitPeriodFromPeriodRef(refPeriod, tz);

    const refPeriodDuration = moment(referencePeriodExplicit?.to).diff(
        moment(referencePeriodExplicit?.from),
    );
    if (typeof refPeriod === 'object') {
        return {
            from: moment(referencePeriodExplicit?.from).subtract(refPeriodDuration).startOf('day'),
            to: moment(referencePeriodExplicit?.to)
                .subtract(refPeriodDuration)
                .subtract(1, 'day')
                .endOf('day'),
        };
    }
    return {
        from: moment(referencePeriodExplicit?.from).subtract(refPeriodDuration).startOf('day'),
        to: moment(referencePeriodExplicit?.to)
            .subtract(refPeriodDuration)
            .subtract(1, 'day')
            .endOf('day'),
    };
}

export function getPeriodFromUrl(): PeriodRef {
    const params = new URLSearchParams(window.location.search);
    return params.get('period')
        ? (params.get('period') as PeriodRef)
        : params.get('from') && params.get('to')
          ? {
                from: moment(params.get('from'))!,
                to: moment(params.get('to')!),
            }
          : 'year_to_date';
}
