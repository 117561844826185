import { CSSProperties } from 'react';

const eventsPagesStyle = {
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    illustration: {
        maxHeight: '60vh',
        maxWidth: '100%',
        marginTop: '2rem',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '3rem',
        marginTop: '2rem',
    },
    subtitle: {
        marginBottom: '2rem',
        marginTop: '0.5rem',
        fontSize: '1.5rem',
    },
    text: {
        textAlign: 'center',
        color: '#837E7E',
        marginBottom: '2rem',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonLink: {
        borderRadius: '30px',
        textTransform: 'none',
        fontWeight: '600',
        fontSize: '1.1rem',
        padding: '10px 30px',
    },
    button: {
        borderRadius: '30px',
        textTransform: 'none',
    },
} as const satisfies Record<string, CSSProperties>;

export default eventsPagesStyle;
