import parsePhoneNumberFromString from 'libphonenumber-js';
import moment from 'moment';
import { z } from 'zod';

import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '@api/common/utils';

import { User } from './types';

export const UserUpdateSchema = z.object({
    firstname: z.string().min(2),
    lastname: z.string().min(2),
    email: z.string().email(),
    phone: z.string().refine((value) => {
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber ? phoneNumber.isValid() : false;
    }, 'Invalid phone number'),
    timezone: z.string().refine((value) => {
        return !!moment.tz.zone(value);
    }, 'Invalid timezone'),
    lang: z.enum(['fr', 'en', 'es']),
    newsletter: z.boolean(),
});

export type UserUpdateType = z.infer<typeof UserUpdateSchema>;

export type UserUpdateReqPayload = UserUpdateType & { id: string };

export default async function userUpdate(req: UserUpdateReqPayload) {
    const url = getAPIBaseUrl() + 'common/users.update';
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body as User;
}
