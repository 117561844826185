import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';

import LoadingButton from '@mui/lab/LoadingButton';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { useTextField } from '@hooks/useField';
import { usePromise } from '@hooks/usePromise';

import { ComplexValidator } from '@libs/validators';

import { isErrorPhoneAlreadyTaken, updateMethod } from '@api/user/mfa/updateMethod';
import { User } from '@api/user/types';

import { MfaDialogAction, MfaDialogTitle } from '../common';

export type MfaRegisterMethodViewProps = {
    defaultPhone?: string;
    cancelBtnLabel?: string;
    onSuccess: (user: User) => void;
    onCancel: () => void;
    /** Custom register method, mostly for testing purpose */
    updateMethod?: typeof updateMethod;
};

export function MfaRegisterMethodView({
    defaultPhone,
    onSuccess,
    onCancel,
    /** the i18n key */
    cancelBtnLabel = 'generic_cancel',
    /** ⚠️ must be stable between render */
    updateMethod: _updateMethod = updateMethod, // add '_' to avoid name conflict with its wrapper from usePromise that add state management feature
}: MfaRegisterMethodViewProps) {
    const { t } = useTranslation();
    const [hasTryToSubmit, setHasTryToSubmit] = useState(false);
    const [phonesTaken, setPhonesTaken] = useState<string[]>([]);
    const phoneValidator: ComplexValidator<string> = useCallback(
        (phone: string) => {
            if (phonesTaken.includes(phone)) {
                return {
                    isValid: false,
                    helperText: 'mfaForm_registerView_phone_alreadyTaken',
                };
            }
            return {
                isValid: matchIsValidTel(phone),
                helperText: 'common_signup_phone_notValid',
            };
        },
        [phonesTaken],
    );
    const phoneField = useTextField(defaultPhone ?? '', {
        validator: phoneValidator,
        hasSubmit: hasTryToSubmit,
        getShowValidity: 'afterSubmit',
    });

    const [updateMethodLoading, updateMethod] = usePromise(_updateMethod);

    async function handleSubmit() {
        setHasTryToSubmit(true);
        if (!phoneField.isValid) {
            return;
        }

        const loading = await updateMethod(phoneField.value);
        if (loading?.status === 'success') {
            onSuccess(loading.data);
        } else if (loading?.status === 'failure') {
            const error = loading.error;
            if (isErrorPhoneAlreadyTaken(error)) {
                setPhonesTaken((prev) => [...prev, phoneField.value]);
            } else {
                console.error(loading.error);
                // todo report to sentry
            }
        }
    }

    const isUnexpectedError =
        updateMethodLoading.status === 'failure' &&
        isErrorPhoneAlreadyTaken(updateMethodLoading.error) === false;

    return (
        <>
            <MfaDialogTitle />
            <DialogContent>
                {isUnexpectedError && (
                    <>
                        <Alert severity='error'>
                            {updateMethodLoading.error?.message ?? t('generic_error')}
                        </Alert>
                        <br />
                    </>
                )}

                <DialogContentText>
                    <Trans i18nKey='mfaForm_registerView_description'>
                        Affilae attaches great importance to the security of its customers&#39; user
                        accounts, which is why two-factor authentication by SMS is becoming
                        mandatory.
                        <br />
                        <br />
                        To continue please enter your mobile phone number:
                    </Trans>
                </DialogContentText>
                <br />
                <MuiTelInput
                    id='phone'
                    value={phoneField.value}
                    onChange={(_, { numberValue: value }) => phoneField.setValue(value ?? '')}
                    helperText={
                        phoneField.shouldDisplayValidity && phoneField.helperText
                            ? (t(phoneField.helperText as string) as string)
                            : undefined
                    }
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                    autoFocus
                    defaultCountry='FR'
                />
            </DialogContent>
            <MfaDialogAction style={{ paddingRight: '1.5rem', paddingBottom: '1rem' }}>
                <Button data-testid='btnCancel' onClick={() => onCancel()} color='inherit'>
                    {t(cancelBtnLabel)}
                </Button>
                <LoadingButton
                    data-testid='btnRegister'
                    onClick={handleSubmit}
                    variant='contained'
                    loading={updateMethodLoading.status === 'loading'}
                >
                    {t('mfaForm_registerView_submitBtn')}
                </LoadingButton>
            </MfaDialogAction>
        </>
    );
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export let _phoneMockSaved: string | undefined;
export const getPhoneMockSaved = () => _phoneMockSaved;

const registerMfaMock = async (phone: string) => {
    await wait(2000);
    _phoneMockSaved = phone;
};
