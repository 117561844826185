import { InvoiceType } from '@pages/admin/Invoices/interfaces';
import { InvoiceFilter, InvoiceService } from '@pages/advertiser/BillingPage/InvoicesTab';

import {
    formatMomentForApi,
    getExplicitPeriodFromPeriodRef,
    PeriodExplicit,
} from '@components/generic/DateRangePicker';

import { getJwt } from '@libs/jwt';
import { IsoDate, MongoId } from '@libs/types';

import { buildQueryString, checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

export type Invoice = {
    id: MongoId;
    advertiser: MongoId;

    type: InvoiceType; // todo
    service: InvoiceService;
    isCredit: boolean;

    paymentMethod: string;
    description: string;
    invoiceIdentifier: string;

    createdAt: IsoDate;
    from: IsoDate;
    to: IsoDate;

    currency: string;
    vat: number;
    amount: number;
    amountVAT: number;

    paidAt?: IsoDate;
    remarks?: string;
};

export type InvoiceFilterExplicit = InvoiceFilter & { period: PeriodExplicit };

export async function listInvoices(filter: InvoiceFilterExplicit): Promise<Invoice[]> {
    const { period, statusList, typesList, searchText, currency, services } = filter;
    const explicitPeriod = getExplicitPeriodFromPeriodRef(period);
    const isPaid = statusList.includes('paid')
        ? true
        : statusList.includes('pending')
          ? false
          : undefined;

    const response = await fetch(
        getAPISubUrlForAdvertiser() +
            '/invoices.list' +
            buildQueryString({
                isPaid: isPaid,
                from: formatMomentForApi(explicitPeriod.from),
                to: formatMomentForApi(explicitPeriod.to),
                types: typesList,
                services,
                identifierSearch: searchText,
                currency,
            }),
        {
            headers: {
                Authorization: getJwt(),
            },
        },
    );
    await checkStatus(response);
    const body = (await response.json()) as Invoice[];
    return body;
}
