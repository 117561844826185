import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '@api/common/utils';

export default async function userDelete(userId: string) {
    const url = getAPIBaseUrl() + 'common/users.delete';
    const payload = { id: userId };

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
    await checkStatus(response, 200);
}
