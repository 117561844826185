import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { LoadingButton } from '@mui/lab';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { Loyalty } from '@mui/icons-material';

import { DialogHeader } from '@components/generic/DialogHeader';

import { Advertiser } from '@api/advertiser/fetchCurrentAdvertiserData';
import { PaymentMethod } from '@api/advertiser/listPaymentMethods';

const stripe = Stripe(CONFIG.stripe.public_key);
const elements = stripe.elements();
let card = null;

function SubscribeDialog({
    isOpen,
    handleClose,
    updatePaymentMethod,
    advertiser,
    isLoading,
}: {
    isOpen: boolean;
    handleClose: () => void;
    updatePaymentMethod: (paymentMethod: PaymentMethod) => void;
    advertiser: Advertiser;
    isLoading: boolean;
}) {
    const [isCardComplete, setIsCardComplete] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (card === null) {
            card = elements.create('card', {
                hidePostalCode: true,
            });
        }
    }, []);

    useEffect(() => {
        if (!isOpen) return;

        setIsCardComplete(false);

        const handleCardChange = (data) => {
            setIsCardComplete(data.complete);
        };

        setTimeout(() => {
            card.mount('#card-element');
            card.on('change', handleCardChange);

            return () => {
                card.removeEventListener('change', handleCardChange);
            };
        }, 100);
    }, [isOpen]);

    const handleSubmit = () => {
        stripe.createPaymentMethod('card', card).then((result) => {
            if (result.error) {
                const errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                updatePaymentMethod(result.paymentMethod);
            }
        });
    };

    if (_.isEmpty(advertiser)) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
            fullWidth={true}
            className='updatePaymentMethodDialog'
            onClose={handleClose}
        >
            <DialogHeader title={t('billing_subscription_card_update')} icon={Loyalty} />
            <DialogContent className='updatePaymentMethodDialogContent'>
                <div className='paymentMethodWrapper'>
                    <form method='post' id='payment-form'>
                        <div className='form-row'>
                            <label htmlFor='card-element'>{/* Credit or debit card */}</label>
                            <div id='card-element'></div>
                            <div id='card-errors' role='alert'></div>
                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color='quiet' onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <LoadingButton
                    variant='contained'
                    disabled={isLoading || !isCardComplete}
                    onClick={handleSubmit}
                    loading={isLoading}
                >
                    {t('submit')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default SubscribeDialog;
