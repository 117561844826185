import { getLocale } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { PartnershipStatus } from '../../common/partnerships/list';
import { checkStatus, getAPISubUrlForAdvertiser } from '../utils';

type Dict<T> = Partial<Record<MongoId, T>>; // dictionary
export interface ApiData {
    partnerships: Dict<Partnership>;
    affiliateProfiles: Dict<AffiliateProfile>;
    users: Dict<User>;
    groups: Dict<Group>;
    categories: Dict<Category>;
}

export interface Partnership {
    id: MongoId;
    program: MongoId;
    affiliateProfile: MongoId;
    group: MongoId;
    status: PartnershipStatus;
    active: boolean;
}

export interface AffiliateProfile {
    id: MongoId;
    title: string;
    manager: MongoId;
    categories: string[];
}

export interface User {
    id: MongoId;
    firstname: string;
    lastname: string;
    email: string;
}

export function getUserFullName(user: User) {
    return `${user.firstname} ${user.lastname.toUpperCase()}`;
}

export interface Group {
    id: MongoId;
    title: string;
    program: MongoId;
}

export interface Category {
    id: MongoId;
    titleFr: string;
    titleEn: string;
    titleEs: string;
    slugFr: string;
    slugEn: string;
    slugEs: string;
}

export function getCategoryTrad(category: Category) {
    const locale = getLocale();
    const key = ('title' + locale.charAt(0).toUpperCase() + locale.slice(1)) as keyof Category;
    return category[key];
}

export async function listContacts(): Promise<ApiData> {
    const url = getAPISubUrlForAdvertiser() + '/contacts.list';
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response);
    return await response.json();
}
