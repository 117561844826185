import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { create } from 'zustand';

import { LoadingHandlerWithRetry } from '@components/generic/LoadingView';
import AlreadyLoggedPage from '@components/nav/redirectPages/AlreadyLoggedPage';

import { useAccessToken } from '@libs/jwt';
import { LoadingState, LoadingStatus } from '@libs/LoadingStatus';

import { wait } from '@api/common/wait';
import { getVerifyState } from '@api/user/mfa/getVerifyState';
import { sendCode } from '@api/user/mfa/sendCode';
import { VerifyState } from '@api/user/mfa/types';
import { verifyCode, VerifyCodeResponse } from '@api/user/mfa/verifyCode';

import { useLogout } from './SessionProvider';
import { useHasTakenOver } from './TakeOverProvider';

type MfaStatusState = {
    isSessionVerified: boolean | null;
    /** @private */
    _verifyState: VerifyState | null;
    /** @throws when _verifyState is not initialized */
    getVerifyState: () => VerifyState;

    fetchVerifyState: typeof getVerifyState;
    verifyStateLoadingStatus: LoadingStatus;
    verifyStateLoadingError: any | null;

    sendCode: typeof sendCode;
    sendCodeLoadingStatus: LoadingStatus;
    sendCodeLoadingError: any | null;

    verifyCode: typeof verifyCode;
    verifyCodeLoadingStatus: LoadingStatus;
    verifyCodeLoadingError: any | null;
    verifyCodeData: VerifyCodeResponse | null;

    canAskNewCode: boolean;
    needToCongratulateUser: boolean;
    finishToCongratulateUser: () => void;

    reset(): void; // on logout
};

export const useMfaState = create<MfaStatusState>((set, get) => {
    function handleVerifyStateChange(
        nextStateMutable: Partial<MfaStatusState>,
        verifyState: VerifyState,
    ) {
        nextStateMutable._verifyState = verifyState;
        if (verifyState.nextMethod === 'support') {
            set({ canAskNewCode: true });
        } else if (verifyState.nextTryAt !== null) {
            nextStateMutable.canAskNewCode = false;
            const diffDurationMs = moment
                .duration(verifyState.nextTryAt.diff(moment()))
                .asMilliseconds();
            setTimeout(() => {
                set({ canAskNewCode: true });
            }, diffDurationMs);
        }
    }

    return {
        isSessionVerified: null,
        _verifyState: null,
        getVerifyState() {
            const { _verifyState } = get();
            if (_verifyState == null) {
                throw new Error('Verify state is not initialized');
            }
            return _verifyState;
        },

        verifyStateLoadingStatus: 'idle',
        verifyStateLoadingError: null,
        async fetchVerifyState() {
            set({ verifyStateLoadingStatus: 'loading' });
            try {
                const verifyState = await getVerifyState();
                const nextState: Partial<MfaStatusState> = {
                    verifyStateLoadingStatus: 'success',
                };
                handleVerifyStateChange(nextState, verifyState);
                set({ ...nextState, isSessionVerified: verifyState.isVerified });
                return verifyState;
            } catch (error) {
                set({ verifyStateLoadingError: error, verifyStateLoadingStatus: 'failure' });
                throw error;
            }
        },

        sendCodeLoadingStatus: 'idle',
        sendCodeLoadingError: null,
        async sendCode() {
            set({ sendCodeLoadingStatus: 'loading' });
            try {
                const verifyState = await sendCode();
                const nextState: Partial<MfaStatusState> = {
                    sendCodeLoadingStatus: 'success',
                };
                handleVerifyStateChange(nextState, verifyState);
                set(nextState);
                return verifyState;
            } catch (error) {
                set({
                    sendCodeLoadingError: error,
                    sendCodeLoadingStatus: 'failure',
                });
                throw error;
            }
        },

        verifyCodeLoadingStatus: 'idle',
        verifyCodeLoadingError: null,
        verifyCodeData: null,
        // ensure that verifyCode last at least 700ms so that user notice that the code is being verified
        // otherwise it's too fast and user might think that the code is not being verified
        // so it is just a psychological trick for a better UX
        verifyCode: lastAtLeast(async (code) => {
            set({ verifyCodeLoadingStatus: 'loading' });
            try {
                const VerifyCodeResponse = await verifyCode(code);
                const nextState: Partial<MfaStatusState> = {
                    verifyCodeLoadingStatus: 'success',
                    verifyCodeData: VerifyCodeResponse,
                };
                const verifyState = await getVerifyState();
                handleVerifyStateChange(nextState, verifyState);
                set({
                    ...nextState,
                    isSessionVerified: verifyState.isVerified,
                    needToCongratulateUser: verifyState.isVerified,
                });
                return VerifyCodeResponse;
            } catch (error) {
                set({
                    verifyCodeLoadingError: error,
                    verifyCodeLoadingStatus: 'failure',
                });
                throw error;
            }
        }, 700),

        canAskNewCode: false,
        needToCongratulateUser: false,
        finishToCongratulateUser() {
            set({ needToCongratulateUser: false });
        },

        reset() {
            set({
                _verifyState: null,
                verifyStateLoadingStatus: 'idle',
                verifyStateLoadingError: null,
                canAskNewCode: false,
                sendCodeLoadingStatus: 'idle',
                sendCodeLoadingError: null,
                needToCongratulateUser: false,
            });
        },
    };
});

/** Load mfa state when needed and display its progression */
export function MfaLoader({ children }: { children: ReactNode }) {
    const { t } = useTranslation();
    const logout = useLogout();
    const verifyState = useMfaState((state) => state._verifyState);
    const fetchVerifyState = useMfaState((state) => state.fetchVerifyState);
    const verifyStateLoadingStatus = useMfaState((state) => state.verifyStateLoadingStatus);

    const isMfaDataRequired = useIsMfaDataRequired();

    useEffect(() => {
        if (isMfaDataRequired && verifyState == null) {
            fetchVerifyState();
        }
    }, [fetchVerifyState, isMfaDataRequired, verifyState]);

    if (!isMfaDataRequired) return <>{children}</>;

    return (
        <LoadingHandlerWithRetry
            loading={
                {
                    status: verifyStateLoadingStatus,
                    error:
                        verifyStateLoadingStatus === 'failure'
                            ? { message: 'Failed to load MFA state' }
                            : undefined,
                } as LoadingState<null>
            }
            onRetry={fetchVerifyState}
            cancelLabel={t('Logout')}
            onCancel={logout}
        >
            {children}
        </LoadingHandlerWithRetry>
    );
}

export function useIsMfaDataRequired() {
    const token = useAccessToken();
    const hasTakenOver = useHasTakenOver();
    return CONFIG.mfaEnabled && token != null && hasTakenOver === false;
}

// Bellow are routing utilities to restrict access to some pages based on MFA state

export function RequireMfaDone({ children }: { children: ReactNode }) {
    const { i18n } = useTranslation();
    const isMfaDataRequired = useIsMfaDataRequired();
    const isSessionVerified = useMfaState((state) => state.isSessionVerified);

    if (!isMfaDataRequired) return <>{children}</>;

    if (isSessionVerified == null) {
        throw new Error('MFA state is not loaded');
    }

    if (isSessionVerified === false) {
        return (
            <Redirect to={`/${i18n.language}/login?next=${location.pathname + location.search}`} />
        );
    }
    return <>{children}</>;
}

export function RequireMfaNotDone({ children }: { children: ReactNode }) {
    const isMfaDataRequired = useIsMfaDataRequired();
    const isSessionVerified = useMfaState((state) => state.isSessionVerified);
    const needToCongratulateUser = useMfaState((state) => state.needToCongratulateUser);

    if (!isMfaDataRequired) return <>{children}</>;

    if (isSessionVerified === true && !needToCongratulateUser) {
        return <AlreadyLoggedPage />;
    }
    return <>{children}</>;
}

/** Ensure input function to take at least x ms
 *
 * One common use case is to ensure a loading take some time so the user can notice it
 * @param time in ms
 */
function lastAtLeast<Args extends unknown[], Data>(
    func: (...args: Args) => Promise<Data>,
    time: number,
): (...args: Args) => Promise<Data> {
    return async (...args: Args) => {
        const all = await Promise.all([func(...args), wait(time)]);
        return all[0];
    };
}
