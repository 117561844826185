import React from 'react';

import AlreadyLoggedPage from '@components/nav/redirectPages/AlreadyLoggedPage';

import { useAccessToken } from '@libs/jwt';

export default function RequireNotAuth({ children }: { children: JSX.Element }) {
    const token = useAccessToken();
    if (token != null) {
        return <AlreadyLoggedPage />;
    }
    return children;
}
