import { useCallback, useEffect, useRef, useState } from 'react';

/** A hook to persist data in localStorage or sessionStorage and render component when the data changes */
export function useStorage(key: string, storageInitial: Storage = localStorage) {
    const storage = useRef(storageInitial).current; // we use useRef to persist the same storage object between renders

    const [value, setValue] = useState<string | null>(storage.getItem(key));

    const eventName = 'useStorage'; // we use a custom event to trigger the useEffect hook when the storage changes
    // todo use different event based on storage type

    useEffect(() => {
        function handleStorageChange() {
            setValue(storage.getItem(key));
        }
        window.addEventListener('storage', handleStorageChange); // only triggers from other tabs
        window.addEventListener(eventName, handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.addEventListener(eventName, handleStorageChange);
        };
    }, [key, storage]);

    const setStorageValue = useCallback(
        (newValue: string | null) => {
            if (newValue != null) {
                storage.setItem(key, newValue);
            } else {
                storage.removeItem(key);
            }
            window.dispatchEvent(new Event(eventName));
        },
        [key, storage],
    );

    return [value, setStorageValue] as const;
}
