import { ReactNode, useEffect } from 'react';

import { useOldAppState } from '@hooks/useOldAppState';

import { getUserTypeFromUrl } from '@libs/getSharedVar';
import { getRefreshToken, useAccessToken } from '@libs/jwt';

export function SSOProvider({ children }: { children: ReactNode }) {
    const side = getUserTypeFromUrl();
    if (!side) {
        throw new Error('side is not defined');
    }

    const accessToken = useAccessToken(side);
    if (!accessToken) {
        throw new Error('user must be logged');
    }

    const { app } = useOldAppState();
    const refreshToken = getRefreshToken(side)!;
    const user = app.state.user!;

    useEffect(() => {
        // console.log(`reactor - emit login msg for user ${user.id}`);
        window.postMessage({
            type: 'affilae/login',
            payload: {
                accessToken,
                refreshToken,
                user,
            },
        });
    }, [user.id]);

    return <>{children}</>;
}
