import { ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import IconButton from '@mui/material/IconButton';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';

import CloseIcon from '@mui/icons-material/Close';

// we could consider using https://github.com/iamhosseindhv/notistack, it recommended by MUI

type Notification = {
    id: number;
    kind: 'success' | 'error' | 'info' | 'warning';
    message: string;
};

// word 'flash' is also used in the codebase to design notification for historical reasons

export type AddFlash = (kind: Notification['kind'], message: Notification['message']) => number;
export type RemoveFlash = (id: Notification['id']) => void;

export default function NotificationsProvider({ children }: { children: ReactNode }) {
    const [notifications, setNotification] = useState<Notification[]>([]);

    const displayNotification: AddFlash = useCallback((kind, message) => {
        const id = new Date().getTime();
        setNotification((notifications) => [
            ...notifications,
            {
                id,
                kind,
                message,
            },
        ]);
        return id;
    }, []);

    const hideNotification: RemoveFlash = useCallback((id) => {
        setNotification((notifications) =>
            notifications.filter((notification) => notification.id !== id),
        );
    }, []);

    useEffect(() => {
        // not great practice... should change
        globalThis.addFlash = displayNotification;
        globalThis.removeFlash = hideNotification;

        return () => {
            globalThis.addFlash = undefined;
            globalThis.removeFlash = undefined;
        };
    }, [displayNotification, hideNotification]);

    return (
        <>
            {createPortal(
                // using a portal to avoid notification html to interfere with the rest of the app
                <div>
                    {notifications.map((notification) => (
                        <Snackbar
                            key={notification.id}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={true}
                            autoHideDuration={6000}
                            onClose={() => hideNotification(notification.id)}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            className={'MUI-Snackbar ' + notification.kind}
                            message={<span id='message-id'>{notification.message}</span>}
                            TransitionComponent={(props: SlideProps) => (
                                <Slide {...props} direction='up' />
                            )}
                            action={[
                                <IconButton
                                    key='close'
                                    aria-label='close'
                                    color='inherit'
                                    onClick={() => hideNotification(notification.id)}
                                    size='large'
                                >
                                    <CloseIcon />
                                </IconButton>,
                            ]}
                        />
                    ))}
                </div>,
                document.body,
            )}

            {children}
        </>
    );
}
