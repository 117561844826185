import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import LoadingButton from '@mui/lab/LoadingButton';

import { DialogContent } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import { LoadingView } from '@components/generic/LoadingView';
import { Tooltip } from '@components/generic/Tooltip';
import { DialogNotAuthTitle } from '@components/notAuth/DialogNotAuth';
import { PageNotAuthLayout } from '@components/notAuth/PageNotAuthLayout';

import { usePromise } from '@hooks/usePromise';

import { ParamTypes } from '@libs/getSharedVar';
import { getUserTypeFromAccessToken, hasTokenExpired } from '@libs/jwt';

import confirmEmail from '@api/user/confirmEmail';
import getCurrentUser from '@api/user/getCurrent';
import { sendConfirmEmail } from '@api/user/sendConfirmEmail';

export const tokenQueryKey = 'email-token';
export const hasToken = (t: string | undefined | null) => t != null && t !== '';

// progress update: have to check what is displayed

export default function EmailValidationPage() {
    const { t } = useTranslation();
    const { locale } = useParams<ParamTypes>();
    const history = useHistory();
    const token = new URL(window.location.href).searchParams.get(tokenQueryKey);
    let isValidToken = false;
    let tokenExpired = false;
    if (token != null) {
        try {
            tokenExpired = hasTokenExpired(token);
            isValidToken = true; // because 'hasTokenExpired' throws an error if token is invalid
        } catch (error) {
            //
        }
    }

    const userType = getUserTypeFromAccessToken();
    const isAuth = userType != null;

    const [userLoading, loadUser] = usePromise(getCurrentUser);
    useEffect(() => {
        if (isAuth) {
            loadUser();
        }
    }, []);

    const isEmailAlreadyConfirmed = (function (): boolean | undefined {
        if (isAuth === false) return false;
        if (userLoading.status === 'idle' || userLoading.status === 'loading') return undefined;
        else return userLoading.status === 'success' && userLoading.data.emailConfirmedAt != null;
    })();

    const [emailValidationLoading, handleValidateMail] = usePromise(confirmEmail, {
        onFailure(error) {
            Sentry.captureException(error);
        },
    });
    useEffect(() => {
        if (isEmailAlreadyConfirmed === false && hasToken(token) && isValidToken && !tokenExpired) {
            handleValidateMail(token!);
        }
    }, [isEmailAlreadyConfirmed]);

    const [loadingSend, handleSend] = usePromise(sendConfirmEmail);

    function GoToBtn() {
        const { t } = useTranslation();
        const goTo = isAuth ? `/${locale}/${userType}` : `/${locale}/login`;
        return (
            <Button variant='contained' color='secondary' onClick={() => history.push(goTo)}>
                {isAuth ? t('generic_goToHome') : t('generic_goToLogin')}
            </Button>
        );
    }

    return (
        <PageNotAuthLayout>
            <Helmet>
                <title>{t('emailValidationPage_title')}</title>
            </Helmet>
            <DialogNotAuthTitle>{t('emailValidationPage_title')}</DialogNotAuthTitle>
            {(function () {
                if (isEmailAlreadyConfirmed === undefined)
                    return (
                        <DialogContent>
                            <LoadingView />
                        </DialogContent>
                    );
                else if (isEmailAlreadyConfirmed) {
                    return (
                        <>
                            <DialogContent>
                                <Alert severity='success'>
                                    {t('emailValidationPage_alreadyConfirmed')}
                                </Alert>
                            </DialogContent>
                            <DialogActionsCentered>
                                <GoToBtn />
                            </DialogActionsCentered>
                        </>
                    );
                } else if (
                    token == null ||
                    !isValidToken ||
                    tokenExpired ||
                    emailValidationLoading.status === 'failure'
                ) {
                    const errInfo = (function (): {
                        titleI18nKey: string;
                        descriptionI18nKey?: string;
                    } {
                        if (token == null || !isValidToken)
                            return {
                                titleI18nKey:
                                    'emailValidation_error_emailConfirmationLinkInvalid_title',
                                descriptionI18nKey:
                                    'emailValidation_error_emailConfirmationLinkInvalid_description',
                            };
                        else if (tokenExpired)
                            return {
                                titleI18nKey: 'generic_linkHasExpired',
                                descriptionI18nKey:
                                    'emailValidation_error_emailConfirmationLinkInvalid_description',
                            };
                        else return { titleI18nKey: 'generic_error' };
                    })();

                    return (
                        <>
                            <DialogContent>
                                <Alert severity='error'>
                                    <AlertTitle>{t(errInfo.titleI18nKey)}</AlertTitle>
                                    {errInfo.descriptionI18nKey != null
                                        ? t(errInfo.descriptionI18nKey)
                                        : ''}
                                </Alert>
                            </DialogContent>
                            <DialogActionsCentered>
                                {userLoading.status === 'success' && (
                                    <LoadingButton
                                        onClick={handleSend}
                                        color='secondary'
                                        variant='contained'
                                        disabled={
                                            loadingSend.status === 'loading' ||
                                            loadingSend.status === 'success'
                                        }
                                        loading={loadingSend.status === 'loading'}
                                        endIcon={
                                            (loadingSend.status === 'success' && (
                                                <CheckIcon
                                                    fontSize='inherit'
                                                    style={{ color: 'green' }}
                                                />
                                            )) ||
                                            (loadingSend.status === 'failure' && (
                                                <Tooltip
                                                    title={
                                                        loadingSend.error.message ??
                                                        t(
                                                            'messaging_error_view_basic_msg',
                                                        ).toString()
                                                    }
                                                >
                                                    <ErrorIcon color='error' fontSize='small' />
                                                </Tooltip>
                                            ))
                                        }
                                    >
                                        {loadingSend.status !== 'success'
                                            ? t('emailValidation_reSendMail')
                                            : t('emailValidation_reSendMail_success')}
                                    </LoadingButton>
                                )}
                                <GoToBtn />
                            </DialogActionsCentered>
                        </>
                    );
                } else if (
                    emailValidationLoading.status === 'idle' ||
                    emailValidationLoading.status === 'loading'
                ) {
                    return (
                        <DialogContent>
                            <LoadingView />
                        </DialogContent>
                    );
                } else if (emailValidationLoading.status === 'success') {
                    return (
                        <>
                            <DialogContent>
                                <Alert severity='success'>{t('emailValidationPage_success')}</Alert>
                            </DialogContent>
                            <DialogActionsCentered>
                                <GoToBtn />
                            </DialogActionsCentered>
                        </>
                    );
                } else {
                    // should not happen
                    return null;
                }
            })()}
        </PageNotAuthLayout>
    );
}

const DialogActionsCentered = styled(DialogActions)({
    justifyContent: 'center',
});
