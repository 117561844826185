import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';

import { ParamTypes } from '@libs/getSharedVar';

import creditCard from './wallet.svg';

export default function UnauthorizedBySubscription() {
    const { t } = useTranslation();
    const params = useParams<ParamTypes>();
    return (
        <div style={unauthorizedPagesStyles.container}>
            <img src={creditCard} alt='creditCard' />
            <div style={unauthorizedPagesStyles.container}>
                <p style={unauthorizedPagesStyles.title}>{t('unauthorizedBySubscription_title')}</p>
                <p style={unauthorizedPagesStyles.description}>
                    {t('unauthorizedBySubscription_description')}
                </p>
                <Button
                    variant='contained'
                    color='warning'
                    component={Link}
                    to={generatePath('/:locale/advertiser/billing/subscription', params)}
                >
                    {t('unauthorizedBySubscription_action')}
                </Button>
            </div>
        </div>
    );
}

export const unauthorizedPagesStyles = {
    page: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2rem',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
    },
    creditCard: {
        width: '200px',
        height: '200px',
    },
    title: {
        color: '#223946',
        fontSize: '3em',
        fontWeight: 'bold',
        margin: '0',
    },
    description: {
        fontSize: '1.5em',
        color: 'grey',
    },
};
