import {
    FETCH_PARTNERSHIPS,
    FETCH_PARTNERSHIPS_FAILURE,
    FETCH_PARTNERSHIPS_SUCCESS,
    FETCH_PUBLISHER_PROGRAM_SUCCESS,
} from '@actions/program';

const INITIAL_STATE = {
    partnerships: [],
    currentProgram: null,
    programDetails: {}, // used for publisher for now, see InfoPanelContainer
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_PARTNERSHIPS:
            return {
                ...state,
            };
        case FETCH_PARTNERSHIPS_SUCCESS:
            return {
                ...state,
                partnerships: action.payload,
            };
        case FETCH_PARTNERSHIPS_FAILURE:
            return {
                ...state,
            };

        case FETCH_PUBLISHER_PROGRAM_SUCCESS:
            return {
                ...state,
                programDetails: action.payload,
            };
    }
    return state;
}
