import { AsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { LoadingState } from '@libs/LoadingStatus';

export function createAsyncThunkSlice<Returned, ThunkArg>(
    name: string,
    thunk: AsyncThunk<Returned, ThunkArg, {}>,
) {
    const initialState: LoadingState<Returned, SerializedError> = { status: 'idle' };

    const slice = createSlice({
        name,
        initialState: initialState as LoadingState<Returned, SerializedError>,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(thunk.pending, (state) => {
                state.status = 'loading';
            });
            builder.addCase(thunk.fulfilled, (state, action) => {
                return {
                    status: 'success',
                    data: action.payload,
                    error: state.error,
                };
            });

            builder.addCase(thunk.rejected, (state, action) => {
                return {
                    status: 'failure',
                    data: state.data,
                    error: action.error,
                };
            });
        },
    });

    return slice;
}
