import moment from 'moment';

import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '@api/common/utils';
import { wait } from '@api/common/wait';

import { refineVerifyState, VerifyState, VerifyStateRowResponse } from './types';

export const verifyStateGen = getVerifyStateGenerator();
let _currentVerifyState: VerifyState = verifyStateGen.next().value as VerifyState;
export function getCurrentVerifyState() {
    return _currentVerifyState;
}
export function setCurrentVerifyState(state: VerifyState) {
    _currentVerifyState = state;
}

export async function getVerifyState(): Promise<VerifyState> {
    const response = await fetch(getAPIBaseUrl() + 'common/users/mfa.status', {
        headers: {
            Authorization: getJwt() || '',
        },
    });
    await checkStatus(response, 200);
    const data = (await response.json()) as VerifyStateRowResponse;
    return refineVerifyState(data.userMFAStatus);
}

export const getVerifyStateMock: typeof getVerifyState = async () => {
    await wait(1000);
    return _currentVerifyState;
};

export function* getVerifyStateGenerator(): Generator<VerifyState, VerifyState> {
    let tryCount = 0;

    function getNextTryAt(tryCount: number) {
        return tryCount === 0 ? null : moment().add('4', 'second');
    }

    const limitSmsTries = 3;

    while (tryCount <= limitSmsTries) {
        yield {
            tryCount,
            nextTryAt: getNextTryAt(tryCount),
            currentMethod: 'sms',
            nextMethod: tryCount < limitSmsTries ? 'sms' : 'email',
        };
        tryCount++;
    }

    yield {
        tryCount,
        nextTryAt: getNextTryAt(tryCount),
        currentMethod: 'email',
        nextMethod: 'support',
    };
    tryCount++;

    while (true) {
        yield {
            tryCount,
            nextTryAt: getNextTryAt(tryCount),
            currentMethod: 'support',
            nextMethod: 'support',
        };
    }
}
