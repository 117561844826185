import {
    ADD_INVOICE_SUCCESS,
    ADMIN_MERGE_INVOICES,
    ADMIN_MERGE_INVOICES_FAILURE,
    ADMIN_MERGE_INVOICES_PREVIEW,
    ADMIN_MERGE_INVOICES_PREVIEW_FAILURE,
    ADMIN_MERGE_INVOICES_PREVIEW_SUCCESS,
    ADMIN_MERGE_INVOICES_SUCCESS,
    ADVERTISER_PAYMENT_EARNINGS_LOADING,
    ADVERTISER_PAYMENT_LOADING,
    CLOSE_ALL_DIALOGS,
    DELETE_INVOICE_SUCCESS,
    FETCH_ADVERTISER_EARNINGS_DATA,
    FETCH_ADVERTISER_EARNINGS_DATA_FAILURE,
    FETCH_ADVERTISER_EARNINGS_DATA_SUCCESS,
    FETCH_ADVERTISER_PAYMENT_REQUEST,
    FETCH_ADVERTISER_PAYMENT_REQUEST_FAILURE,
    FETCH_ADVERTISER_PAYMENT_REQUEST_SUCCESS,
    FETCH_ADVERTISER_PAYMENTS_DATA,
    FETCH_ADVERTISER_PAYMENTS_DATA_FAILURE,
    FETCH_ADVERTISER_PAYMENTS_DATA_SUCCESS,
    INIT_PROGRAM,
    OPEN_ADMIN_CONFIRM_MERGE_DIALOG,
    OPEN_DELETE_CUSTOM_INVOICE_DIALOG,
    OPEN_REFUSE_PAYMENT_REQUEST_DIALOG,
    REFUSE_PAYMENT_REQUEST_SUCCESS,
    UPDATE_ADMIN_TOGGLED_PAYMENT_REQUEST_IDS,
    UPDATE_ADVERTISER_EARNINGS_CRITERIA,
    UPDATE_ADVERTISER_PAYMENTS_CRITERIA,
    UPDATE_PAYMENT_REQUEST_STATUS_SUCCESS,
    VERIFY_ADVERTISER,
} from '@actions/advertiserPayments';

import { PeriodRef } from '@components/generic/DateRangePicker';

import { LoadingStatus } from '@libs/LoadingStatus';
import { IsoDate, MongoId, Timestamp } from '@libs/types';

import { ProgramInfo } from '@api/advertiser/fetchProgramInfo';
import { PaymentRequest } from '@api/advertiser/paymentRequests/info';

// Initial query is for the 30 last days
const initialFrom = new Date();
initialFrom.setDate(initialFrom.getDate() - 30);
initialFrom.setHours(0, 0, 0, 0);

const initialTo = new Date();
initialTo.setHours(23, 59, 59, 999);
const INITIAL_STATE: AdvertiserPaymentEarningState = {
    program: null,
    isAdvertiserVerified: false,
    isPublisherVerified: true,
    isPublisherGetBankAccount: true,
    payments: {
        loading: false,
        data: [],
        kpis: [],
        error: null,
        queryCriteria: {
            currency: '',
            dateRange: 'month_to_date',
            status: '', // can be 'pending', 'refused', 'accepted', 'paid', 'scheduled'"
            program: '',
            partnership: '',
            group: '',
            search: '',
        },
    },
    earnings: {
        loading: false,
        data: null,
        error: null,
        queryCriteria: {
            currency: '',
            dateRange: 'last_month',
            status: '', // can be 'active' or 'inactive'
            program: '',
            partnership: '',
            group: '',
            search: '',
        },
    },
    paymentRequestDetails: null,
    statusList: ['pending', 'accepted', 'scheduled', 'refused', 'paid'],
    refusePaymentRequestDialog: {
        isOpen: false,
        paymentRequestId: null,
    },
    deleteCustomInvoiceDialog: {
        isOpen: false,
        paymentRequestId: null,
    },
    adminActions: {
        loadingStatus: 'idle',
        paymentRequestIds: [],
        identifiers: [],
        confirmMergeDialog: {
            preview: null,
            isOpen: false,
        },
    },
    publisherNotVerifiedDialog: {
        isOpen: false,
        missingFields: [],
    },
};

export type AdvertiserPaymentEarningState = {
    program: null | ProgramInfo;
    isAdvertiserVerified: boolean;
    isPublisherVerified: boolean;
    isPublisherGetBankAccount: boolean;
    payments: {
        loading: boolean;
        data: PaymentRequestList[];
        kpis: unknown[];
        error: null | unknown;
        queryCriteria: PaymentsQueryCriteria;
    };
    earnings: {
        loading: boolean;
        data: null | AffiliateEarning[];
        error: null | unknown;
        queryCriteria: EarningsQueryCriteria;
    };
    paymentRequestDetails: PaymentRequest | null;
    statusList: ['pending', 'accepted', 'scheduled', 'refused', 'paid'];
    refusePaymentRequestDialog: {
        isOpen: boolean;
        paymentRequestId: null | MongoId;
    };
    deleteCustomInvoiceDialog: {
        isOpen: boolean;
        paymentRequestId: null | unknown;
    };
    adminActions: {
        loadingStatus: LoadingStatus;
        paymentRequestIds: MongoId[];
        identifiers: unknown[];
        confirmMergeDialog: {
            preview: null | unknown;
            isOpen: boolean;
        };
    };
    publisherNotVerifiedDialog: {
        isOpen: boolean;
        missingFields: string[];
    };
};

export type PaymentsQueryCriteria = {
    currency: string; // can be empty
    dateRange: PeriodRef;
    status: '' | 'pending' | 'refused' | 'accepted' | 'paid' | 'scheduled';
    program: MongoId | '';
    partnership: MongoId | '';
    search: string;
    isBankDetailsValid: boolean;
};

export type EarningsQueryCriteria = {
    currency: string;
    dateRange: PeriodRef;
    status: '' | 'active' | 'inactive';
    program: MongoId | '';
    partnership: MongoId | '';
    search: string;
    isBankDetailsValid: string;
    isThresholdValid: string;
};

export type PaymentRequestList = {
    paymentRequestId: MongoId;
    identifier: string;
    advertiserId: MongoId;
    publisherId: MongoId;
    invoice: MongoId | '';
    affiliateProfile: {
        id: MongoId;
        name: string;
        group?: unknown;
        groups?: unknown;
    };
    group: {
        id: MongoId;
        title: string;
    };
    program: {
        id: MongoId;
        name: string;
    };
    contributionsNb: number;
    contributionsNbCPA: number;
    contributionsNbCPL: number;
    commissionsCPACPL: number;
    commissionsCPA: number;
    commissionsCPL: number;
    clicksNb: number;
    commissionsCPC: number;
    bonus: number;
    amount: number;
    amountVAT: number;
    vat: number;
    currency: string;
    status: string;
    refusedReason: string | '';
    createdAt: IsoDate;
    updatedOn: IsoDate | null;
    invoiceMerged: MongoId | null;
    isCredit?: boolean;
    creditOf?: unknown;
    publisher?: PublisherInfo;
};

export type AffiliateEarning = {
    /** used as id for earning */
    partnershipId: MongoId;
    affiliateProfile: {
        id: MongoId;
        name: string;
    };
    program: {
        id: MongoId;
        name: string;
    };
    group: {
        id: MongoId;
        title: string;
    };
    groupId: MongoId;
    groupTitle: string;
    status: string;
    createdAt: IsoDate;
    commissions: {
        [currency: string]: {
            cpc: number;
            cpacpl: number;
            cpa: number;
            cpl: number;
            total: number;
        };
    };
    publisher: PublisherInfo;
};

type PublisherInfo = {
    address: string;
    bankAccountNumber: string;
    bankAccountNumberType: string;
    bankAccountOwner: string;
    bankAddress: string;
    bankCity: string;
    bankCountry: string;
    bankDetailsVerification: {
        countryRestriction: boolean;
        isVerified: boolean;
        missingData: string[];
        paymentAllowedReasons: string[];
        paymentIsAllowed: boolean;
        preferredPaymentMethod: 'bank' | 'paypal' | null;
        publisher: string;
    };
    bankIdentifierCode: string;
    bankIdentifierType: string;
    bankName: string;
    bankRoutingNumber: string;
    bankRoutingType: string;
    bankZipCode: string;
    city: string;
    companyId: string;
    country: string;
    name: string;
    paypalAccount: string;
    vat: string;
    vatRate: number;
    zipCode: string;
    deletedAt: Timestamp | null;
};

export default function (state = INITIAL_STATE, action): AdvertiserPaymentEarningState {
    let error;
    switch (action.type) {
        case INIT_PROGRAM: {
            return {
                ...state,
                program: action.program,
            };
        }
        case ADVERTISER_PAYMENT_LOADING:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loading: true,
                },
            };
        case ADVERTISER_PAYMENT_EARNINGS_LOADING:
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                    loading: true,
                },
            };
        case UPDATE_ADVERTISER_PAYMENTS_CRITERIA:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    queryCriteria: action.queryCriteria,
                },
            };
        case UPDATE_ADVERTISER_EARNINGS_CRITERIA:
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                    queryCriteria: action.queryCriteria,
                },
            };
        case FETCH_ADVERTISER_PAYMENTS_DATA:
            return {
                ...state,
                payments: {
                    ...state.payments,
                },
            };
        case FETCH_ADVERTISER_PAYMENTS_DATA_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loading: false,
                    data: action.payload.paymentRequests,
                    kpis: action.payload.kpis,
                    error: error,
                },
            };
        case FETCH_ADVERTISER_PAYMENTS_DATA_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loading: false,
                    data: [],
                    kpis: [],
                    error: error,
                },
            };
        case FETCH_ADVERTISER_PAYMENT_REQUEST:
            return {
                ...state,
            };
        case FETCH_ADVERTISER_PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                paymentRequestDetails: action.payload,
                payments: {
                    ...state.payments,
                    loading: false,
                },
            };
        case FETCH_ADVERTISER_PAYMENT_REQUEST_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loading: false,
                    paymentRequestDetails: null,
                    error: error,
                },
            };
        case FETCH_ADVERTISER_EARNINGS_DATA:
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                },
            };
        case FETCH_ADVERTISER_EARNINGS_DATA_SUCCESS:
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                    loading: false,
                    data: action.payload,
                    error: error,
                },
            };
        case FETCH_ADVERTISER_EARNINGS_DATA_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                earnings: {
                    ...state.earnings,
                    loading: false,
                    data: null,
                    error: error,
                },
            };
        case DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    data: action.payload,
                },
                deleteCustomInvoiceDialog: {
                    isOpen: false,
                    paymentRequestId: null,
                },
            };
        case ADD_INVOICE_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    data: action.payload,
                },
            };
        case UPDATE_PAYMENT_REQUEST_STATUS_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    data: action.payload,
                },
                paymentRequestDetails: {
                    ...state.paymentRequestDetails,
                    status: action.status,
                },
            };
        case REFUSE_PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    data: action.payload,
                },
                paymentRequestDetails: {
                    ...state.paymentRequestDetails,
                    status: 'refused',
                    refusedReason: action.refusedReason,
                },
            };

        case UPDATE_ADMIN_TOGGLED_PAYMENT_REQUEST_IDS:
            return {
                ...state,
                adminActions: {
                    ...state.adminActions,
                    paymentRequestIds: action.paymentRequestIds,
                    identifiers: action.identifiers,
                },
            };

        case OPEN_REFUSE_PAYMENT_REQUEST_DIALOG:
            return {
                ...state,
                refusePaymentRequestDialog: {
                    isOpen: true,
                    paymentRequestId: action.paymentRequestId,
                },
            };

        case OPEN_DELETE_CUSTOM_INVOICE_DIALOG:
            return {
                ...state,
                deleteCustomInvoiceDialog: {
                    isOpen: true,
                    paymentRequestId: action.paymentRequestId,
                },
            };

        case OPEN_ADMIN_CONFIRM_MERGE_DIALOG:
            return {
                ...state,
                adminActions: {
                    ...state.adminActions,
                    confirmMergeDialog: {
                        ...state.adminActions.confirmMergeDialog,
                        isOpen: true,
                    },
                },
            };

        case ADMIN_MERGE_INVOICES_PREVIEW:
            return {
                ...state,
                adminActions: {
                    ...state.adminActions,
                    loadingStatus: 'loading',
                },
            };

        case ADMIN_MERGE_INVOICES_PREVIEW_SUCCESS:
            return {
                ...state,
                adminActions: {
                    ...state.adminActions,
                    loadingStatus: 'success',
                    confirmMergeDialog: {
                        ...state.adminActions.confirmMergeDialog,
                        preview: action.payload,
                    },
                },
            };

        case ADMIN_MERGE_INVOICES_PREVIEW_FAILURE:
            return {
                ...state,
                adminActions: {
                    ...state.adminActions,
                    loadingStatus: 'failure',
                },
            };

        case ADMIN_MERGE_INVOICES:
            return {
                ...state,
                adminActions: {
                    ...state.adminActions,
                    loadingStatus: 'loading',
                },
            };

        case ADMIN_MERGE_INVOICES_SUCCESS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    data: action.payload,
                },
                adminActions: {
                    loadingStatus: 'success',
                    paymentRequestIds: [],
                    identifiers: [],
                    confirmMergeDialog: {
                        preview: null,
                        isOpen: false,
                    },
                },
            };

        case ADMIN_MERGE_INVOICES_FAILURE:
            return {
                ...state,
                adminActions: {
                    ...state.adminActions,
                    loadingStatus: 'failure',
                },
            };

        case CLOSE_ALL_DIALOGS:
            return {
                ...state,
                refusePaymentRequestDialog: {
                    isOpen: false,
                    paymentRequestId: null,
                },
                deleteCustomInvoiceDialog: {
                    isOpen: false,
                    paymentRequestId: null,
                },
                adminActions: {
                    ...state.adminActions,
                    confirmMergeDialog: {
                        isOpen: false,
                        preview: null,
                    },
                },
            };

        case VERIFY_ADVERTISER:
            return {
                ...state,
                isAdvertiserVerified: action.isVerified,
            };
        default:
            break;
    }

    return state;
}
