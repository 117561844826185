import { getJwt } from '@libs/jwt';
import { MongoId, Timestamp } from '@libs/types';

import { buildQueryString, checkStatus, getAPISubUrlForCurrentUser } from '../utils';
import { Thread } from './fetchThreads';

type PaginationOptions = {
    offset: number;
    limit: number;
};

export type Message = {
    id: MongoId;
    forPublisher: boolean;
    forAdvertiser: boolean;
    content: string;
    createdAt: Timestamp;
    from: MongoId;
    to: MongoId;
    thread: MongoId;
    program: MongoId;
    affiliateProfile: MongoId;
    attachment?: {
        url: string;
        fileName: string;
    };
};

export async function fetchMessages(
    threadId: MongoId,
    paginationOptions: PaginationOptions | null = null,
) {
    const res = await fetch(
        getAPISubUrlForCurrentUser() +
            '/messages.list' +
            buildQueryString({ threadId, ...(paginationOptions ?? {}) }),
        {
            headers: { Authorization: getJwt() },
        },
    );
    await checkStatus(res);
    const body = await res.json();
    const messages = body.messages.data as Message[];
    const totalCount = body.messages.total as number;
    return { messages, totalCount };
}

export async function searchMessage(searchText: string) {
    const res = await fetch(
        getAPISubUrlForCurrentUser() + '/messages.search' + buildQueryString({ searchText }),
        {
            headers: { Authorization: getJwt() },
        },
    );
    await checkStatus(res);
    return (await res.json()).threads as Thread[];
}

export async function fetchMessage(messageId: MongoId) {
    const res = await fetch(
        getAPISubUrlForCurrentUser() + '/messages.info' + buildQueryString({ messageId }),
        {
            headers: { Authorization: getJwt() },
        },
    );
    await checkStatus(res);
    return (await res.json()).message.data as Message;
}
