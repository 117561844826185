import { checkStatus, getAPIBaseUrl } from './utils';

export interface CountriesStates {
    [code: string]: { name: string };
}

export async function fetchCountriesStates() {
    const response = await fetch(getAPIBaseUrl() + 'common/countriesStates.list');
    await checkStatus(response, 200);
    const body = await response.json();
    return body.countriesStates.countryStateNameByStateCode as CountriesStates;
}
