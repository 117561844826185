import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { useAccessToken } from '@libs/jwt';

export default function RequireAuth({ children }: { children: JSX.Element }) {
    const { i18n } = useTranslation();
    const token = useAccessToken();
    if (token == null) {
        return (
            <Redirect
                to={`/${i18n.language}/login?next=${location.pathname}` + `${location.search}`}
            />
        );
    }
    return children;
}
