import { getJwtAdmin } from '@libs/jwt';

import { buildQueryString, checkStatus, getAPISubUrlForAdmin } from '@api/common/utils';
import { User } from '@api/user/types';

export type UsersPayload = {
    entityType: 'advertiser' | 'publisher';
    id: string;
};
export const usersList = async (payload: UsersPayload): Promise<User[]> => {
    const { entityType, id } = payload;
    const query = { [entityType]: id };
    const url = `${getAPISubUrlForAdmin()}/users.list${buildQueryString(query)}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: { Authorization: getJwtAdmin() },
    });

    checkStatus(response, 200);
    const body = await response.json();
    return body.programs.data;
};

export type AdminTakeoverPayload = {
    id: string;
};
export const adminTakeoverAccess = async (query: AdminTakeoverPayload): Promise<string> => {
    const url = `${getAPISubUrlForAdmin()}/users.auth${buildQueryString(query)}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: { Authorization: getJwtAdmin() },
    });

    await checkStatus(response, 200);
    const body = await response.json();
    return body.programs.data;
};
