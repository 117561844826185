import { checkStatus, getAPIBaseUrl } from '../common/utils';

interface Response {
    accessToken: string;
}

export default async function refreshAccessToken(refreshToken: string): Promise<string> {
    const res = await fetch(getAPIBaseUrl() + 'common/users/token.refresh', {
        method: 'post',
        headers: { Authorization: refreshToken },
    });
    await checkStatus(res, 200);
    const body = (await res.json()) as Response;
    return body.accessToken;
}
