import { CSSProperties } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

import ErrorIcon from '@mui/icons-material/Error';

import PailheLabel from '../PailheLabel';

type PailheTelInputProps = MuiTelInputProps & {
    styleContainer?: CSSProperties;
    bubbleHelperText?: string;
};

type PailheTelInputControlledProps = PailheTelInputProps & {
    name: string;
};

export function PailheTelInput({
    styleContainer,
    label,
    required,
    bubbleHelperText,
    error: hasError,
    helperText,
    ...otherProps
}: PailheTelInputProps) {
    return (
        <div style={styleContainer}>
            <PailheLabel label={label} required={required} helperText={bubbleHelperText}>
                <MuiTelInput
                    role='textbox'
                    id='phoneForm'
                    variant='outlined'
                    defaultCountry='FR'
                    error={true}
                    InputProps={{
                        endAdornment: hasError && <ErrorIcon style={{ color: 'red' }} />,
                    }}
                    helperText={
                        helperText !== undefined && (
                            <span
                                style={{
                                    color: hasError ? 'red' : undefined,
                                    marginLeft: '-14px',
                                    fontSize: '14px',
                                }}
                            >
                                {helperText}
                            </span>
                        )
                    }
                    {...otherProps}
                    fullWidth
                    sx={{
                        '& .MuiInputBase-root': {
                            borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: hasError
                                    ? '#d32f2f!important'
                                    : 'rgba(0, 0, 0, 0.10)!important',
                            },
                            '&:hover fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.10)!important',
                            },
                        },
                    }}
                />
            </PailheLabel>
        </div>
    );
}

export const PailheTelInputControlled = ({ name, ...props }: PailheTelInputControlledProps) => {
    const { t } = useTranslation();

    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                const error = errors[name]?.message as string | undefined;
                return (
                    <PailheTelInput
                        {...field}
                        {...props}
                        error={!!errors[name]}
                        helperText={error ? t(error) : undefined}
                    />
                );
            }}
        />
    );
};
