import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Location } from 'history';

import { LoadingHandler } from '@components/generic/LoadingView';

import { usePromise } from '@hooks/usePromise';
import { useUrlValue } from '@hooks/useUrlState';

import { getUserTypeFromUrl, ParamTypes, useUserTypeFromUrl } from '@libs/getSharedVar';
import { getJwtForUserType, getUserIdFromToken, storeJwt, useAccessToken } from '@libs/jwt';
import { LoadingStatus } from '@libs/LoadingStatus';
import { MongoId } from '@libs/types';

import { adminTakeoverAccess } from '@api/admin/adminAccess/adminTakeoverAccess';

/** Provide necessities to allow admin to taker over user account */
export function TakeOverProvider({ children }: { children: React.ReactChild }) {
    const history = useHistory();
    const { side: requestedAppSide } = useParams<ParamTypes>();

    const takeOverId = useTakeOverId();
    const takeOverEmail = useTakeOverEmail(); // todo to remove when we don't need to support old takeover url
    const takeOverIdOrEmail = takeOverId ?? takeOverEmail;

    const accessTokenOfAdmin = useAccessToken('admin');
    const accessTokenOfCurrentSide = useAccessToken(requestedAppSide);
    const accessTokenOfControlledUser =
        requestedAppSide === 'admin' ? accessTokenOfAdmin : accessTokenOfCurrentSide;

    const [lastTakenOverId, setLastTakenOverId] = useState<MongoId | null>(null);

    const [takeOverLoading, takeOver] = usePromise(
        async (takeOverIdOrEmail: string) => {
            return await adminTakeoverAccess({ id: takeOverIdOrEmail });
        },
        {
            onSuccess: (token) => {
                storeJwt(token, requestedAppSide);
                if (takeOverEmail != null) {
                    const userId = getUserIdFromToken(token);
                    replaceInUrlTakeOverEmailByUserId(userId);
                    setLastTakenOverId(userId);
                } else {
                    setLastTakenOverId(takeOverId!);
                }
            },
        },
    );

    const isMissingTakeOverInfoInUrl = getIsMissingTakeOverInfoInUrl(history.location);
    const loadingStatus: LoadingStatus = (function () {
        if (isMissingTakeOverInfoInUrl) {
            return 'loading';
        } else if (takeOverLoading.status === 'success' && takeOverId !== lastTakenOverId) {
            return 'loading';
        } else {
            return takeOverLoading.status;
        }
    })();

    function replaceInUrlTakeOverEmailByUserId(userId: MongoId) {
        const searchParamsNext = new URLSearchParams(history.location.search);
        searchParamsNext.delete(takeOverKeyOld);
        searchParamsNext.set(takeOverKey, userId);
        history.replace({ search: searchParamsNext.toString() });
    }

    const couldTakeOver =
        takeOverIdOrEmail != null && requestedAppSide !== 'admin' && accessTokenOfAdmin != null;

    const hasTakenOver = getHasAdminTakenAccountControl();
    useEffect(() => {
        if (hasTakenOver) {
            Sentry.setTag('takeOver', true);
        } else {
            Sentry.setTag('takeOver', false);
        }
    }, [hasTakenOver]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (couldTakeOver && takeOverId !== lastTakenOverId) {
            takeOver(takeOverIdOrEmail);
            // we do it even if admin has already taken over because
            // it is possible that the admin has taken over another
            // account than the one requested in the url
        }
    }, [couldTakeOver, takeOverIdOrEmail]); // eslint-disable-line react-hooks/exhaustive-deps
    // disabling exhaustive deps because we can't include takeOver in the
    // deps array because it is not stable and would trigger an infinite loop

    const takeOverSubEntityId = useTakeOverSubEntityId();
    const takeOverDataBackupString = sessionStorage.getItem('takeOverData');
    const takeOverDataBackup = takeOverDataBackupString
        ? (JSON.parse(takeOverDataBackupString) as TakeOverBackupData)
        : undefined;

    // backup takeover data in session storage because they are effaced when user navigate
    useEffect(() => {
        if (hasTakenOver && takeOverId) {
            sessionStorage.setItem(
                'takeOverData',
                JSON.stringify({ takeOverId, takeOverSubEntityId }),
            );
        } else if (!hasTakenOver) {
            sessionStorage.removeItem('takeOverData');
        }
    }, [hasTakenOver, takeOverId, takeOverSubEntityId]);

    // add the takeoverId to the url if missing when there is a takeover
    // it is very useful since it allow us to create navigation links carefree
    // of the takeover state
    useEffect(() => {
        handleUrlChange(history.location);
        return history.listen(handleUrlChange);

        function handleUrlChange(location: Location) {
            if (getIsMissingTakeOverInfoInUrl(location)) {
                const searchParams = new URLSearchParams(location.search);
                const userId = getUserIdFromToken(accessTokenOfControlledUser!);
                searchParams.set(takeOverKey, userId);
                if (takeOverDataBackup?.takeOverSubEntityId != null) {
                    searchParams.set(takeOverSubEntityKey, takeOverDataBackup.takeOverSubEntityId);
                }
                history.replace({ search: searchParams.toString() });
            }
        }
    }, [history, accessTokenOfControlledUser, takeOverDataBackup]);

    // clean unnecessary takeoverId
    useEffect(() => {
        if (
            (takeOverId != null || takeOverSubEntityId != null || takeOverEmail != null) &&
            (requestedAppSide === 'admin' || accessTokenOfAdmin == null)
        ) {
            const searchParams = new URLSearchParams(history.location.search);
            searchParams.delete(takeOverKey);
            searchParams.delete(takeOverSubEntityKey);
            searchParams.delete(takeOverKeyOld);
            history.replace({ search: searchParams.toString() });
        }
    }, [
        requestedAppSide,
        accessTokenOfAdmin,
        history,
        takeOverId,
        takeOverSubEntityId,
        takeOverEmail,
    ]);

    useEffect(() => {
        return history.listen((location) => {
            if (location.pathname.includes('admin')) {
                localStorage.setItem(lastAdminUrlStorageKey, location.pathname + location.search);
            }
        });
    }, [history]);

    if (!couldTakeOver && !isMissingTakeOverInfoInUrl) {
        return <>{children}</>;
    }

    return (
        <LoadingHandler
            status={loadingStatus}
            errorMsg={`Unable to take control of this account '${takeOverIdOrEmail}'`}
        >
            {children}
        </LoadingHandler>
    );
}

type TakeOverBackupData = {
    takeOverId: MongoId;
    takeOverSubEntityId: MongoId;
};

function getIsMissingTakeOverInfoInUrl(
    location: Location,
    takeOverDataBackup?: TakeOverBackupData,
) {
    const searchParams = new URLSearchParams(location.search);
    const hasTakenOver = getHasAdminTakenAccountControl();
    return (
        hasTakenOver &&
        (!searchParams.has(takeOverKey) ||
            (takeOverDataBackup?.takeOverSubEntityId != null &&
                !searchParams.has(takeOverSubEntityKey)))
    );
}

export function getHasAdminTakenAccountControl() {
    const userType = getUserTypeFromUrl();
    const adminToken = getJwtForUserType('admin');
    const accessToken = userType ? getJwtForUserType(userType) : undefined;
    if (
        (userType === 'advertiser' || userType === 'publisher') &&
        adminToken != null &&
        accessToken != null
    ) {
        return true;
    } else {
        return false;
    }
}

export function useHasTakenOver() {
    const userType = useUserTypeFromUrl();
    const adminToken = useAccessToken('admin');
    const accessToken = useAccessToken(userType);

    return (
        (userType === 'advertiser' || userType === 'publisher') &&
        adminToken != null &&
        accessToken != null
    );
}

/** url query string key used for takeover */
export const takeOverKey = 'takeOverId';
export const takeOverSubEntityKey = 'takeOverSubEntityId';

/** @deprecated */
export const takeOverKeyOld = '_switch_user';

export function createTakeOverUrlForSubEntity({
    path,
    subEntityId,
    userId,
}: {
    /** path with query string */
    path: string;
    subEntityId: MongoId;
    userId?: MongoId;
}): string {
    return updateSearchParamsInPathWithQuerystring(path, (searchParams) => {
        searchParams.set(takeOverKey, userId ?? subEntityId);
        searchParams.set(takeOverSubEntityKey, subEntityId);
    });
}

export function createTakeOverUrlForEntity({
    path,
    entityId,
    userId,
}: {
    /** path with query string */
    path: string;
    entityId: MongoId;
    userId?: MongoId;
}): string {
    return updateSearchParamsInPathWithQuerystring(path, (searchParams) => {
        searchParams.set(takeOverKey, userId ?? entityId);
    });
}
/**
 *
 * @param pathWithQueryString ex: /advertiser/dashboard?currency=EUR
 * @param updater use mutation on searchParams
 * @returns
 */
export function updateSearchParamsInPathWithQuerystring(
    pathWithQueryString: string,
    updater: (searchParams: URLSearchParams) => void,
) {
    const [path, queryString] = pathWithQueryString.split('?');
    const searchParams = new URLSearchParams(queryString);
    updater(searchParams);
    return path + (searchParams.toString() ? '?' + searchParams.toString() : '');
}

export function useTakeOverId(): string | null {
    const getTakeOverId = useCallback(
        (location: Location) => new URLSearchParams(location.search).get(takeOverKey),
        [],
    );
    const [value] = useUrlValue({ get: getTakeOverId });
    return value;
}

export function useTakeOverEmail(): string | null {
    const getTakeOverId = useCallback(
        (location: Location) => new URLSearchParams(location.search).get(takeOverKeyOld),
        [],
    );
    const [value] = useUrlValue({ get: getTakeOverId });
    return value;
}

export function useTakeOverSubEntityId(): string | null {
    const getTakeOverSubEntityId = useCallback(
        (location: Location) => new URLSearchParams(location.search).get(takeOverSubEntityKey),
        [],
    );
    const [value] = useUrlValue({ get: getTakeOverSubEntityId });
    return value;
}

export const lastAdminUrlStorageKey = 'urlBeforeTakeOver';
