import { checkStatus, getAPIBaseUrl } from './utils';

export interface AffiliateType {
    slug: string;
    title_fr: string;
    title_en: string;
    title_es: string;
}

export async function fetchProfilesTypes() {
    const url = getAPIBaseUrl() + 'marketplace/affiliateTypes.list';
    const response = await fetch(url);
    await checkStatus(response);
    const body = await response.json();
    return body.affiliateTypes as AffiliateType[];
}
