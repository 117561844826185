import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { ReducerPublisherPartnershipState } from 'reducers/reducer_publisher_partnerships';

import { AppDispatch } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import listPartnerships, { PartnershipPublisher } from '@api/common/partnerships/list';
import updatePartnershipStatus from '@api/publisher/partnerships/updateStatus';

export const PUBLISHER_PARTNERSHIPS_LOADING = 'PUBLISHER_PARTNERSHIPS_LOADING';

export const FETCH_PUBLISHER_PARTNERSHIPS = 'FETCH_PUBLISHER_PARTNERSHIPS';
export const FETCH_PUBLISHER_PARTNERSHIPS_SUCCESS = 'FETCH_PUBLISHER_PARTNERSHIPS_SUCCESS';
export const FETCH_PUBLISHER_PARTNERSHIPS_FAILURE = 'FETCH_PUBLISHER_PARTNERSHIPS_FAILURE';

export const UPDATE_PUBLISHER_PARTNERSHIP_STATUS = 'UPDATE_PUBLISHER_PARTNERSHIP_STATUS';
export const UPDATE_PUBLISHER_PARTNERSHIP_STATUS_SUCCESS =
    'UPDATE_PUBLISHER_PARTNERSHIP_STATUS_SUCCESS';
export const UPDATE_PUBLISHER_PARTNERSHIP_STATUS_FAILURE =
    'UPDATE_PUBLISHER_PARTNERSHIP_STATUS_FAILURE';

export function fetchPartnershipsThunk(
    queryCriteria: ReducerPublisherPartnershipState['queryCriteria'],
) {
    return async function (dispatch: AppDispatch) {
        dispatch({
            type: PUBLISHER_PARTNERSHIPS_LOADING,
            payload: queryCriteria,
        });
        dispatch({ type: FETCH_PUBLISHER_PARTNERSHIPS });

        try {
            const partnerships = await listPartnerships('publisher', {
                affiliateProfile: queryCriteria.affiliateProfile,
                programs: queryCriteria.programs,
                status: queryCriteria.status,
                rates: queryCriteria.rates,
                search: queryCriteria.search,
                withDetails: true,
                withProgramsDetails: true,
            });
            const sortedData = _.sortBy(partnerships, 'id').reverse();
            dispatch({
                type: FETCH_PUBLISHER_PARTNERSHIPS_SUCCESS,
                payload: sortedData,
            });
        } catch (error) {
            dispatch({
                type: FETCH_PUBLISHER_PARTNERSHIPS_FAILURE,
                payload: error,
            });
        }
    };
}

export function updatePartnershipStatusThunk(
    partnershipId: MongoId,
    status: 'pending' | 'cancelled_publisher',
    reason: string,
    allData: PartnershipPublisher[],
) {
    return async function (dispatch: AppDispatch) {
        dispatch({
            type: UPDATE_PUBLISHER_PARTNERSHIP_STATUS,
        });

        try {
            const partnership = await updatePartnershipStatus({
                partnershipId,
                status,
                reason,
            });

            const updatedData = (function () {
                const partnershipIndex = allData.findIndex((item) => item.id === partnership.id);
                const updatedData = cloneDeep(allData);
                updatedData[partnershipIndex] = partnership;
                return updatedData;
            })();

            dispatch({
                type: UPDATE_PUBLISHER_PARTNERSHIP_STATUS_SUCCESS,
                payload: updatedData,
            });
        } catch (error) {
            dispatch({
                type: UPDATE_PUBLISHER_PARTNERSHIP_STATUS_FAILURE,
                payload: error,
            });
        }
    };
}
