import {
    CLOSE_SEARCH_RESULTS,
    CLOSE_THREAD_CREATION_PANEL,
    DELETE_THREAD_FILE,
    DOWNLOAD_THREAD_SUCCESS,
    LOAD_THREADS_FAILURE,
    LOAD_THREADS_REQUEST,
    LOAD_THREADS_SUCCESS,
    MARK_THREAD_AS_READ_REQUEST,
    MARK_THREAD_AS_READ_SUCCESS,
    MARK_THREAD_AS_UNREAD_REQUEST,
    MARK_THREAD_AS_UNREAD_SUCCESS,
    OPEN_THREAD_CREATION_PANEL,
    SEARCH_MESSAGE_FAILURE,
    SEARCH_MESSAGE_REQUEST,
    SEARCH_MESSAGE_SUCCESS,
    SELECT_THREAD,
    SEND_GLOBAL_MESSAGE_FAILURE,
    SEND_GLOBAL_MESSAGE_REQUEST,
    SEND_GLOBAL_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    THREAD_FILTER_CHANGE_REQUEST,
    THREAD_FILTER_CHANGE_SUCCESS,
    UNSELECTED_THREAD,
    UPDATE_EDITOR_STATE,
} from '@actions/message';
import _ from 'lodash';

import { MongoId } from '@libs/types';

import { Thread } from '@api/common/messages/fetchThreads';

type MessageState = {
    threads: {
        isLoading: boolean;
        data: Thread[] | null;
        error: unknown | null;
    };
    threadFilter: boolean;
    selectedThreadId: MongoId | null;
    selectedMessageId: MongoId | null;
    search: {
        isOpen: boolean;
        isLoading: boolean;
        data: Thread[] | null;
        error: unknown | null;
    };
    threadCreationPanel: {
        isOpen: boolean;
        toEntityId: MongoId | null; // either a program or an affiliate profile depending of type of logged user
        FromEntityId: MongoId | null; // an entity too, but the opposite of the one above. ex: if 'toEntityId' is a affiliateProfile id, then this is a program id
        toUserId: MongoId | null;
    };
    messageEditor: {
        shouldEditorReset: boolean;
        isLoading: boolean;
        error: unknown | null;
    };
};

const initialState: MessageState = {
    threads: {
        isLoading: false,
        data: null,
        error: null,
    },
    threadFilter: false,
    selectedThreadId: null,
    selectedMessageId: null,
    search: {
        isOpen: false,
        isLoading: false,
        data: null,
        error: null,
    },
    threadCreationPanel: {
        isOpen: false,
        toEntityId: null, // either a program or an affiliate profile depending of type of logged user
        FromEntityId: null, // an entity too, but the opposite of the one above. ex: if 'toEntityId' is a affiliateProfile id, then this is a program id
        toUserId: null,
    },
    messageEditor: {
        file: null,
        shouldEditorReset: true,
        isLoading: false,
        error: null,
    },
};

export default function reducer(state = initialState, action) {
    const nextState = _.cloneDeep(state);
    let index;
    switch (action.type) {
        case OPEN_THREAD_CREATION_PANEL:
            nextState.threadCreationPanel.isOpen = true;
            nextState.threadCreationPanel.toEntityId = action.toEntityId;
            nextState.threadCreationPanel.toUserId = action.toUserId;
            return nextState;
        case CLOSE_THREAD_CREATION_PANEL:
            nextState.threadCreationPanel.isOpen = false;
            nextState.threadCreationPanel.toEntityId = null;
            nextState.threadCreationPanel.toUserId = null;
            return nextState;
        case SEARCH_MESSAGE_REQUEST:
            nextState.search.isOpen = true;
            nextState.search.isLoading = true;
            nextState.search.data = null;
            nextState.search.error = null;
            return nextState;
        case SEARCH_MESSAGE_SUCCESS:
            nextState.search.isLoading = false;
            nextState.search.data = action.results;
            nextState.search.error = null;
            return nextState;
        case SEARCH_MESSAGE_FAILURE:
            nextState.search.isLoading = false;
            nextState.search.data = null;
            nextState.search.error = action.error;
            return nextState;
        case CLOSE_SEARCH_RESULTS:
            nextState.search.isOpen = false;
            nextState.search.isLoading = false;
            nextState.search.data = null;
            nextState.search.error = null;
            return nextState;
        case SELECT_THREAD:
            nextState.selectedThreadId = action.threadId;
            nextState.selectedMessageId = action.messageId;
            nextState.threadCreationPanel.isOpen = false;
            nextState.threadCreationPanel.toEntityId = null;
            nextState.threadCreationPanel.toUserId = null;
            return nextState;
        case DOWNLOAD_THREAD_SUCCESS:
            index = nextState.threads.data.findIndex((thread) => thread.id === action.threadId);
            nextState.threads.data[index].downloadFile = action.text;
            return nextState;
        case DELETE_THREAD_FILE:
            index = nextState.threads.data.findIndex((thread) => thread.id === action.threadId);
            delete nextState.threads.data[index].downloadFile;
            return nextState;
        case UNSELECTED_THREAD:
            nextState.selectedThreadId = null;
            nextState.threadCreationPanel.isOpen = false;
            nextState.threadCreationPanel.toEntityId = null;
            nextState.threadCreationPanel.toUserId = null;
            return nextState;
        case LOAD_THREADS_REQUEST:
            nextState.threads.isLoading = true;
            nextState.threads.data = null;
            nextState.threads.error = null;
            return nextState;
        case LOAD_THREADS_SUCCESS:
            nextState.threads.isLoading = false;
            nextState.threads.data = action.threads;
            nextState.threads.error = null;
            return nextState;
        case LOAD_THREADS_FAILURE:
            nextState.threads.isLoading = false;
            nextState.threads.data = [];
            nextState.threads.error = action.error;
            return nextState;
        case THREAD_FILTER_CHANGE_REQUEST:
            nextState.threadFilter = action.filter;
            nextState.threads.isLoading = true;
            return nextState;
        case THREAD_FILTER_CHANGE_SUCCESS:
            nextState.threads.isLoading = false;
            nextState.threads.data = action.threads;
            return nextState;
        case MARK_THREAD_AS_UNREAD_REQUEST: {
            index = nextState.threads.data.findIndex((thread) => thread.id === action.threadId);
            const updatedThread = { ...nextState.threads.data[index], unreadStatusLoading: true };
            nextState.threads.data[index] = updatedThread;
            return nextState;
        }
        case MARK_THREAD_AS_UNREAD_SUCCESS: {
            index = nextState.threads.data.findIndex((thread) => thread.id === action.thread.id);
            nextState.threads.data[index] = action.thread;
            return nextState;
        }
        case MARK_THREAD_AS_READ_REQUEST: {
            const index = nextState.threads.data.findIndex(
                (thread) => thread.id === action.threadId,
            );
            const updatedThread = { ...nextState.threads.data[index], unreadStatusLoading: true };
            nextState.threads.data[index] = updatedThread;
            return nextState;
        }
        case MARK_THREAD_AS_READ_SUCCESS: {
            const foundIndex = nextState.threads.data.findIndex(
                (thread) => thread.id === action.thread.id,
            );
            nextState.threads.data[foundIndex] = action.thread;
            return nextState;
        }

        case SEND_MESSAGE_REQUEST:
        case SEND_GLOBAL_MESSAGE_REQUEST: {
            nextState.messageEditor = {
                ...state.messageEditor,
                isLoading: true,
            };
            return nextState;
        }

        case SEND_MESSAGE_FAILURE:
        case SEND_GLOBAL_MESSAGE_FAILURE: {
            nextState.messageEditor = {
                ...state.messageEditor,
                isLoading: false,
                error: action.error,
            };
            return nextState;
        }

        case SEND_MESSAGE_SUCCESS:
        case SEND_GLOBAL_MESSAGE_SUCCESS: {
            nextState.messageEditor = {
                ...state.messageEditor,
                shouldEditorReset: true,
                file: null,
                isLoading: false,
            };
            return nextState;
        }

        case UPDATE_EDITOR_STATE: {
            nextState.messageEditor = {
                ...nextState.messageEditor,
                shouldEditorReset: false,
            };
            return nextState;
        }

        // sync actions:
        // async actions: (x3 with request, success, failure)
        // downloadThread, what to do with result? (file in a base64 string)
        // sendMessage
        // sendGlobalMessage
        default:
            return nextState;
    }
}
