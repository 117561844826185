import { getJwt } from '@libs/jwt';

import { checkStatus, getAPISubUrlForPublisher } from '@api/common/utils';
import { AffiliateCommonDetails } from '@api/publisher/interfacesPublisher';

export async function updateAffiliateProfile(fields: AffiliateCommonDetails) {
    const res = await fetch(getAPISubUrlForPublisher() + '/affiliateProfiles.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: fields.id,
            name: fields.title,
            teaser: fields.teaser,
            description: fields.description,
            url: fields.url,
            type: fields.type,
            categories: fields.categories,
            countries: fields.countries,
            currency: fields.currency,
            pagesViews: fields.pagesViews
                ? parseFloat(fields.pagesViews.replace(',', '.').replace(' ', ''))
                : 0,
            uniqueVisitors: fields.visitors
                ? parseFloat(fields.visitors.replace(',', '.').replace(' ', ''))
                : 0,
            tags: fields.keywords.filter(function (entry) {
                return entry.trim() != '';
            }),
            onMarketplace: fields.marketplace,
            notificationConversion: fields.conversionNotification,
            notificationMessage: fields.messageNotification,
            notificationAd: fields.adsNotification,
            manager: fields.mainUser,
            facebookUrl: fields.facebook,
            tiktokUrl: fields.tiktok,
            twitterUrl: fields.twitter,
            instagramUrl: fields.instagram,
            pinterestUrl: fields.pinterest,
            youtubeUrl: fields.youtube,
        }),
    });

    await checkStatus(res);
    const body = await res.json();
    return body.affiliateProfile;
}
