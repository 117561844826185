import _ from 'lodash';
import { AdvertiserAdsState } from 'reducers/reducer_advertiser_ads';

import { downloadBlob } from '@libs/fileHelper';
import { getJwt } from '@libs/jwt';
import { AppDispatch } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import { getAPISubUrlForAdvertiser } from '@api/common/utils';

export const ADVERTISER_ADS_LOADING = 'ADVERTISER_ADS_LOADING';
export const FETCH_ADVERTISER_ADS = 'FETCH_ADVERTISER_ADS';
export const FETCH_ADVERTISER_ADS_SUCCESS = 'FETCH_ADVERTISER_ADS_SUCCESS';
export const FETCH_ADVERTISER_ADS_FAILURE = 'FETCH_ADVERTISER_ADS_FAILURE';

export const DELETE_ADVERTISER_AD = 'DELETE_ADVERTISER_AD';
export const DELETE_ADVERTISER_AD_SUCCESS = 'DELETE_ADVERTISER_AD_SUCCESS';
export const DELETE_ADVERTISER_AD_FAILURE = 'DELETE_ADVERTISER_AD_FAILURE';

export const SEND_ADVERTISER_AD = 'SEND_ADVERTISER_AD';
export const SEND_ADVERTISER_AD_SUCCESS = 'SEND_ADVERTISER_AD_SUCCESS';
export const SEND_ADVERTISER_AD_FAILURE = 'SEND_ADVERTISER_AD_FAILURE';

export const DOWNLOAD_ADVERTISER_AD = 'DOWNLOAD_ADVERTISER_AD';
export const DOWNLOAD_ADVERTISER_AD_SUCCESS = 'DOWNLOAD_ADVERTISER_AD_SUCCESS';
export const DOWNLOAD_ADVERTISER_AD_FAILURE = 'DOWNLOAD_ADVERTISER_AD_FAILURE';

export const OPEN_ADVERTISER_AD_PREVIEW = 'OPEN_ADVERTISER_AD_PREVIEW';
export const OPEN_ADVERTISER_AD_DELETE = 'OPEN_ADVERTISER_AD_DELETE';
export const OPEN_ADVERTISER_AD_CREATE = 'OPEN_ADVERTISER_AD_CREATE';
export const OPEN_ADVERTISER_AD_EDIT = 'OPEN_ADVERTISER_AD_EDIT';

export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';

export function advertiserAdsLoading() {
    return {
        type: ADVERTISER_ADS_LOADING,
    };
}

export function fetchAds(queryCriteria: AdvertiserAdsState['queryCriteria']) {
    return (dispatch: AppDispatch) => {
        let statusCode: number;
        dispatch(advertiserAdsLoading());
        dispatch(fetchAdvertiserAds(queryCriteria))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch(fetchAdvertiserAdsFailure(statusCode));
                } else {
                    dispatch(closeAllDialogs());
                    dispatch(fetchAdvertiserAdsSuccess(result));
                }
            });
    };
}

export function fetchAdvertiserAds(queryCriteria: AdvertiserAdsState['queryCriteria']) {
    let qs = '?program=' + queryCriteria.program;

    if (!_.isEmpty(queryCriteria.type)) {
        qs = qs.concat('&type=' + queryCriteria.type);
    }
    if (!_.isEmpty(queryCriteria.status)) {
        qs = qs.concat('&status=' + queryCriteria.status);
    }
    if (!_.isEmpty(queryCriteria.affiliateProfile)) {
        qs = qs.concat('&affiliateProfile=' + queryCriteria.affiliateProfile);
    }
    if (!_.isEmpty(queryCriteria.search)) {
        qs = qs.concat('&search=' + queryCriteria.search);
    }

    const promise = fetch(getAPISubUrlForAdvertiser() + '/ads.list' + qs, {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
        },
    });
    return {
        type: FETCH_ADVERTISER_ADS,
        payload: promise,
    };
}

export function fetchAdvertiserAdsSuccess(data) {
    return {
        type: FETCH_ADVERTISER_ADS_SUCCESS,
        payload: data,
    };
}

export function fetchAdvertiserAdsFailure(error) {
    return {
        type: FETCH_ADVERTISER_ADS_FAILURE,
        payload: error,
    };
}

export function deleteAd(
    programId: MongoId,
    adId: MongoId,
    queryCriteria: AdvertiserAdsState['queryCriteria'],
) {
    return (dispatch: AppDispatch) => {
        let statusCode: number;
        dispatch(deleteAdvertiserAd(programId, adId)).then(function (response) {
            statusCode = response.payload.status;
            if (statusCode !== 200) {
                dispatch(deleteAdvertiserAdFailure(statusCode));
            } else {
                dispatch(deleteAdvertiserAdSuccess());
                dispatch(closeAllDialogs());
                addFlash('success', _t('ad_delete_dialog_success'));
                dispatch(fetchAds(queryCriteria));
            }
        });
    };
}

export function deleteAdvertiserAd(programId: MongoId, adId: MongoId) {
    const bodyObj = {
        id: adId,
        program: programId,
    };

    const promise = fetch(getAPISubUrlForAdvertiser() + '/ads.delete', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: DELETE_ADVERTISER_AD,
        payload: promise,
    };
}

export function deleteAdvertiserAdSuccess() {
    return {
        type: DELETE_ADVERTISER_AD_SUCCESS,
    };
}

export function deleteAdvertiserAdFailure(error) {
    return {
        type: DELETE_ADVERTISER_AD_FAILURE,
        payload: error,
    };
}

export function downloadAdvertiserAd(programId, adId, affiliateProfileId) {
    let qs = '?id=' + adId;
    qs = qs.concat('&program=' + programId);
    qs = qs.concat('&affiliateProfile=' + affiliateProfileId);

    const promise = fetch(getAPISubUrlForAdvertiser() + '/ads.get' + qs, {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
        },
    });

    return {
        type: DOWNLOAD_ADVERTISER_AD,
        payload: promise,
    };
}

export function downloadAdvertiserAdSuccess(ad, data) {
    downloadBlob(data, ad.title + '.zip');
    return { type: DOWNLOAD_ADVERTISER_AD_SUCCESS };
}

export function downloadAdvertiserAdFailure(error) {
    return {
        type: DOWNLOAD_ADVERTISER_AD_FAILURE,
        payload: error,
    };
}

export function sendAdvertiserAd(programId, adId, affiliateProfileId) {
    const bodyObj = {
        id: adId,
        program: programId,
        affiliateProfile: affiliateProfileId,
    };

    const promise = fetch(getAPISubUrlForAdvertiser() + '/ads.send', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyObj),
    });

    return {
        type: SEND_ADVERTISER_AD,
        payload: promise,
    };
}

export function sendAdvertiserAdSuccess() {
    return {
        type: SEND_ADVERTISER_AD_SUCCESS,
    };
}

export function sendAdvertiserAdFailure(error) {
    return {
        type: SEND_ADVERTISER_AD_FAILURE,
        payload: error,
    };
}

export function openAdvertiserAdPreview(ad) {
    return {
        type: OPEN_ADVERTISER_AD_PREVIEW,
        payload: ad,
    };
}

export function openAdvertiserAdDelete(ad) {
    return {
        type: OPEN_ADVERTISER_AD_DELETE,
        payload: ad,
    };
}

export function openAdvertiserAdCreate() {
    return {
        type: OPEN_ADVERTISER_AD_CREATE,
    };
}

export function openAdvertiserAdEdit(ad) {
    return {
        type: OPEN_ADVERTISER_AD_EDIT,
        payload: ad,
    };
}

export function closeAllDialogs() {
    return {
        type: CLOSE_ALL_DIALOGS,
    };
}
