import { useEffect, useState } from 'react';
import moment from 'moment';

import { useMfaState } from '@providers/MfaStateProvider';

import { User } from '@api/user/types';

import { MfaFailureView } from './FailureView';
import { MfaRegisterMethodView } from './RegisterMethodView';
import { MfaSuccessView } from './SuccessView';
import { MfaVerifyCodeView } from './VerifyCodeView';

// 💡 mfa = Multi Factor Authentication

export type MfaFormProps = {
    scenario: Scenario;
    onClose: (reason: 'success' | 'failure' | 'cancel') => void;
    user: User;
    cancelBtnLabel?: string;
};

export type Scenario = 'setup' | 'confirm';

type MfaView = 'registerMethod' | 'verifyCode' | 'success' | 'failure';

/** Can be used to insert Two factor auth inside another dialog*/
export function MfaForm({
    onClose,
    scenario,
    user: userInitial,
    cancelBtnLabel = 'Cancel',
}: MfaFormProps) {
    const verifyState = useMfaState((state) => state.getVerifyState());
    const [user, setUser] = useState(userInitial);
    const [view, setView] = useState<MfaView>(() => {
        if (user.mfa.phone == null && verifyState.currentMethod !== 'email') {
            return 'registerMethod';
        } else if (verifyState.currentMethod === 'support') {
            return 'failure';
        } else {
            return 'verifyCode';
        }
    });
    const resetVerifyState = useMfaState((state) => state.reset);

    return (
        <div data-testid='mfaForm'>
            {view === 'registerMethod' && (
                <MfaRegisterMethodView
                    defaultPhone={user.mfa.phone ?? user.phone}
                    cancelBtnLabel={cancelBtnLabel}
                    onSuccess={(user) => {
                        setUser(user);
                        setView('verifyCode');
                    }}
                    onCancel={() => {
                        onClose('cancel');
                    }}
                />
            )}
            {view === 'verifyCode' && (
                <MfaVerifyCodeView
                    scenario={scenario}
                    cancelBtnLabel={cancelBtnLabel}
                    onClose={(reason) => {
                        switch (reason) {
                            case 'success':
                                setView('success');
                                break;
                            case 'failure':
                                setView('failure');
                                break;
                            case 'cancel':
                                onClose('cancel');
                                break;
                            case 'editPhone':
                                setView('registerMethod');
                                break;
                        }
                    }}
                    userInfo={{
                        email: user.email,
                        phone: user.mfa.phone!,
                    }}
                />
            )}
            {view === 'success' && (
                <MfaSuccessView
                    scenario={scenario}
                    onContinue={() => {
                        onClose('success');
                    }}
                />
            )}
            {view === 'failure' && (
                <MfaFailureView
                    cancelBtnLabel={cancelBtnLabel}
                    onCancel={() => {
                        onClose('failure');
                    }}
                    delayBeforeRetry={moment
                        .duration(verifyState.nextTryAt?.diff(moment()))
                        .asMilliseconds()}
                    onRetryAvailable={async () => {
                        await resetVerifyState();
                        setView('verifyCode');
                    }}
                />
            )}
        </div>
    );
}
