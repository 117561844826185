import { formatNumber as formatPhoneNumber } from 'libphonenumber-js';

/**
 * @param phone following the E.164 format ex: +33612345678
 */
export function maskPhone(phone: string) {
    const phoneStr = formatPhoneNumber(phone, 'INTERNATIONAL');
    const phoneArr = phoneStr.split(' ');
    const maskedPhoneArr = phoneArr.map((phonePart, index) => {
        if (index <= 1) {
            return phonePart;
        }
        if (index === phoneArr.length - 1) {
            return phonePart;
        }
        return 'xx';
    });
    return maskedPhoneArr.join(' ');
}
