import { CSSProperties, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import merge from 'lodash/merge';

import { TextField, TextFieldProps } from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';

import PailheLabel, { PailheLabelProps } from '../PailheLabel/PailheLabel';

export type PailheTextFieldProps = TextFieldProps & {
    styleContainer?: CSSProperties;
    labelHelperText?: PailheLabelProps['helperText'];
    inputLabelProps?: TextFieldProps['InputLabelProps'];
};
export type PailheTextFieldControlledProps = PailheTextFieldProps & {
    name: string;
};
export default function PailheTextField({
    styleContainer,
    label,
    required,
    labelHelperText,
    disabled,
    sx,
    error: hasError,
    InputProps,
    fullWidth = true,
    ...otherProps
}: PailheTextFieldProps) {
    const [isActive, setIsActive] = useState(false);
    const handleInputFocus = () => setIsActive(true);
    return (
        <div
            id='pailheTextFieldContainer'
            style={{
                width: fullWidth ? '100%' : undefined,
                ...styleContainer,
            }}
        >
            <PailheLabel label={label} required={required} helperText={labelHelperText}>
                <TextField
                    onFocus={handleInputFocus}
                    variant='outlined'
                    disabled={disabled}
                    fullWidth={fullWidth}
                    {...otherProps}
                    sx={merge(
                        {
                            '& .MuiInputBase-root': {
                                borderRadius: '8px',
                                backgroundColor: disabled ? '#F6F6F6' : undefined,
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.10)',
                                },
                                '&:hover fieldset': {
                                    borderColor: isActive ? '#fc6317' : 'rgba(0, 0, 0, 0.10)',
                                },
                            },

                            '.MuiFormHelperText-root': {
                                color: hasError ? 'red' : undefined,
                                marginLeft: 0,
                                fontSize: '0.875rem',
                                whiteSpace: 'wrap',
                            },
                        },
                        sx,
                    )}
                    error={hasError}
                    InputProps={{
                        ...InputProps,
                        endAdornment: (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                }}
                            >
                                {InputProps?.endAdornment}
                                {hasError && <ErrorIcon style={{ color: 'red' }} />}
                            </div>
                        ),
                    }}
                />
            </PailheLabel>
        </div>
    );
}

export function PailheTextFieldControlled({
    name,
    label,
    ...otherProps
}: PailheTextFieldControlledProps) {
    const { t } = useTranslation();

    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                const error = errors[name]?.message as string | undefined;

                return (
                    <PailheTextField
                        label={label}
                        error={Boolean(errors[field.name])}
                        helperText={error ? t(error) : undefined}
                        inputRef={field.ref}
                        {...field}
                        {...otherProps}
                    />
                );
            }}
        />
    );
}
