import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '../common/utils';
import { User } from './types';

export default async function getCurrentUser(jwt: string = getJwt()): Promise<User> {
    const url = getAPIBaseUrl() + 'common/users.me';
    const response = await fetch(url, { headers: { Authorization: jwt } });
    await checkStatus(response, 200);
    const body = await response.json();
    const user = body;
    return user;
}
