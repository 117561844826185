import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { History, Location } from 'history';
import debounce from 'lodash/debounce';

type PrimaryType = number | string | boolean;
type AllowedType = PrimaryType | Array<PrimaryType> | null | undefined;

/**
 * A use State wrapper that sync a state with an url query parameter.
 * The state is initialized from the url and the url is updated on each state update.
 * @param queryKey is not supposed to change
 *
 * @example
 * const [status, setStatus] = useUrlState('status', 'pending') // store value in url with queryKey 'status'
 * //instead of
 * const [status, setStatus] = useState('pending')
 */
export function useUrlState<T extends AllowedType>(
    queryKey: string,
    defaultValue: T,
    hiddenValue = defaultValue,
) {
    const history = useHistory();
    const [state, internSetState] = useState<T>(() =>
        getFromUrl({ location: history.location, queryKey, defaultValue }),
    );

    const updateUrl = useCallback(
        (queryKey: string, value: T) => {
            const searchParams = new URLSearchParams(window.location.search);
            if (value === hiddenValue) {
                searchParams.delete(queryKey);
            } else {
                searchParams.set(queryKey, JSON.stringify(value));
            }
            history.push({ search: searchParams.toString() });
        },
        [history, hiddenValue],
    );

    const updateUrlDebounced = useMemo(() => debounce(updateUrl, 300), [updateUrl]);

    const setState = useCallback(
        (value: T) => {
            internSetState(value);
            updateUrlDebounced(queryKey, value);
        },
        [queryKey, updateUrlDebounced],
    );

    useEffect(() => {
        return history.listen((location) => {
            const value = getFromUrl({ location, queryKey, defaultValue });
            if (!isEqual(state, value)) {
                // need deep equal for array
                internSetState(value);
            }
        });
    }, [history, queryKey, state, defaultValue]);

    return [state, setState] as const;
}

function getFromUrl<T>({
    location,
    queryKey,
    defaultValue,
}: {
    location: Location;
    queryKey: string;
    defaultValue: T;
}): T {
    const str = new URLSearchParams(location.search).get(queryKey);
    const parsed = str ? (JSON.parse(str) as T) : undefined;
    return parsed !== undefined ? parsed : defaultValue;
}

export function useUrlValue<T>({
    get,
    set,
}: {
    /** ⚠️ Must be stable between render */
    get: (location: Location) => T | null;
    set?: (value: T | null) => Parameters<History['push']>;
}) {
    const history = useHistory();
    const [value, setValue] = useState<T | null>(get(history.location));

    useEffect(() => {
        return history.listen((location) => {
            setValue(get(location));
        });
    }, [history, get]);

    const setValueAndPushToHistory = useCallback(
        (newValue: T | null) => {
            setValue(newValue);
            if (set != null) {
                history.push(...set(newValue));
            }
        },
        [history, set],
    );

    return [value, setValueAndPushToHistory] as const;
}

function isEqual<T extends AllowedType>(a: T, b: T) {
    return JSON.stringify(a) === JSON.stringify(b);
}
