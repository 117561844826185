/**
 * a Set implementation that can store more complex types like object
 */
export class ComplexSet<T> {
    getIndex: (el: T) => string;
    private dict: Record<string, T> = {};

    /**
     *
     * @param elements
     * @param getIndex is Used to boost perf and determine if two element ara equal (same index)
     */
    constructor(
        elements: Iterable<T> = [],
        getIndex: (el: T) => string = (el) => JSON.stringify(el),
    ) {
        this.getIndex = getIndex;

        for (const el of elements) {
            this.add(el);
        }
    }

    add(el: T): void {
        const id = this.getIndex(el);
        if (this.dict[id] == null) {
            this.dict[id] = el;
        }
    }

    remove(el: T): void {
        const id = this.getIndex(el);
        if (this.dict[id] != null) {
            delete this.dict[id];
        }
    }

    clear(): void {
        this.dict = {};
    }

    has(el: T): boolean {
        const id = this.getIndex(el);
        return this.dict[id] != null;
    }

    values(): T[] {
        return Object.values(this.dict);
    }

    union(set: ComplexSet<T>): ComplexSet<T> {
        const nextSet = new ComplexSet(this.values(), this.getIndex);
        for (const el of set.values()) {
            nextSet.add(el);
        }
        return nextSet;
    }

    intersection(set: ComplexSet<T>): ComplexSet<T> {
        const nextSet = new ComplexSet([], this.getIndex);
        for (const el of this.values()) {
            if (set.has(el)) {
                nextSet.add(el);
            }
        }
        for (const el of set.values()) {
            if (this.has(el)) {
                nextSet.add(el);
            }
        }
        return nextSet;
    }
}
