import { getJwt } from '@libs/jwt';

import { getAPISubUrlForAdmin } from '../../common/utils';

export default function updateProductIsFeatured(productId, isFeatured) {
    const request = buildRequest(productId, isFeatured);
    const promise = fetch(request)
        .then((response) => {
            if (response.status !== 200) {
                throw new Error();
            }
            return response.json();
        })
        .then((body) => {
            const product = body;
            return product;
        });
    return promise;
}

function buildRequest(productId, isFeatured) {
    const url = getAPISubUrlForAdmin() + '/products.feature';

    const options = {
        method: 'Post',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: productId,
            isFeatured: isFeatured,
        }),
    };

    return new Request(url, options);
}
