import { UserType } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';
import { IsoDate, MongoId, ShortArray, Timestamp } from '@libs/types';

import { TCommissionSchedule } from '@api/advertiser/commissionsSchedule/list';
import { GroupRuleLevel, TRuleMode, TRulePayoutType } from '@api/advertiser/group/types';

import {
    buildQueryString,
    checkStatus,
    getAPISubUrlForAdvertiser,
    getAPISubUrlForPublisher,
} from '../../common/utils';

export type RequestAdvertiser = {
    orderBy?: 'createdAt' | 'name';
    sort?: 'asc' | 'desc';
    program?: MongoId;
    groups?: ShortArray<MongoId>;
    name?: string;
    affiliateProfiles?: ShortArray<MongoId>;
    status?: ShortArray<Exclude<PartnershipStatus, 'active'>>;
    type?: ShortArray<string>;
    active?: boolean;
    hasCustomRules?: boolean;
    search?: string;
    withDetails?: boolean;
};

export type RequestPublisher = {
    orderBy?: 'id';
    sort?: 'asc' | 'desc';
    affiliateProfile?: MongoId;
    programs?: ShortArray<MongoId>;
    name?: string;
    withProgramsDetails?: boolean;
    status?: ShortArray<Exclude<PartnershipStatus, 'active'>>;
    rates?: ShortArray<'cpc' | 'cpa' | 'cpl'>;
    search?: string;
    withDetails?: boolean;
};

export type PartnershipAdvertiser = PartnershipCommon & {
    affiliateProfile: PartnershipCommon['affiliateProfile'] & {
        slug: string; // advertiser only?
        manager: MongoId | undefined; // advertiser only
        categories: string[];
    };
    customRules: { [key: string]: RuleAdvertiser }; // advertiser only?
    publisherSubids: { [key: string]: string };
};

export type PartnershipPublisher = PartnershipCommon & {
    program: PartnershipCommon['program'] & {
        manager: MongoId;
        allowPaypal: boolean | null;
        slug: string;
        url: string;
        hasAm: boolean;
    };
    final_rules: Record<string, RulePublisher>; // publisher only?
    commissionsRateSchedule?: TCommissionSchedule[];
    publisherSubids: PublisherSubids;
};

export type PartnershipCommon = {
    id: string;
    advertiser: string;
    publisher: string;
    hasCustomRules: boolean;
    minimumAmount: number;
    trackingId: number;
    weight: number;
    urlType?: UrlType;
    createdAt: Timestamp;
    status_accepted_at: IsoDate;
    status_updated_at?: IsoDate;
    status: PartnershipStatus;
    group: {
        id: string;
        name: string;
        currencies: string[];
    };
    program: {
        id: string;
        name: string;
        currency: string;
    };
    affiliateProfile: {
        id: string;
        name: string;
    };
    urlParameters: {
        key: string;
        value: string;
    }[];
    stats: {
        clicks: { total: number };
        commissions: Record<
            string,
            {
                aff: {
                    total: number;
                    total_amount: number;
                };
            }
        >;
        conversions: Record<
            string,
            {
                shared: number;
                total: number;
            }
        >;
        impressions: number;
        revenue: Record<string, number>;
    };
    requestOrigin?: RequestOrigin | null;
    // advertiser only?
    customCpc?: unknown;
    customSessionTimeout?: number;

    // wip this not cover all sanitizer
};

export type RequestOrigin = 'advertiser' | 'publisher' | 'testing' | 'products_search';

export type UrlType = 'sharp' | 'parameter' | 'redirect';

export const partnershipStatuses = [
    'pending',
    'approved', // @deprecated to not use, use 'active' instead, I think it is never returned by API
    'cancelled_advertiser',
    'cancelled_publisher',
    'blocked',
    'inactive',
    'active',
    'interested',
] as const;
export type PartnershipStatus = (typeof partnershipStatuses)[number];

export interface RulePublisher {
    id: MongoId;
    name: string;
    currency: string;
    mode: TRuleMode;
    type: TRulePayoutType;
    delayBeforePaymentDemand: number;
    trackingLifetime: number;
    levels: Record<string, GroupRuleLevel[]>;
}
export interface RuleAdvertiser {
    id: string;
    name: string;
    mode: TRuleMode;
    payoutType: TRulePayoutType;
    levels: Record<string, GroupRuleLevel[]>;
}

interface PublisherSubids {
    one: string;
    two: string;
    three: string;
    four: string;
    five: string;
}

export default async function apiList<T extends Exclude<UserType, 'admin'>>(
    userType: T,
    idOrPayload: string | (T extends 'advertiser' ? RequestAdvertiser : RequestPublisher),
): Promise<(T extends 'advertiser' ? PartnershipAdvertiser : PartnershipPublisher)[]> {
    let payload: T extends 'advertiser' ? RequestAdvertiser : RequestPublisher;
    if (typeof idOrPayload === 'string') {
        const key = userType === 'advertiser' ? 'program' : 'affiliateProfile';
        payload = { [key]: idOrPayload };
    } else {
        payload = idOrPayload;
    }

    const url =
        (userType === 'advertiser' ? getAPISubUrlForAdvertiser() : getAPISubUrlForPublisher()) +
        `/partnerships.list${buildQueryString(payload)}`;
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response, 200);
    const partnerships = (await response.json()).partnerships.data as T extends 'advertiser'
        ? PartnershipAdvertiser[]
        : PartnershipPublisher[];
    // temporary filtered results for products search feature (only interested partnerships)
    const filteredPartnerships = partnerships.filter(
        (partnership) => partnership.status !== 'interested',
    );
    return filteredPartnerships;
}
