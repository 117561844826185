import { createTheme } from '@mui/material';

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#ff5c00',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#0083b4',
            contrastText: '#ffffff',
        },
        info: {
            main: '#0096cb',
            contrastText: '#ffffff',
        },
        admin: {
            main: '#ffff9d',
            dark: '#cbcc6d',
            light: '#ffffcf',
        },
        quiet: {
            main: '#8d8d8d',
            contrastText: '#ffffff',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 3px 32px 0px rgba(0, 0, 0, 0.05)',
                    borderRadius: '1rem',
                },
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 3px 32px 0px rgba(0, 0, 0, 0.05)',
                    borderRadius: '1rem',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '1.25rem',
                    '.MuiDialogActions-root': {
                        // using a class name as a hack to use sx syntax (in particular different value based on screen breakpoint)
                        // because otherwise it don't work yet at theme level
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    const variant = ownerState.variant ?? 'text';
                    const isLink = ownerState.href != null || ownerState.to != null;

                    const quietBackgroundColor = (function () {
                        if (ownerState.color !== 'quiet') return undefined;
                        if (variant === 'text') {
                            return '#8d8d8d30';
                        } else if (variant === 'contained') {
                            return '#666161';
                        }
                        return undefined;
                    })();

                    const explicitCurrentColor = (function () {
                        const color = ownerState.color;
                        if (color == null) return 'primary';
                        return color === 'inherit' ? 'primary' : color;
                    })();

                    const color =
                        theme.palette[explicitCurrentColor][
                            variant === 'contained' ? 'contrastText' : 'main'
                        ];

                    return {
                        padding: '0.5rem 2rem',
                        textTransform: 'none',
                        borderRadius: '10rem',
                        minWidth: '6rem',
                        fontWeight: 600,
                        boxShadow: 'none',

                        ...(isLink && {
                            textDecoration: 'none',
                            color,
                        }),

                        '&:hover': {
                            boxShadow: 'none',
                            ...(quietBackgroundColor !== undefined && {
                                backgroundColor: quietBackgroundColor,
                            }),
                            ...(isLink && {
                                color,
                            }),
                        },
                    };
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 3px 32px 0px rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderRadius: '0',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    borderRadius: '4px',
                },
                paper: {
                    borderRadius: '4px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: '#fe6e00',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
    },
    typography: {
        fontFamily: `"Lato", "Roboto", sans-serif`,
    },
});
