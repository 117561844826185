import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Autocomplete, AutocompleteProps, AutocompleteValue, ChipTypeMap } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { bound, mergeProps } from '@libs/bound';
import { MongoId } from '@libs/types';

import PailheTextField, { PailheTextFieldProps } from '../PailheTextField';

export type PailheAutoCompleteProps<
    Value,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType,
> = Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'renderInput'
> & {
    TextFieldProps?: PailheTextFieldProps;
    onlySelect?: boolean;
    customOptions?:
        | string[]
        | {
              label: string;
              value:
                  | { categoryMain: MongoId }
                  | { categoryMain: MongoId; categorySub1: MongoId }
                  | { categoryMain: MongoId; categorySub1: MongoId; categorySub2: MongoId };
          }[];
};

export function PailheAutoComplete<
    Value,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
    TextFieldProps,
    onlySelect = false,
    ...otherProps
}: PailheAutoCompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
    return (
        <Autocomplete
            popupIcon={<ExpandMoreIcon sx={{ color: '#333333' }} />}
            renderInput={(params) => {
                return (
                    <PailheTextField
                        {...mergeProps(params, TextFieldProps)}
                        onKeyDown={(e) => {
                            if (onlySelect) {
                                e.preventDefault();
                            }
                        }}
                    />
                );
            }}
            disableClearable={onlySelect ? true : otherProps.disableClearable}
            {...otherProps}
        />
    );
}

export type BaseOption<Id = string> = {
    id: Id;
    label: ReactNode;
};

export type PailheAutoCompleteHandyProps<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType,
    Id = string,
    Option extends BaseOption<Id> = BaseOption<Id>,
> = Omit<
    PailheAutoCompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'value' | 'onChange'
> & {
    value: AutocompleteValue<Id, Multiple, DisableClearable, FreeSolo>;
    onChange?: (id: AutocompleteValue<Id, Multiple, DisableClearable, FreeSolo>) => void;
};

/**
 * An autocomplete that is more handy to use that the classic one.
 * it does:
 * - handle the value as an id in props value and onChange
 * - translate the label, the helperText and option labels
 */
export function PailheAutoCompleteHandy<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
    Id = string,
    Option extends BaseOption<Id> = BaseOption<Id>,
>(
    props: PailheAutoCompleteHandyProps<
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent,
        Id,
        Option
    >,
) {
    const { value, onChange, options, TextFieldProps, multiple } = props;
    const translateNode = useTranslateNode();

    const translatedOptions = options.map((option) => ({
        ...option,
        label: option.label,
    }));

    const selectedOption = multiple
        ? translatedOptions.filter((option) => value.includes(option.id))
        : (translatedOptions.find((option) => option.id === value) ?? null);

    return (
        <PailheAutoComplete
            {...props}
            TextFieldProps={{
                ...TextFieldProps,
                label: translateNode(TextFieldProps?.label),
                helperText: translateNode(TextFieldProps?.helperText),
            }}
            options={translatedOptions}
            value={selectedOption}
            onChange={(event, newValue) => {
                onChange(!multiple ? newValue?.id : newValue?.map((option) => option.id));
            }}
        />
    );
}

export const PailheAutoCompleteHandyControlled =
    createAutoCompleteControlled(PailheAutoCompleteHandy);

function createAutoCompleteControlled<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
    Id = string,
    Option extends BaseOption<Id> = BaseOption<Id>,
    Props extends Partial<
        PailheAutoCompleteHandyProps<
            Multiple,
            DisableClearable,
            FreeSolo,
            ChipComponent,
            Id,
            Option
        >
    > = PailheAutoCompleteHandyProps<
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent,
        Id,
        Option
    >,
>(AutoComplete: (props: Props) => ReactNode) {
    return function AutoCompleteControlled(props: Omit<Props, 'value'> & { name: string }) {
        const { name, TextFieldProps, onChange: onChangeFromProp } = props;
        const {
            control,
            formState: { errors },
        } = useFormContext();
        const translateNode = useTranslateNode();
        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    const { onChange: onChangeFromHookForm, value } = field;

                    const error = TextFieldProps?.error ?? Boolean(errors[name]);
                    const helperText =
                        TextFieldProps?.helperText ?? translateNode(errors[name]?.message);

                    return (
                        <AutoComplete
                            {...props}
                            ref={field.ref}
                            TextFieldProps={{
                                ...TextFieldProps,
                                // applying following props conditionally even if they are undefined
                                // to avoid to override them if defined by a bound component
                                ...(error && { error }),
                                ...(helperText && { helperText }),
                            }}
                            value={value}
                            onChange={(id) => {
                                onChangeFromHookForm(id);
                                onChangeFromProp?.(id);
                            }}
                        />
                    );
                }}
            />
        );
    };
}

function useTranslateNode() {
    const { t } = useTranslation();
    return (node: ReactNode | undefined) => (typeof node === 'string' ? (t(node) as string) : node);
}

export function boundSelect<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
    Id = string,
    Option extends BaseOption<Id> = BaseOption<Id>,
>(
    useProps: (
        props: PailheAutoCompleteHandyProps<
            Multiple,
            DisableClearable,
            FreeSolo,
            ChipComponent,
            Id,
            Option
        >,
    ) => Partial<
        PailheAutoCompleteHandyProps<
            Multiple,
            DisableClearable,
            FreeSolo,
            ChipComponent,
            Id,
            Option
        >
    >,
) {
    const Select = bound(PailheAutoCompleteHandy, useProps);
    const SelectControlled = createAutoCompleteControlled(Select);
    return [Select, SelectControlled];
}
