import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '@api/common/utils';

export async function sendConfirmEmail(): Promise<void> {
    const response = await fetch(getAPIBaseUrl() + 'common/users/send-confirm-email', {
        headers: { Authorization: getJwt() },
    });
    await checkStatus(response);
}
