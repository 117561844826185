/**
 * @deprecated Prefer LoadingStatus from '@libs/LoadingStatus'
 */
export default class LoadingStatus {
    constructor({ isLoading = false, isComplete = false, isError = false, msg = null } = {}) {
        this.isLoading = isLoading;
        this.isComplete = isComplete;
        this.isError = isError;
        this.msg = msg;
    }

    isNotLaunched() {
        return this.isLoading === false && this.isComplete === false;
    }
}
