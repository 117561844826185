import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type Direction = 'up' | 'down' | 'between' | 'only' | 'not';

interface UseBreakpointProps {
    breakpoint: Breakpoint | [Breakpoint, Breakpoint];
    direction: Direction;
}

const useBreakpoint = ({ breakpoint, direction }: UseBreakpointProps) => {
    const theme = useTheme();
    let query: string;

    switch (direction) {
        case 'up':
            query = theme.breakpoints.up(breakpoint as Breakpoint);
            break;
        case 'down':
            query = theme.breakpoints.down(breakpoint as Breakpoint);
            break;
        case 'between':
            if (Array.isArray(breakpoint) && breakpoint.length === 2) {
                query = theme.breakpoints.between(breakpoint[0], breakpoint[1]);
            } else {
                throw new Error("Breakpoint must be an array of two values for 'between'.");
            }
            break;
        case 'only':
            query = theme.breakpoints.only(breakpoint as Breakpoint);
            break;
        case 'not':
            query = theme.breakpoints.up(breakpoint as Breakpoint);
            query = `not ${query}`;
            break;
        default:
            throw new Error(`Unsupported direction: ${direction}`);
    }

    const isMatched = useMediaQuery(query);
    return isMatched;
};

export default useBreakpoint;
