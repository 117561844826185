import { getJwt } from '@libs/jwt';

import { getAPISubUrlForAdvertiser, getAPISubUrlForPublisher } from '@api/common/utils';

export const FETCH_PUBLISHER_PROGRAM = 'FETCH_PUBLISHER_PROGRAM';
export const FETCH_PUBLISHER_PROGRAM_SUCCESS = 'FETCH_PUBLISHER_PROGRAM_SUCCESS';
export const FETCH_PUBLISHER_PROGRAM_FAILURE = 'FETCH_PUBLISHER_PROGRAM_FAILURE';

export const FETCH_PARTNERSHIPS = 'FETCH_PARTNERSHIPS';
export const FETCH_PARTNERSHIPS_SUCCESS = 'FETCH_PARTNERSHIPS_SUCCESS';
export const FETCH_PARTNERSHIPS_FAILURE = 'FETCH_PARTNERSHIPS_FAILURE';

export function fetchPartnerships(programId) {
    const promise = fetch(getAPISubUrlForAdvertiser() + '/partnerships.list?program=' + programId, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + getJwt() },
    });

    return {
        type: FETCH_PARTNERSHIPS,
        payload: promise,
    };
}

export function fetchPartnershipsSuccess(partnerships) {
    return {
        type: FETCH_PARTNERSHIPS_SUCCESS,
        payload: partnerships,
    };
}

export function fetchPartnershipsFailure(error) {
    return {
        type: FETCH_PARTNERSHIPS_FAILURE,
        payload: error,
    };
}

export function fetchPublisherProgramThunk(programId, affiliateProfileId) {
    return (dispatch) => {
        let statusCode;

        const promise = fetch(
            getAPISubUrlForPublisher() +
                '/programs.info?affiliateProfile=' +
                affiliateProfileId +
                '&program=' +
                programId,
            {
                method: 'GET',
                headers: { Authorization: 'Bearer ' + getJwt() }, // must be publisher jwt
            },
        );

        dispatch({
            type: FETCH_PUBLISHER_PROGRAM,
            payload: promise,
        })
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (statusCode !== 200) {
                    dispatch({
                        type: FETCH_PUBLISHER_PROGRAM_FAILURE,
                        payload: statusCode,
                    });
                } else {
                    dispatch({
                        type: FETCH_PUBLISHER_PROGRAM_SUCCESS,
                        payload: result.program.data,
                    });
                }
            });
    };
}

export const fetchPartnershipsThunk = (programId) => {
    return async (dispatch) => {
        dispatch(fetchPartnerships(programId))
            .then(function (response) {
                return response.payload.json();
            })
            .then(function (result) {
                if (result.statusCode !== 200) {
                    dispatch(fetchPartnershipsFailure());
                } else {
                    dispatch(fetchPartnershipsSuccess(result.partnerships.data));
                }
            });
    };
};
