import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { ErrorView } from '@components/generic/ErrorView';
import { LoadingView } from '@components/generic/LoadingView';

import { useOldAppState } from '@hooks/useOldAppState';

import { ParamTypesPublisher } from '@libs/getSharedVar';
import { LoadingStatus } from '@libs/LoadingStatus';

import fetchAds from '@api/common/fetchAds';
import fetchPartnerships from '@api/common/partnerships/list';
import fetchProfileInfo from '@api/publisher/fetchProfileInfo';
import { listCurrencies as listCurrenciesPublisher } from '@api/publisher/listCurrencies';

export function AffiliateProvider({ children }: { children: JSX.Element }) {
    const { app } = useOldAppState();
    const { params } = useRouteMatch<ParamTypesPublisher>();
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('idle');
    const currentAffiliateProfileId = params.affiliateProfileId;
    const currentAffiliateProfileIdPreviousRef = useRef(null);

    useEffect(() => {
        if (
            currentAffiliateProfileId == null ||
            currentAffiliateProfileId === currentAffiliateProfileIdPreviousRef.current
        ) {
            return;
        }

        currentAffiliateProfileIdPreviousRef.current = currentAffiliateProfileId;

        (async function () {
            setLoadingStatus('pending');
            try {
                await Promise.all([
                    handleFetchProfileInfo(),
                    handleFetchProfileCurrencies(),
                    handleFetchCurrentAffiliateProfilePartnerships(),
                    handleFetchCurrentAffiliateProfileAds(),
                ]);
                setLoadingStatus('success');
            } catch (error) {
                setLoadingStatus('failure');
            }
        })();
    }, [currentAffiliateProfileId]);

    if (
        currentAffiliateProfileIdPreviousRef.current != null &&
        currentAffiliateProfileId !== currentAffiliateProfileIdPreviousRef.current
    ) {
        // when we detect that the user want to consult a
        // page related to a different affiliate profile that the first he consulted, we need to
        // reload the application because our pages don't support
        // currentAffiliateProfile change on the fly without full app reloading
        window.location.reload();
        return null;
    }

    if (loadingStatus === 'idle' || loadingStatus === 'pending') {
        return <LoadingView />;
    } else if (loadingStatus === 'failure') {
        return <ErrorView />;
    } else {
        return children;
    }

    async function handleFetchProfileInfo() {
        try {
            const currentAffiliateProfile = await fetchProfileInfo(currentAffiliateProfileId);
            app.setState({ currentAffiliateProfile });
        } catch (error) {
            window.addFlash('error', 'Fail to affiliate profile');
            console.error(error);
            throw error;
            // todo: report to sentry
        }
    }

    async function handleFetchProfileCurrencies() {
        try {
            const currencies = await listCurrenciesPublisher(currentAffiliateProfileId);
            app.setState({ currentSubEntitiesCurrencies: currencies });
        } catch (error) {
            window.addFlash('error', 'Fail to fetch currencies associated to profile');
            console.error(error);
            throw error;
            // todo: report to sentry
        }
    }

    async function handleFetchCurrentAffiliateProfilePartnerships() {
        try {
            const currentAffiliateProfilePartnerships = await fetchPartnerships('publisher', {
                affiliateProfile: currentAffiliateProfileId,
                withDetails: true,
            });
            app.setState({
                currentPartnerships: currentAffiliateProfilePartnerships,
            });
        } catch (error) {
            window.addFlash('error', 'Fail to fetch partnerships');
            console.error(error);
            throw error;
            // todo: report to sentry
        }
    }

    async function handleFetchCurrentAffiliateProfileAds() {
        try {
            const currentAds = await fetchAds({ affiliateProfile: currentAffiliateProfileId });
            app.setState({ currentAds });
        } catch (error) {
            window.addFlash('error', 'Fail to fetch ads');
            console.error(error);
            throw error;
            // todo: report to sentry
        }
    }
}
