import { getJwt } from '@libs/jwt';
import { MongoId, Timestamp } from '@libs/types';

import { User } from '@api/user/types';

import { checkStatus, getAPISubUrlForCurrentUser } from '../utils';
import { Message } from './fetchMessages';

export type Thread = {
    id: MongoId;
    createdAt: Timestamp;
    advertiser: MongoId;
    program: {
        id: MongoId;
        title: string;
        slug: string;
    };
    programUser: User;
    publisher: MongoId;
    affiliateProfile: {
        id: MongoId;
        title: string;
        slug: string;
    };
    affiliateProfileUser: User;
    lastMessage: Timestamp | null;
    readAtAdvertiser: Timestamp | null;
    readAtPublisher: Timestamp | null;
    messages: Message[] | null;
};

export async function fetchThreads(unread: boolean) {
    const res = await fetch(
        getAPISubUrlForCurrentUser() + `/messages/threads.list?unread=${unread}`,
        {
            method: 'GET',
            headers: { Authorization: getJwt() },
        },
    );
    await checkStatus(res);
    return (await res.json()).threads.data as Thread[];
}

export function markThreadAsUnread(ThreadId) {
    const request = buildRequestUnreadThread(ThreadId);
    const promise = fetch(request)
        .then((response) => {
            if (response.status !== 200) {
                throw new Error(response.status + ' ' + response.statusText);
            }
            return response.json();
        })
        .then((body) => {
            return body.thread.data;
        });
    return Promise.resolve(promise);
}
export function downloadThread(ThreadId) {
    const request = buildRequestDownloadThread(ThreadId);
    const promise = fetch(request)
        .then((response) => {
            if (response.status !== 200) {
                throw new Error(response.status + ' ' + response.statusText);
            }
            return response;
        })
        .then((body) => {
            return body.text();
        });
    return promise;
}
function buildRequestDownloadThread(ThreadId) {
    const route = '/messages/threads.download?threadId=' + ThreadId;
    const url = getAPISubUrlForCurrentUser() + route;
    const option = {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
    };
    return new Request(url, option);
}
function buildRequestUnreadThread(ThreadId) {
    const route = '/messages/threads.unread';
    const url = getAPISubUrlForCurrentUser() + route;
    const option = {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ threadId: ThreadId }),
    };
    return new Request(url, option);
}
