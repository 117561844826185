import { checkStatus, getAPIBaseUrl } from '@api/common/utils';

import { User } from './types';

export default async function confirmEmail(confirmEmailToken: string): Promise<User> {
    const response = await fetch(getAPIBaseUrl() + 'common/users/confirm-email', {
        headers: { Authorization: confirmEmailToken },
    });
    await checkStatus(response);
    return (await response.json()) as User;
}
