import { connect } from 'react-redux';
import {
    closeAllDialogs,
    listPaymentMethods,
    updateAdvertiserPaymentMethod,
    updateAdvertiserPaymentMethodFailure,
    updateAdvertiserPaymentMethodLoading,
    updateAdvertiserPaymentMethodSuccess,
} from '@actions/advertiserBilling';
import { addFlash } from '@actions/common';
import UpdatePaymentMethodDialog from '@pages/advertiser/BillingPage/SubscriptionTab/UpdatePaymentMethodDialog';

import { AppDispatch, RootState } from '@libs/reduxHooks';

function mapStateToProps(globalState: RootState) {
    return {
        advertiser: globalState.advertiser.currentAdvertiser,
        isOpen: globalState.advertiserBillingReducer.updatePaymentMethodDialog.isOpen,
        isLoading: globalState.advertiserBillingReducer.updatePaymentMethodDialog.loading,
        paymentMethods: globalState.advertiserBillingReducer.paymentMethods,
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        handleClose: () => {
            dispatch(closeAllDialogs());
        },
        updatePaymentMethod: (plan) => {
            let statusCode;
            dispatch(updateAdvertiserPaymentMethodLoading());
            dispatch(updateAdvertiserPaymentMethod(plan))
                .then(function (response) {
                    statusCode = response.payload.status;
                    return response.payload.json();
                })
                .then(function (result) {
                    if (!result.data || statusCode !== 200) {
                        dispatch(addFlash('error', result.message));
                        dispatch(updateAdvertiserPaymentMethodFailure(statusCode));
                    } else {
                        dispatch(updateAdvertiserPaymentMethodSuccess(result.data.paymentMethods));
                        dispatch(closeAllDialogs());
                        dispatch(listPaymentMethods()); // for updating display of payment method in subscription tab
                        dispatch(
                            addFlash('success', _t('billing_subscription_card_update_success')),
                        );
                    }
                });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentMethodDialog);
