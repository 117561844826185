import { getJwt } from '@libs/jwt';
import { IsoDate, MongoId, Timestamp } from '@libs/types';

import { User } from '@api/user/types';

import { checkStatus, getAPISubUrlForAdvertiser } from '../common/utils';
import { ProgramInfo } from './fetchProgramInfo';

export default async function fetchCurrentAdvertiserData() {
    const url = getAPISubUrlForAdvertiser() + '/advertisers.me';
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response, 200);
    const body = await response.json();
    const currentAdvertiser = body.advertiser.data as Advertiser;
    const advertiserPrograms = body.programs.data as ProgramInfo[];
    const advertiserUsers = body.users.data as User[];
    return { currentAdvertiser, advertiserPrograms, advertiserUsers };
}

export type BillingPeriod = 'monthly' | 'yearly';

export type Advertiser<IsMe extends boolean = true> = {
    id: MongoId;
    description: string;
    type: AdvertiserType;
    company: string;
    companyId: string;
    address: string;
    zipcode: string;
    city: string;
    state: string;
    country: string;
    phone: string;
    billingUsers: MongoId[];
    programCount: number;
    createdAt: Timestamp;

    affiliatesBilling: {
        type: AdvertiserType;
        company: string;
        companyId: string;
        address: string;
        zipcode: string;
        city: string;
        country: string;
        phone: string;
        billingUsers: MongoId[];

        companyVat: IsMe extends true ? string : undefined;
        isVatFree: IsMe extends true ? boolean : undefined;
    } | null;

    logo?: MongoId;

    /** @deprecated */
    title: string;
    /** @deprecated */
    css: unknown;
    /** @deprecated */
    subdomain: string;
} & (IsMe extends true
    ? {
          admin: MongoId;
          companyVat: string;
          isVatFree: boolean;
          plan: AdvertiserPlan;
          billingPeriod: BillingPeriod;
          /** /year if billing period is yearly else /month */
          price: number;
          currency: string;
          stripeId: string;
          hasVat: boolean;
          vat: number;
          isFree?: boolean;
          isCancel?: boolean;
          isFreeze?: boolean;
          quotas: Quota;
          /** @deprecated use dedicated route for quota: /advertiser/subscriptions/quotas.info */
          quotaUsage: {
              // full year (4 digits)
              [year: string]: {
                  // 2 digits zero padded. ex: 01, 06, 10
                  [month: string]: Quota;
              };
          };
          /** @deprecated */
          hasSsl: boolean;
          /** @deprecated */
          hasSupport: boolean;
          /** @deprecated */
          hasCnameApp: boolean;
          /** @deprecated */
          hasCnameTracking: boolean;
          paymentMethod: 'stripe' | 'paypal' | 'bankwire';
          subscribedTo: Timestamp | null;
          trialEnds: Timestamp;
          trackingIdIncrement: number;
          /** @deprecated */
          cname: null;
          trackingCname: null;
          subscriptions?: {
              stripeId: string;
              status:
                  | 'incomplete'
                  | 'incomplete_expired'
                  | 'trialing'
                  | 'active'
                  | 'past_due'
                  | 'canceled'
                  | 'unpaid';
              taxPercent: number;
              discount?: {
                  name: string;
                  amountOff?: number; //amount or percent, not both, not none
                  percentOff?: number;
              };
              startedAt: IsoDate;
              endedAt: IsoDate | null;
              currentPeriodStart: IsoDate | null;
              currentPeriodEnd: IsoDate | null;
              canceledAt: IsoDate | null;
              plans: {
                  stripeId: string;
                  name: string;
                  /** in cents */
                  amount: number;
                  interval: 'day' | 'week' | 'month' | 'year';
              }[];
          }[];
          status:
              | 'free'
              | 'cancelled'
              | 'frozen'
              | 'subscribed'
              | 'freetrial'
              | 'freetrialEnded'
              | 'unpaid'
              | 'subscribedEnded';
          isAffilaeRenewed: boolean;
          isProductsSearchAllowed: boolean;
      }
    : {});

type Quota = {
    impressions: number;
    clicks: number;
    programs: number;
    conversion: number;
    /** @deprecated */
    users: number;
};

// todo
export type AdvertiserType = 'company' | 'association' | '';
export type AdvertiserPlan = AdvertiserPlanAvailable | AdvertiserPlanOld;

/** plan that new user can subscribe */
export const latestPlans = ['track', 'engage', 'scale'] as const;
export const deprecatedPlans = [
    'starter',
    'pro',
    'premium',
    'business',
    'network',
    'free',
    'on-demand',
] as const;
export const plans = [...latestPlans, ...deprecatedPlans];
export type AdvertiserPlanAvailable = (typeof latestPlans)[number];
export type AdvertiserPlanOld = (typeof deprecatedPlans)[number];
