import { getUserType } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { checkStatus, getAPISubUrlForCurrentUser } from '../utils';

export async function addMessageFile(sender: MongoId, file: IFile) {
    const url = getAPISubUrlForCurrentUser() + '/messages/media.add';
    const form = getFormData(sender, file);
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
        },
        body: form,
    });
    await checkStatus(response, 201);
    const data = await response.json();
    return data.file.data;
}

function getFormData(sender, file) {
    const form = new FormData();
    form.append('file', file);

    const userType = getUserType();
    switch (userType) {
        case 'advertiser':
            form.append('programId', sender);
            break;

        case 'publisher':
            form.append('affiliateProfileId', sender);
            break;
    }

    return form;
}

interface IFile {
    data: {
        contentType: string;
        filename: string;
        s3FilePath: string;
        uploadId: MongoId;
    };
}
