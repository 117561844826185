import { CountryCode } from 'libphonenumber-js';

import { checkStatus, getAPIBaseUrl } from './utils';

/**
 * Use Hapigator to detect current user country.
 * Return a country code conform to iso 3166
 * (https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes)
 */
export async function detectCountry(): Promise<CountryCode> {
    const url = getAPIBaseUrl() + 'common/countries.info';
    const response = await fetch(url);
    await checkStatus(response);
    const body = await response.json();
    const countryCode = body?.country?.code;
    if (countryCode == null) throw new Error('Receive null value');
    return countryCode;
}
