import _ from 'lodash';

import { getJwt } from '@libs/jwt';

import { getAPISubUrlForAdmin } from '../../common/utils';

export class FilterData {
    constructor() {
        this.validityStatuses = [];
        this.programIds = [];
        this.searchText = '';
        this.isFeatured = null;
    }
}

/**
 *
 * @param {FilterData} filterData
 */
export default function fetchProduct(filterData = new FilterData()) {
    const request = buildProductRequest(filterData);
    const promise = fetch(request)
        .then((response) => {
            if (response.status !== 200) {
                throw new Error();
            }
            return response.json();
        })
        .then((body) => {
            const products = body.data;
            return products;
        });
    return promise;
}

/**
 *
 * @param {FilterData} filterData
 */
function buildProductRequest(filterData) {
    const queryParams = buildQueryParams(filterData);
    const queryString = buildQueryString(queryParams);
    const url = getAPISubUrlForAdmin() + '/products.list' + queryString;

    const option = {
        method: 'GET',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
    };
    return new Request(url, option);
}
function buildQueryString(queryCriteria) {
    const queries = [];
    if (!_.isEmpty(queryCriteria.programs)) {
        queryCriteria.programs.map((programId) => {
            queries.push('programs=' + programId);
        });
    }
    if (!_.isEmpty(queryCriteria.status_admin)) {
        queryCriteria.status_admin.map((status) => {
            queries.push('status_admin=' + status);
        });
    }
    if (!_.isEmpty(queryCriteria.search)) {
        queries.push('search=' + queryCriteria.search);
    }
    if (_.has(queryCriteria, 'isFeatured')) {
        queries.push('isFeatured=' + queryCriteria.isFeatured);
    }
    if (queries.length > 0) {
        return '?' + queries.join('&');
    } else {
        return '';
    }
}

/**
 *
 * @param {FilterData} filterData
 */
function buildQueryParams(filterData) {
    const params = {};
    if (!_.isEmpty(filterData.validityStatuses)) {
        params.status_admin = filterData.validityStatuses;
    }
    if (!_.isEmpty(filterData.programIds)) {
        params.programs = filterData.programIds;
    }
    if (!_.isEmpty(filterData.searchText)) {
        params.search = filterData.searchText;
    }
    if (filterData.isFeatured != null) {
        params.isFeatured = filterData.isFeatured;
    }
    return params;
}
