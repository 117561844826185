import {
    CLOSE_ALL_DIALOGS,
    CREATE_PUBLISHER_REVIEW_FAILURE,
    CREATE_PUBLISHER_REVIEW_SUCCESS,
    FETCH_PUBLISHER_PRODUCT_REQUESTS_FAILURE,
    FETCH_PUBLISHER_PRODUCT_REQUESTS_SUCCESS,
    OPEN_PUBLISHER_ADD_REVIEW_DIALOG,
    OPEN_PUBLISHER_EDIT_REVIEW_DIALOG,
    PUBLISHER_PRODUCTS_LOADING,
    UPDATE_PUBLISHER_REVIEW_FAILURE,
    UPDATE_PUBLISHER_REVIEW_SUCCESS,
} from '@actions/publisherProducts';

const productRequestsEmptyQueryCriteria = {
    affiliateProfiles: null,
    status: [],
    program: null,
    product: null,
    search: '',
};

const reviewFormDialogEmptyFields = {
    id: null,
    url: '',
    message: '',
};

const INITIAL_STATE = {
    loading: false,
    error: null,
    productRequests: {
        data: null,
        queryCriteria: productRequestsEmptyQueryCriteria,
    },
    reviewFormDialog: {
        isOpen: false,
        action: 'create', // can be create or update
        fields: reviewFormDialogEmptyFields,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case PUBLISHER_PRODUCTS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PUBLISHER_PRODUCT_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                productRequests: {
                    ...state.productRequests,
                    data: action.payload,
                },
            };
        case FETCH_PUBLISHER_PRODUCT_REQUESTS_FAILURE:
        case CREATE_PUBLISHER_REVIEW_FAILURE:
        case UPDATE_PUBLISHER_REVIEW_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case CREATE_PUBLISHER_REVIEW_SUCCESS:
        case UPDATE_PUBLISHER_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                productRequests: {
                    ...state.productRequests,
                    data: action.payload,
                },
            };
        case OPEN_PUBLISHER_ADD_REVIEW_DIALOG:
            return {
                ...state,
                reviewFormDialog: {
                    ...state.reviewFormDialog,
                    isOpen: true,
                    action: 'create',
                    fields: {
                        id: action.review ? action.review.id : null,
                        url: '',
                        message: '',
                    },
                },
            };

        case OPEN_PUBLISHER_EDIT_REVIEW_DIALOG: {
            const initialReview = action.review;
            return {
                ...state,
                reviewFormDialog: {
                    ...state.reviewFormDialog,
                    isOpen: true,
                    action: 'update',
                    fields: {
                        id: initialReview.id,
                        url: initialReview.url,
                        message: '',
                    },
                },
            };
        }
        case CLOSE_ALL_DIALOGS:
            return {
                ...state,
                reviewFormDialog: {
                    ...state.reviewFormDialog,
                    isOpen: false,
                    fields: reviewFormDialogEmptyFields,
                },
            };
        default:
            break;
    }

    return state;
}
