import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import { getUserTypeFromTokens, removeAllJwt } from '@libs/jwt';

import illustration from './choice.svg';
import styles from './eventsPagesStyle';

export default function AlreadyLoggedPage() {
    const { t, i18n } = useTranslation();
    const userType = getUserTypeFromTokens();

    return (
        <div style={styles.page}>
            <Helmet>
                <title>{t('AlreadyLoggedPage_title')}</title>
            </Helmet>
            <img src={illustration} style={styles.illustration} />
            <span style={styles.title}>{t('AlreadyLoggedPage_title')}</span>
            <span style={styles.subtitle}>{t('AlreadyLoggedPage_subtitle')}</span>
            <div style={styles.buttons}>
                <Button
                    variant='contained'
                    color='primary'
                    component={Link}
                    to={`/${i18n.language}/${userType}`}
                    style={styles.buttonLink}
                >
                    {t('generic_goToHome')}
                </Button>
                <Button color='primary' onClick={() => removeAllJwt()} style={styles.button}>
                    {t('AlreadyLoggedPage_continue')}
                </Button>
            </div>
        </div>
    );
}
