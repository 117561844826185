/**
 * Create a new component that is bound to a set of fixed props.
 *
 * Similar to `styled` from styled-components, but for props.
 */
export function bound<Props extends object, FixedProps extends Partial<Props>>(
    Component: (props: Props) => JSX.Element,
    useFixedProps: (props: Omit<Props, keyof FixedProps> & Partial<FixedProps>) => FixedProps,
) {
    return function BoundComponent(props: Omit<Props, keyof FixedProps> & Partial<FixedProps>) {
        const fixedProps = useFixedProps(props);
        const finalProps = mergeProps(fixedProps, props) as unknown as Props;
        return <Component {...finalProps} />;
    };
}

/**
 * Merge two props objects recursively while preserving react refs.
 *
 * It is similar to `_.merge` from lodash, but it handles react refs correctly.
 */
export function mergeProps(a: object, b: object | undefined | null): object {
    if (b == null) {
        return a;
    }

    const merged = { ...a };
    for (const key in b) {
        if (key in a && isPlainObject(b[key])) {
            if (/ref$/i.test(key) || !isPlainObject(a[key])) {
                merged[key] = b[key];
            } else {
                merged[key] = mergeProps(a[key], b[key]);
            }
        } else {
            merged[key] = b[key];
        }
    }
    return merged;
}

const isPlainObject = (value: any) => value?.constructor === Object;
