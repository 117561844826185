import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getJwt } from '@libs/jwt';
import { AppDispatch } from '@libs/reduxHooks';
import { MongoId } from '@libs/types';

import { AdvertiserPlanAvailable, BillingPeriod } from '@api/advertiser/fetchCurrentAdvertiserData';
import { InvoiceFilterExplicit, listInvoices } from '@api/advertiser/invoice/list';
import apiListPaymentMethods from '@api/advertiser/listPaymentMethods';
import { checkStatus, getAPISubUrlForAdvertiser, getFileAsync } from '@api/common/utils';

import { initCurrentAdvertiser } from './advertiser';

export const ADVERTISER_BILLING_LOADING = 'ADVERTISER_BILLING_LOADING';

export const UPDATE_SUBSCRIPTION_ADVERTISER_LOADING = 'UPDATE_SUBSCRIPTION_ADVERTISER_LOADING';
export const SUBSCRIBE_ADVERTISER = 'SUBSCRIBE_ADVERTISER';
export const SUBSCRIBE_ADVERTISER_SUCCESS = 'SUBSCRIBE_ADVERTISER_SUCCESS';
export const SUBSCRIBE_ADVERTISER_FAILURE = 'SUBSCRIBE_ADVERTISER_FAILURE';

export const VERIFY_3DS_ADVERTISER = 'VERIFY_3DS_ADVERTISER';
export const VERIFY_3DS_ADVERTISER_SUCCESS = 'VERIFY_3DS_ADVERTISER_SUCCESS';
export const VERIFY_3DS_ADVERTISER_FAILURE = 'VERIFY_3DS_ADVERTISER_FAILURE';

export const UPDATE_SUBSCRIPTION_ADVERTISER = 'UPDATE_SUBSCRIPTION_ADVERTISER';
export const UPDATE_SUBSCRIPTION_ADVERTISER_SUCCESS = 'UPDATE_SUBSCRIPTION_ADVERTISER_SUCCESS';
export const UPDATE_SUBSCRIPTION_ADVERTISER_FAILURE = 'UPDATE_SUBSCRIPTION_ADVERTISER_FAILURE';

export const CANCEL_SUBSCRIPTION_ADVERTISER = 'CANCEL_SUBSCRIPTION_ADVERTISER';
export const CANCEL_SUBSCRIPTION_ADVERTISER_SUCCESS = 'CANCEL_SUBSCRIPTION_ADVERTISER_SUCCESS';
export const CANCEL_SUBSCRIPTION_ADVERTISER_FAILURE = 'CANCEL_SUBSCRIPTION_ADVERTISER_FAILURE';

export const UPDATE_ADVERTISER_PAYMENT_METHOD_LOADING = 'UPDATE_ADVERTISER_PAYMENT_METHOD_LOADING';
export const UPDATE_ADVERTISER_PAYMENT_METHOD = 'UPDATE_ADVERTISER_PAYMENT_METHOD';
export const UPDATE_ADVERTISER_PAYMENT_METHOD_SUCCESS = 'UPDATE_ADVERTISER_PAYMENT_METHOD_SUCCESS';
export const UPDATE_ADVERTISER_PAYMENT_METHOD_FAILURE = 'UPDATE_ADVERTISER_PAYMENT_METHOD_FAILURE';

export const OPEN_ADVERTISER_SUBSCRIBE_DIALOG = 'OPEN_ADVERTISER_SUBSCRIBE_DIALOG';
export const OPEN_ADVERTISER_UPDATE_SUBSCRIPTION_DIALOG =
    'OPEN_ADVERTISER_UPDATE_SUBSCRIPTION_DIALOG';
export const OPEN_ADVERTISER_UPDATE_PAYMENT_METHOD_DIALOG =
    'OPEN_ADVERTISER_UPDATE_PAYMENT_METHOD_DIALOG';

export const UPDATE_INVOICE_FILTER = 'UPDATE_INVOICE_FILTER';
export const FETCH_ADVERTISER_INVOICES_START = 'FETCH_ADVERTISER_INVOICES_START';
export const FETCH_ADVERTISER_INVOICES_SUCCESS = 'FETCH_ADVERTISER_INVOICES_SUCCESS';
export const FETCH_ADVERTISER_INVOICES_FAILURE = 'FETCH_ADVERTISER_INVOICES_FAILURE';

export const FETCH_LATEST_PAYMENT_INTENT_REQUEST = 'FETCH_LATEST_PAYMENT_INTENT_REQUEST';
export const FETCH_LATEST_PAYMENT_INTENT_SUCCESS = 'FETCH_LATEST_PAYMENT_INTENT_SUCCESS';
export const FETCH_LATEST_PAYMENT_INTENT_FAILURE = 'FETCH_LATEST_PAYMENT_INTENT_FAILURE';

export const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS';

export function advertiserBillingLoading() {
    return {
        type: ADVERTISER_BILLING_LOADING,
    };
}

export function updateSubscriptionAdvertiserLoading() {
    return {
        type: UPDATE_SUBSCRIPTION_ADVERTISER_LOADING,
    };
}

export function subscribe(
    paymentMethod: unknown,
    plan: AdvertiserPlanAvailable,
    currency: string,
    billingPeriod: BillingPeriod,
) {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: SUBSCRIBE_ADVERTISER });
        let data;
        try {
            data = await apiSubscribeAdvertiser(paymentMethod, plan, currency, billingPeriod);
        } catch (error) {
            addFlash('error', String(error));
            dispatch({
                type: SUBSCRIBE_ADVERTISER_FAILURE,
                payload: error,
            });
            return;
        }

        if (data.advertiser.paymentMethod === 'bankwire') {
            data.paymentIntent = {
                client_secret: null,
                status: 'succeeded',
            };
        }
        dispatch({
            type: SUBSCRIBE_ADVERTISER_SUCCESS,
            paymentIntent: data.paymentIntent,
        });
        dispatch(initCurrentAdvertiser(data.advertiser));
        return data;
    };
}

async function apiSubscribeAdvertiser(
    paymentMethod: unknown,
    plan: AdvertiserPlanAvailable,
    currency: string,
    billingPeriod: BillingPeriod,
) {
    const res = await fetch(getAPISubUrlForAdvertiser() + '/subscriptions.create', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            stripePaymentMethod: paymentMethod ? paymentMethod.id : '',
            plan: plan,
            currency: currency,
            billingPeriod,
        }),
    });

    await checkStatus(res, 201);
    const body = await res.json();
    return body.data;
}

export function verify3ds() {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(verify3dsAdvertiser())
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (!result.data || statusCode !== 200) {
                    dispatch(addFlash('error', result.message));
                    dispatch(verify3dsAdvertiserFailure(statusCode));
                } else {
                    dispatch(verify3dsAdvertiserSuccess());
                    dispatch(initCurrentAdvertiser(result.data.advertiser));
                }
            });
    };
}

function verify3dsAdvertiser() {
    const promise = fetch(getAPISubUrlForAdvertiser() + '/subscriptions/3ds.verify', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
    });

    return {
        type: VERIFY_3DS_ADVERTISER,
        payload: promise,
    };
}

function verify3dsAdvertiserSuccess() {
    return {
        type: VERIFY_3DS_ADVERTISER_SUCCESS,
    };
}

function verify3dsAdvertiserFailure(error) {
    return {
        type: VERIFY_3DS_ADVERTISER_FAILURE,
        payload: error,
    };
}

export function updateSubscription(plan) {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(updateSubscriptionAdvertiser(plan))
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (!result.data || statusCode !== 200) {
                    dispatch(addFlash('error', result.message));
                    dispatch(updateSubscriptionAdvertiserFailure(statusCode));
                } else {
                    if (result.data.advertiser.paymentMethod == 'bankwire') {
                        result.data.paymentIntent = {
                            client_secret: null,
                            status: 'succeeded',
                        };
                    }
                    dispatch(updateSubscriptionAdvertiserSuccess(result.data));
                    dispatch(initCurrentAdvertiser(result.data.advertiser));
                }
            });
    };
}

function updateSubscriptionAdvertiser(plan) {
    const promise = fetch(getAPISubUrlForAdvertiser() + '/subscriptions.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            plan: plan,
        }),
    });

    return {
        type: UPDATE_SUBSCRIPTION_ADVERTISER,
        payload: promise,
    };
}

function updateSubscriptionAdvertiserSuccess(data) {
    return {
        type: UPDATE_SUBSCRIPTION_ADVERTISER_SUCCESS,
        paymentIntent: data.paymentIntent,
    };
}

function updateSubscriptionAdvertiserFailure(error) {
    return {
        type: UPDATE_SUBSCRIPTION_ADVERTISER_FAILURE,
        payload: error,
    };
}

export function cancelSubscription() {
    return (dispatch: AppDispatch) => {
        let statusCode;
        dispatch(cancelSubscriptionAdvertiser())
            .then(function (response) {
                statusCode = response.payload.status;
                return response.payload.json();
            })
            .then(function (result) {
                if (!result.data || statusCode !== 200) {
                    addFlash('error', result.message);
                    dispatch(cancelSubscriptionAdvertiserFailure(statusCode));
                } else {
                    dispatch(closeAllDialogs());
                    addFlash('success', _t('billing_subscription_update_dialog_cancel_success'));
                    dispatch(cancelSubscriptionAdvertiserSuccess());
                    dispatch(initCurrentAdvertiser(result.data.advertiser));
                }
            });
    };
}

function cancelSubscriptionAdvertiser() {
    const promise = fetch(getAPISubUrlForAdvertiser() + '/subscriptions.freeze', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
    });

    return {
        type: CANCEL_SUBSCRIPTION_ADVERTISER,
        payload: promise,
    };
}

function cancelSubscriptionAdvertiserSuccess() {
    return {
        type: CANCEL_SUBSCRIPTION_ADVERTISER_SUCCESS,
    };
}

function cancelSubscriptionAdvertiserFailure(error) {
    return {
        type: CANCEL_SUBSCRIPTION_ADVERTISER_FAILURE,
        payload: error,
    };
}

export const listPaymentMethods = createAsyncThunk('advertiser/listPaymentMethod', async () => {
    try {
        return await apiListPaymentMethods();
    } catch (error) {
        window.addFlash('error', `Failed to retrieve payment method.\nDetails: '${error}'`);
        throw error;
    }
});

export function updateAdvertiserPaymentMethodLoading() {
    return {
        type: UPDATE_ADVERTISER_PAYMENT_METHOD_LOADING,
    };
}

export function updateAdvertiserPaymentMethod(paymentMethod) {
    const promise = fetch(getAPISubUrlForAdvertiser() + '/paymentMethods.update', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            stripePaymentMethod: paymentMethod.id,
        }),
    });

    return {
        type: UPDATE_ADVERTISER_PAYMENT_METHOD,
        payload: promise,
    };
}

export function updateAdvertiserPaymentMethodSuccess(paymentMethods) {
    return {
        type: UPDATE_ADVERTISER_PAYMENT_METHOD_SUCCESS,
        payload: paymentMethods,
    };
}

export function updateAdvertiserPaymentMethodFailure(error) {
    return {
        type: UPDATE_ADVERTISER_PAYMENT_METHOD_FAILURE,
        payload: error,
    };
}

export function openAdvertiserSubscribeDialog() {
    return {
        type: OPEN_ADVERTISER_SUBSCRIBE_DIALOG,
    };
}

export function openAdvertiserUpdateSubscriptionDialog() {
    return {
        type: OPEN_ADVERTISER_UPDATE_SUBSCRIPTION_DIALOG,
    };
}

export function openAdvertiserUpdatePaymentMethodDialog() {
    return {
        type: OPEN_ADVERTISER_UPDATE_PAYMENT_METHOD_DIALOG,
    };
}

export function closeAllDialogs() {
    return {
        type: CLOSE_ALL_DIALOGS,
    };
}

export function updateInvoicesFilter(filter) {
    return {
        type: UPDATE_INVOICE_FILTER,
        filter,
    };
}

export function fetchAdvertiserInvoices(filter: InvoiceFilterExplicit) {
    return (dispatch) => {
        dispatch(fetchAdvertiserInvoicesStart());
        listInvoices(filter)
            .then((invoices) => dispatch(fetchAdvertiserInvoicesSuccess(invoices)))
            .catch((err) => dispatch(fetchAdvertiserInvoicesFailure(err.message)));
    };
}

export function fetchAdvertiserInvoicesStart() {
    return {
        type: FETCH_ADVERTISER_INVOICES_START,
    };
}

export function fetchAdvertiserInvoicesSuccess(invoices) {
    return {
        type: FETCH_ADVERTISER_INVOICES_SUCCESS,
        invoices,
    };
}

export function fetchAdvertiserInvoicesFailure(msg) {
    return {
        type: FETCH_ADVERTISER_INVOICES_FAILURE,
        msg,
    };
}

export const getInvoicePdfOptimized = withCache(getInvoicePdf);
export async function getInvoicePdf(id: MongoId): Promise<File> {
    const res = await fetch(getAPISubUrlForAdvertiser() + `/invoices.get?ids=${id}`, {
        headers: { Authorization: getJwt() },
    });
    await checkStatus(res);
    return await getFileAsync(res);
}

// could extract to reuse
/**
 * Add basic cache to an async function
 */
function withCache<T>(func: (id: string) => Promise<T>): (id: string) => Promise<T> {
    const cache: { [id: string]: T } = {};
    return async function (id) {
        if (cache[id] == null) {
            cache[id] = await func(id);
        }
        return cache[id];
    };
}

export async function apiFetchLatestPaymentIntent(
    subscriptionId = undefined,
): Promise<unknown | undefined> {
    const url = buildUrl(subscriptionId);
    const response = await fetch(url, { headers: { Authorization: getJwt() } });
    await checkStatus(response, 200);

    const contentLength = response.headers.get('content-length');
    if (contentLength != null && Number(contentLength) === 0) return undefined;

    const body = await response.json();
    const paymentIntent = body;
    return paymentIntent;

    function buildUrl(subscriptionId = undefined) {
        let url = getAPISubUrlForAdvertiser() + '/subscriptions/paymentIntents.get';
        if (subscriptionId !== undefined) {
            url += `?subscriptionId=${subscriptionId}`;
        }
        return url;
    }
}

export function fetchLatestPaymentIntentRequest() {
    return {
        type: FETCH_LATEST_PAYMENT_INTENT_REQUEST,
    };
}

export function fetchLatestPaymentIntentSuccess(paymentIntent) {
    return {
        type: FETCH_LATEST_PAYMENT_INTENT_SUCCESS,
        paymentIntent,
    };
}

export function fetchLatestPaymentIntentFailure(error) {
    return {
        type: FETCH_LATEST_PAYMENT_INTENT_FAILURE,
        error,
    };
}

export function fetchLatestPaymentIntent() {
    return (dispatch: AppDispatch) => {
        dispatch(fetchLatestPaymentIntentRequest());
        apiFetchLatestPaymentIntent()
            .then((paymentIntent) => {
                dispatch(fetchLatestPaymentIntentSuccess(paymentIntent));
            })
            .catch((error) => {
                dispatch(fetchLatestPaymentIntentFailure(error));
            });
    };
}
