import { getUserTypeFromUrl } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from './utils';

export async function deleteEntity(): Promise<void> {
    const side = getUserTypeFromUrl();
    const url = `${getAPIBaseUrl() + side}/${side}s.delete`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });

    await checkStatus(response);
}
