import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const PailheCheckbox = styled(Checkbox)({
    '&.MuiCheckbox-root': {
        // Style spécifique pour surcharger le padding de la checkbox
        padding: 0,
        height: 'fit-content',
    },
});

export default PailheCheckbox;
