import isEmpty from 'lodash/isEmpty';

import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { PartnershipPublisher } from '@api/common/partnerships/list';
import { checkStatus, getAPISubUrlForPublisher } from '@api/common/utils';

export default async function updateStatus({
    partnershipId,
    status,
    reason,
}: {
    partnershipId: MongoId;
    status: 'pending' | 'cancelled_publisher';
    reason?: string;
}): Promise<PartnershipPublisher> {
    const action = (function () {
        switch (status) {
            case 'pending':
                return 'pending';
            case 'cancelled_publisher':
                return 'cancel';
        }
    })();

    const res = await fetch(getAPISubUrlForPublisher() + '/partnerships.' + action, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            partnership: partnershipId,
            reason: isEmpty(reason) ? undefined : reason,
        }),
    });
    await checkStatus(res);

    const body = await res.json();
    return body.partnership.data;
}
