import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import LoadingButton from '@mui/lab/LoadingButton';

import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useMfaState } from '@providers/MfaStateProvider';

import { ErrorView } from '@components/generic/ErrorView';
import { LoadingView } from '@components/generic/LoadingView';
import { Tooltip } from '@components/generic/Tooltip';

import { useTextField } from '@hooks/useField';
import { useStorage } from '@hooks/useStorage';
import { useTimer } from '@hooks/useTimer';

import { maskEmail } from '@libs/maskEmail';
import { maskPhone } from '@libs/maskPhone';

import { getIsRateLimitError } from '@api/common/utils';

import { MfaDialogAction, MfaDialogTitle } from '../common';
import { Scenario } from '../MfaForm';

export type MfaVerifyCodeViewProps = {
    scenario: Scenario;
    onClose: (reason: 'success' | 'cancel' | 'failure' | 'editPhone') => void;

    userInfo: {
        email: string;
        phone: string;
    };
};

export function MfaVerifyCodeView({ scenario, onClose, userInfo }: MfaVerifyCodeViewProps) {
    const { t } = useTranslation();

    const mfaState = useMfaState();
    const verifyState = mfaState.getVerifyState();
    const sendCode = useMfaState((state) => state.sendCode);
    const sendCodeLoadingStatus = useMfaState((state) => state.sendCodeLoadingStatus);

    const newCodeTimer = useTimer({
        initialDuration: verifyState.nextTryAt ? verifyState.nextTryAt?.diff(moment()) : null,
        autoStart: verifyState.nextTryAt != null,
    });

    const [rateLimitNextTryAtStr, storeRateLimitNextTryAtStr] = useStorage(
        'mfaVerifyCodeRateLimitNextTryAt',
    );

    let rateLimitDurationMs: number | null = null;

    if (rateLimitNextTryAtStr == null) {
        rateLimitDurationMs == null;
    } else {
        const nexTryAt = moment.unix(parseInt(rateLimitNextTryAtStr));
        if (nexTryAt.isAfter(moment())) {
            rateLimitDurationMs = nexTryAt.diff(moment());
        } else {
            storeRateLimitNextTryAtStr(null);
            rateLimitDurationMs == null;
        }
    }

    const errorRateLimitTimer = useTimer({
        initialDuration: rateLimitDurationMs !== null ? rateLimitDurationMs : null,
        autoStart: rateLimitDurationMs !== null,
    });

    const [hasTrySubmit, setHasTrySubmit] = useState(false);

    useEffect(() => {
        async function sendCodeIfFirstTime() {
            if (
                verifyState.tryCount === 0 &&
                verifyState.nextMethod !== null &&
                sendCodeLoadingStatus === 'idle'
            ) {
                const newVerifyState = await sendCode();
                if (newVerifyState.nextTryAt) {
                    newCodeTimer.start(newVerifyState.nextTryAt.diff(moment()));
                }
            }
        }
        sendCodeIfFirstTime();
    }, [verifyState, sendCode, sendCodeLoadingStatus]);

    const verifyCodeLoadingStatus = useMfaState((state) => state.verifyCodeLoadingStatus);
    const verifyCodeLoadingError = useMfaState((state) => state.verifyCodeLoadingError);
    const verifyCodeData = useMfaState((state) => state.verifyCodeData);
    const verifyCode = useMfaState((state) => state.verifyCode);

    const codeField = useTextField('', {
        validator: (code) => {
            const isValid = /^\d{4}$/.test(code);
            return {
                isValid,
                helperText: isValid ? undefined : (
                    <Trans i18nKey='mfaForm_verifyCodeView_errorCode_mustBe4Digits' />
                ),
            };
        },
        getShowValidity: 'afterSubmit',
        hasSubmit: hasTrySubmit,
    });

    async function handleSubmit(value = codeField.value) {
        setHasTrySubmit(true);
        if (codeField.validator(value).isValid === false) {
            return;
        }
        try {
            const { isVerified } = await verifyCode(value);
            if (isVerified) {
                onClose('success');
            }
        } catch (error) {
            if (getIsRateLimitError(error)) {
                errorRateLimitTimer.start(error.retryAfter * 1000);
                const TimeStampEnd = moment().add(error.retryAfter, 'seconds').unix();
                storeRateLimitNextTryAtStr(TimeStampEnd.toString());
            }
        }
    }

    async function handleSendCode() {
        if (verifyState.nextMethod === 'support') {
            onClose('failure');
        } else {
            const newVerifyState = await sendCode();
            if (newVerifyState.nextTryAt) {
                newCodeTimer.start(newVerifyState.nextTryAt.diff(moment()));
            }
        }
    }

    if (
        mfaState.verifyStateLoadingStatus === 'idle' ||
        mfaState.verifyStateLoadingStatus === 'loading'
    ) {
        return <LoadingView />;
    } else if (mfaState.verifyStateLoadingStatus === 'failure') {
        return (
            <ErrorView
                msg={
                    mfaState.verifyStateLoadingError?.errorMsg ??
                    mfaState.verifyStateLoadingError?.message ??
                    t('generic_error')
                }
            />
        );
        // todo add retry & cancel button
    }

    function getCodeFieldHelperText(): ReactNode {
        if (codeField.shouldDisplayValidity && codeField.helperText != null) {
            return codeField.helperText;
        }

        if (verifyCodeLoadingStatus !== 'success' || verifyCodeData == null) {
            return undefined;
        }

        const res = verifyCodeData;
        if (res.isVerified) {
            return undefined;
        }

        switch (res.error) {
            case 'code-expired':
                return <Trans i18nKey='mfaForm_verifyCodeView_errorCode_expired' />;
            case 'code-invalid':
                return <Trans i18nKey='mfaForm_verifyCodeView_errorCode_invalid' />;
            case 'internal':
                return <Trans i18nKey='mfaForm_verifyCodeView_errorCode_generic' />;
        }
    }

    function getCodeFieldError(): boolean {
        if (codeField.shouldDisplayValidity && codeField.isValid === false) {
            return true;
        }

        // todo hide api error after edit
        return verifyCodeData?.isVerified === false;
    }

    if (verifyState.currentMethod === 'support') {
        return null;
    }

    return (
        <>
            <MfaDialogTitle />
            <DialogContent>
                {errorRateLimitTimer.status === 'running' && (
                    <Alert severity='error'>
                        <AlertTitle>{t('generic_too_many_attempts')}</AlertTitle>
                        you can retry in {Math.floor(errorRateLimitTimer.time / 1000)} seconds
                    </Alert>
                )}
                {verifyCodeLoadingStatus === 'failure' &&
                    !getIsRateLimitError(verifyCodeLoadingError) && (
                        <Alert severity='error'>{t('generic_error')}</Alert>
                    )}
                <DialogContentText>
                    {t('mfaForm_verifyCodeView_description', {
                        methodName: verifyState.currentMethod === 'sms' ? 'sms' : 'email',
                        methodValue:
                            verifyState.currentMethod === 'sms'
                                ? maskPhone(userInfo.phone)
                                : maskEmail(userInfo.email),
                    })}
                    <br />
                    {t('mfaForm_verifyCodeView_duration_code_info')}
                </DialogContentText>
                <br />
                <TextField
                    data-testid='codeField'
                    label={t('mfaForm_verifyCodeView_codeFieldLabel')}
                    variant='outlined'
                    value={codeField.value}
                    autoComplete='one-time-code'
                    onChange={(e) => {
                        const newValue = e.target.value;
                        codeField.setValue(newValue);
                        if (newValue.length === 4) {
                            handleSubmit(newValue);
                        }
                    }}
                    disabled={verifyCodeLoadingStatus === 'loading'}
                    error={getCodeFieldError()}
                    helperText={getCodeFieldHelperText()}
                    autoFocus
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                />

                {scenario === 'setup' && verifyState.currentMethod === 'sms' && (
                    // phone number edit only available when we are setting up mfa
                    // can't edit method when we are confirming mfa
                    <DialogContentText
                        sx={{
                            marginTop: '0.5rem',
                            fontStyle: 'italic',
                            fontSize: '0.8rem',
                            cursor: 'pointer',
                            color: 'gray',
                            '&:hover': {
                                color: '#5a96b8', // todo reduce duplication
                            },
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            onClose('editPhone');
                        }}
                    >
                        {t('mfaForm_verifyCodeView_linkEditPhone')}
                        <OpenInNewIcon
                            fontSize='inherit'
                            sx={{
                                marginLeft: '0.2rem',
                            }}
                        />
                    </DialogContentText>
                )}

                {verifyState.nextMethod === 'email' && (
                    <>
                        <br />
                        <br />
                        <DialogContentText>
                            <Trans
                                i18nKey='mfaForm_verifyCodeView_msgTryEmail'
                                values={{
                                    method: verifyState.currentMethod,
                                    email: maskEmail(userInfo.email),
                                }}
                                components={{
                                    br: <br />,
                                }}
                            />
                        </DialogContentText>
                    </>
                )}
            </DialogContent>
            <MfaDialogAction>
                <Button data-testid='btnCancel' onClick={() => onClose('cancel')} color='quiet'>
                    {t('logout')}
                </Button>

                <Tooltip
                    title={
                        mfaState.canAskNewCode === false && verifyState.nextTryAt != null
                            ? t('mfaForm_verifyCodeView_btnSendCode_tooltipDisabledByDelay', {
                                  count: Math.floor(newCodeTimer.time / 1000),
                              })
                            : undefined
                    }
                >
                    <LoadingButton
                        data-testid='btnResendCode'
                        onClick={handleSendCode}
                        loading={mfaState.sendCodeLoadingStatus === 'loading'}
                        disabled={mfaState.canAskNewCode === false}
                    >
                        {t(
                            verifyState.nextMethod === 'email'
                                ? 'mfaForm_verifyCodeView_btnSendCode_byEmail'
                                : 'mfaForm_verifyCodeView_btnSendCode',
                        )}
                    </LoadingButton>
                </Tooltip>

                <LoadingButton
                    data-testid='btnVerifyCode'
                    variant='contained'
                    onClick={() => handleSubmit()}
                    loading={verifyCodeLoadingStatus === 'loading'}
                    disabled={codeField.value === '' || errorRateLimitTimer.status === 'running'}
                >
                    {t('mfaForm_verifyCodeView_btnVerifyCode')}
                </LoadingButton>
            </MfaDialogAction>
        </>
    );
}
