import { getJwt } from '@libs/jwt';

import { checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

/**
 * [Stripe payment method](https://stripe.com/docs/api/payment_methods/object)
 * just a partial type here
 */
export type PaymentMethod = {
    id: string;
    object: 'payment_method';
    billing_details: unknown;
    card: {
        brand: string;
        exp_month: number;
        exp_year: number;
        last4: string;
    };
};

export default async function listPaymentMethods(): Promise<PaymentMethod[]> {
    const response = await fetch(getAPISubUrlForAdvertiser() + '/paymentMethods.list', {
        headers: { Authorization: getJwt() },
    });
    await checkStatus(response);

    const data = (await response.json()).data;
    if (data == null) {
        throw new Error('missing data');
    }

    return data.paymentMethods;
}
