import _ from 'lodash';

import { getJwt } from '@libs/jwt';

import { getAPISubUrlForPublisher } from '@api/common/utils';

// Fetch reporting data for advertiser
export const PUBLISHER_REPORTING_LOADING = 'PUBLISHER_REPORTING_LOADING';
export const FETCH_PUBLISHER_REPORTING_DATA = 'FETCH_PUBLISHER_REPORTING_DATA';
export const FETCH_PUBLISHER_REPORTING_DATA_SUCCESS = 'FETCH_PUBLISHER_REPORTING_DATA_SUCCESS';
export const FETCH_PUBLISHER_REPORTING_DATA_FAILURE = 'FETCH_PUBLISHER_REPORTING_DATA_FAILURE';

export const UPDATE_SELECTED_PARTNERSHIPS = 'UPDATE_SELECTED_PARTNERSHIPS';
export const UPDATE_VISIBLE_COLUMNS = 'UPDATE_VISIBLE_COLUMNS';
export const UPDATE_SELECTED_ADS = 'UPDATE_SELECTED_ADS';
export const UPDATE_SELECTED_PRODUCTS = 'UPDATE_SELECTED_PRODUCTS';
export const UPDATE_SELECTED_RULES = 'UPDATE_SELECTED_RULES';
export const UPDATE_SELECTED_GROUPS = 'UPDATE_SELECTED_GROUPS';
export const UPDATE_CURRENT_GRAPH_KPI_ID = 'UPDATE_CURRENT_GRAPH_KPI_ID';
export const UPDATE_CURRENCIES = 'UPDATE_CURRENCIES';

export const FETCH_USER_PREFERENCES = 'FETCH_USER_PREFERENCES';
export const FETCH_USER_PREFERENCES_SUCCESS = 'FETCH_USER_PREFERENCES_SUCCESS';
export const FETCH_USER_PREFERENCES_FAILURE = 'FETCH_USER_PREFERENCES_FAILURE';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const UPDATE_USER_PREFERENCES_SUCCESS = 'UPDATE_USER_PREFERENCES_SUCCESS';
export const UPDATE_USER_PREFERENCES_FAILURE = 'UPDATE_USER_PREFERENCES_FAILURE';

export const SAVE_REPORT = 'SAVE_REPORT';
export const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS';
export const SAVE_REPORT_FAILURE = 'SAVE_REPORT_FAILURE';

export const FETCH_SAVED_REPORTS = 'FETCH_SAVED_REPORTS';
export const FETCH_SAVED_REPORTS_SUCCESS = 'FETCH_SAVED_REPORTS_SUCCESS';
export const FETCH_SAVED_REPORTS_FAILURE = 'GET_SAVED_REPORTS_FAILURE';

export const DELETE_SAVED_REPORT = 'DELETE_SAVED_REPORT';
export const DELETE_SAVED_REPORT_SUCCESS = 'DELETE_SAVED_REPORT_SUCCESS';
export const DELETE_SAVED_REPORT_FAILURE = 'DELETE_SAVED_REPORT_FAILURE';

export const OPEN_SAVE_REPORT_DOCK = 'OPEN_SAVE_REPORT_DOCK';
export const OPEN_AFFILIATE_PROFILE_SAVED_REPORT_DOCK = 'OPEN_AFFILIATE_PROFILE_SAVED_REPORT_DOCK';
export const CLOSE_DOCKS = 'CLOSE_DOCKS';

function getPublisherQueryString(queryCriteria) {
    let qs =
        '?affiliateProfile=' +
        queryCriteria.affiliateProfile +
        '&currency=' +
        queryCriteria.currency +
        '&from=' +
        queryCriteria.from +
        '&to=' +
        queryCriteria.to +
        '&timezone=' +
        queryCriteria.timezone +
        '&status=' +
        queryCriteria.status +
        '&scale=' +
        queryCriteria.scale +
        '&kind=' +
        queryCriteria.kind;

    if (queryCriteria.group) {
        qs = qs.concat('&group=' + queryCriteria.group);
    }
    if (queryCriteria.partnership) {
        qs = qs.concat('&partnership=' + queryCriteria.partnership);
    }
    if (queryCriteria.title) {
        qs = qs.concat('&title=' + queryCriteria.title);
    }
    return qs;
}

export function publisherReportingLoading(queryCriteria) {
    return {
        type: PUBLISHER_REPORTING_LOADING,
        queryCriteria: queryCriteria,
    };
}

export function fetchPublisherReportingData(queryCriteria) {
    const qs = getPublisherQueryString(queryCriteria);

    const promise = fetch(getAPISubUrlForPublisher() + '/reporting.stats' + qs, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + getJwt() },
    });

    return {
        type: FETCH_PUBLISHER_REPORTING_DATA,
        payload: promise,
    };
}

export function fetchPublisherReportingDataSuccess(reportingData, queryCriteria) {
    return {
        type: FETCH_PUBLISHER_REPORTING_DATA_SUCCESS,
        payload: reportingData,
        queryCriteria: queryCriteria,
    };
}

export function fetchPublisherReportingDataFailure(error) {
    return {
        type: FETCH_PUBLISHER_REPORTING_DATA_FAILURE,
        payload: error,
    };
}

export function publisherExportReportingLoading(queryCriteria) {
    return {
        type: PUBLISHER_EXPORT_REPORTING_LOADING,
        queryCriteria: queryCriteria,
    };
}

export function fetchSavedReports(queryCriteria) {
    const qs = '?affiliateProfile=' + queryCriteria.affiliateProfile;

    const promise = fetch(getAPISubUrlForPublisher() + '/reports.list' + qs, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + getJwt() },
    });

    return {
        type: FETCH_SAVED_REPORTS,
        payload: promise,
    };
}

export function fetchSavedReportsSuccess(reports) {
    return {
        type: FETCH_SAVED_REPORTS_SUCCESS,
        payload: reports,
    };
}

export function fetchSavedReportsFailure(error) {
    return {
        type: FETCH_SAVED_REPORTS_FAILURE,
        payload: error,
    };
}

export function updateSelectedPartnerships(selectedPartnerships) {
    return {
        type: UPDATE_SELECTED_PARTNERSHIPS,
        selectedPartnerships: selectedPartnerships,
    };
}

export function updateVisibleColumns(visibleColumns) {
    return {
        type: UPDATE_VISIBLE_COLUMNS,
        visibleColumns: visibleColumns,
    };
}

export function updateSelectedAds(selectedAds) {
    return {
        type: UPDATE_SELECTED_ADS,
        selectedAds: selectedAds,
    };
}

export function updateSelectedProducts(selectedProducts) {
    return {
        type: UPDATE_SELECTED_PRODUCTS,
        selectedProducts: selectedProducts,
    };
}

export function updateSelectedRules(selectedRules) {
    return {
        type: UPDATE_SELECTED_RULES,
        selectedRules: selectedRules,
    };
}

export function updateSelectedGroups(selectedGroups) {
    return {
        type: UPDATE_SELECTED_GROUPS,
        selectedGroups: selectedGroups,
    };
}

export function updateCurrentGraphKpiId(graphKpiId) {
    return {
        type: UPDATE_CURRENT_GRAPH_KPI_ID,
        graphKpiId: graphKpiId,
    };
}

export function updateCurrencies(currencies) {
    return {
        type: UPDATE_CURRENCIES,
        currencies: currencies,
    };
}

export function fetchUserPreferences(queryCriteria) {
    const promise = fetch(getAPISubUrlForPublisher() + '/reportingPreferences.info', {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + getJwt() },
    });

    return {
        type: FETCH_USER_PREFERENCES,
        payload: promise,
    };
}

export function fetchUserPreferencesSuccess(response, visibleColumns) {
    let newVisibleColumns;
    if (response.length === 0) {
        newVisibleColumns = visibleColumns;
    } else {
        newVisibleColumns = ['name'];
        newVisibleColumns = newVisibleColumns.concat(response);
    }

    return {
        type: FETCH_USER_PREFERENCES_SUCCESS,
        visibleColumns: newVisibleColumns,
    };
}

export function fetchUserPreferencesFailure(error) {
    return {
        type: FETCH_USER_PREFERENCES_FAILURE,
        payload: error,
    };
}

export function updateUserPreferences(queryCriteria, visibleColumns) {
    const promise = fetch(getAPISubUrlForPublisher() + '/reportingPreferences.update', {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            visibleColumns: visibleColumns,
        }),
    });

    return {
        type: UPDATE_USER_PREFERENCES,
        payload: promise,
    };
}

export function updateUserPreferencesSuccess(response) {
    return {
        type: UPDATE_USER_PREFERENCES_SUCCESS,
        payload: response,
    };
}

export function updateUserPreferencesFailure(error) {
    return {
        type: UPDATE_USER_PREFERENCES_FAILURE,
        payload: error,
    };
}

export function saveReport(reportTitle, queryCriteria, selectedEntities) {
    const entitiesIds = [...selectedEntities.keys()];
    const promise = fetch(getAPISubUrlForPublisher() + '/reports.add', {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            title: reportTitle,
            type: queryCriteria.kind,
            currency: queryCriteria.currency,
            titleFilter: queryCriteria.title ? queryCriteria.title : '',
            from: queryCriteria.from,
            to: queryCriteria.to,
            affiliateProfile: queryCriteria.affiliateProfile,
            entitiesIds: entitiesIds,
        }),
    });

    return {
        type: SAVE_REPORT,
        payload: promise,
    };
}

export function saveReportSuccess(response) {
    return {
        type: SAVE_REPORT_SUCCESS,
        payload: response,
    };
}

export function saveReportFailure(error) {
    return {
        type: SAVE_REPORT_FAILURE,
        payload: error,
    };
}

export function deleteSavedReport(reportId, queryCriteria) {
    const qs = '?report=' + reportId;

    const promise = fetch(getAPISubUrlForPublisher() + '/reports.delete' + qs, {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + getJwt() },
    });

    return {
        type: DELETE_SAVED_REPORT,
        payload: promise,
    };
}

export function deleteSavedReportSuccess(response) {
    return {
        type: DELETE_SAVED_REPORT_SUCCESS,
        payload: response,
    };
}

export function deleteSavedReportFailure(error) {
    return {
        type: DELETE_SAVED_REPORT_FAILURE,
        payload: error,
    };
}

export function openSaveReportDock() {
    return {
        type: OPEN_SAVE_REPORT_DOCK,
    };
}

export function openAffiliateProfileSavedReportDock() {
    return {
        type: OPEN_AFFILIATE_PROFILE_SAVED_REPORT_DOCK,
    };
}

export function openExportReportDock() {
    throw 'Missing action type def, and not handled by reducer';
}

export function closeDocks() {
    return {
        type: CLOSE_DOCKS,
    };
}
