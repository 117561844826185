import { CSSProperties } from 'react';

interface ICountryFlagProps {
    countryCode: string;
    style?: CSSProperties;
    width?: number | string;
    height?: number | string;
}

function CountryFlag(props: ICountryFlagProps) {
    const { countryCode, style, width = 20, height = 15 } = props;

    return (
        <img
            src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.webp`}
            width={width}
            height={height}
            style={style}
            alt={`${countryCode} flag`}
        />
    );
}

export default CountryFlag;
