import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import GppBadIcon from '@mui/icons-material/GppBad';

import { useTimer } from '@hooks/useTimer';

import { emailSupport } from '@libs/getSharedVar';

import { MfaDialogAction } from '../common';

export type MfaFailureViewProps = {
    cancelBtnLabel?: string;
    onCancel: () => void;
    /** in ms */
    delayBeforeRetry: number;
    onRetryAvailable: () => void;
};

export function MfaFailureView({
    cancelBtnLabel = 'Cancel',
    delayBeforeRetry,
    onCancel,
    onRetryAvailable,
}: MfaFailureViewProps) {
    const { t } = useTranslation();

    const retryTimer = useTimer({
        initialDuration: delayBeforeRetry,
        onEnd() {
            onRetryAvailable?.();
        },
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <GppBadIcon sx={{ fontSize: '6rem', color: 'error.main', marginTop: '2rem' }} />

            <DialogContent>
                <DialogContentText
                    component='h2'
                    sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}
                >
                    {t('mfaForm_failureView_title')}
                </DialogContentText>

                <DialogContentText
                    component='h3'
                    sx={{ fontSize: '1rem', textAlign: 'center', marginTop: '2rem' }}
                >
                    {t('mfaForm_failureView_description')}
                </DialogContentText>

                {retryTimer.status === 'running' && (
                    <>
                        <br />
                        <DialogContentText sx={{ fontSize: '1rem', textAlign: 'center' }}>
                            {t('mfaForm_failureView_retryIn', {
                                duration: moment
                                    .duration(retryTimer.time, 'milliseconds')
                                    .humanize(),
                            })}
                        </DialogContentText>
                    </>
                )}
            </DialogContent>

            <MfaDialogAction>
                <Button color='inherit' onClick={() => onCancel()}>
                    {cancelBtnLabel}
                </Button>
                <Button variant='contained' color='primary' href={`mailto:${emailSupport}`}>
                    {t('mfaForm_failureView_btnContact')}
                </Button>
            </MfaDialogAction>
        </div>
    );
}
