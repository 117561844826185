import { checkStatus, getAPIBaseUrl } from './utils';

export interface Currency {
    code: string;
    number: string; // a string of a number
    digits: number;
    currency: string;
    countries: string[];
}

export async function fetchCurrencies() {
    const response = await fetch(getAPIBaseUrl() + 'common/currencies.list');
    await checkStatus(response, 200);
    const body = await response.json();
    return body.currencies as Currency[];
}
