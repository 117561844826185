import 'moment/locale/es';
import 'moment/locale/fr';
import '@libs/lib_moment_locale';

import { useEffect } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment';

import AdapterMoment from '@mui/lab/AdapterMoment';
import MuiLocalizationProvider from '@mui/lab/LocalizationProvider';

import { getLocale as getLocalFromUrl } from '@libs/getSharedVar';
import useZodErrorMap from '@libs/useZodErrorMap';

import resources from '../i18n/resources';

export { default as i18next } from 'i18next';

let isLanguageInit = true;

init();

function init() {
    const lng = getLocalFromUrl();
    i18next.use(initReactI18next).init({
        lng,
        resources,
        interpolation: {
            prefix: '__',
            suffix: '__',
        },
    });
    window._t = i18next.t.bind(i18next);
    handleLanguageChange(lng);
    isLanguageInit = false;
}

i18next.on('languageChanged', handleLanguageChange);

// mkOnLocaleChange(locale => {
//     if (i18next.language !== locale) {
//         i18next.changeLanguage(locale);
//     }
// });

function handleLanguageChange(lng: string) {
    // mkChangeLocale(lng);
    if (!isLanguageInit && /marketplace/.test(location.pathname)) {
        setTimeout(() => location.reload(), 500);
        //wait for url to be update with new local by component responsible to sync path & locale
    }
    moment.locale(lng);
}

// to easily change language from browser console and
// test i18n in modal, form & other element without locale selector
window.i18n = i18next;

export function LocalizationProvider({ children }: { children: React.ReactNode }) {
    const { i18n } = useTranslation();
    const history = useHistory();

    // Update locale when URL changes
    useEffect(() => {
        function handleLocationChange() {
            const localeFromUrl = getLocalFromUrl();
            if (i18n.language !== localeFromUrl) {
                i18n.changeLanguage(localeFromUrl);
            }
        }
        window.addEventListener('popstate', handleLocationChange);
        return () => window.removeEventListener('pop', handleLocationChange);
    }, [i18n]);

    // Update URL when locale changes
    useEffect(() => {
        history.push({
            pathname: updateLocaleInPath(location.pathname, i18n.language),
            search: location.search,
        });
    }, [i18n.language, history]);

    // Apply custom default error messages for Zod on whole app
    useZodErrorMap();

    return (
        <MuiLocalizationProvider dateAdapter={AdapterMoment} locale={i18n.language}>
            {children}
        </MuiLocalizationProvider>
    );
}

function updateLocaleInPath(path: string, locale: string) {
    const parts = path.split('/');
    parts[1] = locale;
    return parts.join('/');
}

// based on https://github.com/i18next/react-i18next/issues/6#issuecomment-185300536
if (import.meta.hot) {
    import.meta.hot.accept('./resources.js', (module) => {
        if (!module) return;

        const resources = module.default;
        Object.keys(resources).forEach((lang) => {
            Object.keys(resources[lang]).forEach((namespace) => {
                i18next.addResourceBundle(lang, namespace, resources[lang][namespace], true, true);
            });
        });
        const language = i18next.language;
        i18next.changeLanguage(language);
    });
}
