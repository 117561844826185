import { Trans, useTranslation } from 'react-i18next';
import type { Event, FallbackRender } from '@sentry/react';

import Button from '@mui/material/Button';

import useBreakpoint from '@hooks/useBreakpoint';

import { emailSupport } from '@libs/getSharedVar';

import crashImage from './crash.svg';
import styles from './eventsPagesStyle';

let lastEvent: Event | null = null;
// we store the last event **send** to sentry to be able to display it in the error page
// we can't use the eventId from the error boundary because it's not the same as the one sent to sentry
// because of intern react mechanism (similar to https://github.com/getsentry/sentry-javascript/issues/4534
// it could be removed if the future if this issue is fixed

export function setLastEvent(event: Event) {
    lastEvent = event;
}

/** Wrapper needed by sentry */
export const ErrorFallback: FallbackRender = (props) => <ErrorPage {...props} />;

type ErrorPageProps = Parameters<FallbackRender>[0];
const ErrorPage = ({ resetError }: ErrorPageProps) => {
    const { t } = useTranslation();
    const isMobile = useBreakpoint({ breakpoint: 'md', direction: 'down' });

    const eventId = lastEvent?.event_id;

    return (
        <div
            style={
                isMobile
                    ? { ...styles.page, margin: '2rem 1rem 0 1rem' }
                    : { ...styles.page, marginTop: '2rem' }
            }
        >
            <img src={crashImage} alt='page crashed' style={styles.illustration} />
            <h1
                style={
                    isMobile
                        ? { ...styles.title, fontSize: '2rem', marginTop: '1rem' }
                        : styles.title
                }
            >
                {t('errorPage_title')}
            </h1>
            <p style={styles.text}>
                <Trans i18nKey={'errorPage_description'} values={{ eventId }}>
                    We have been noticed about this incident. And we are doing our best to fix it
                    <br />
                    Event id: {eventId}
                </Trans>
            </p>

            <div style={styles.buttons}>
                <Button
                    style={styles.buttonLink}
                    variant='contained'
                    color='primary'
                    onClick={() => window.location.reload()}
                >
                    {t('generic_retry')}
                </Button>
                <Button style={styles.button} href={`mailto:${emailSupport}`}>
                    {t('generic_contact_support')}
                </Button>
            </div>
        </div>
    );
};
