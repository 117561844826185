import { SerializedError } from '@reduxjs/toolkit';

import { ApiError } from '@api/common/utils';

// todo merge with another file in libs folder?
export type LoadingStatus = 'idle' | 'loading' | 'success' | 'failure';

export type LoadingStatusCancelable = LoadingStatus | 'cancel';

/** also store the data & error of previous loading */
export type LoadingState<Data, Error extends SerializedError = ApiError> =
    | {
          status: 'idle';
          data?: undefined;
          error?: undefined;
      }
    | {
          status: 'loading';
          data?: Data; // present if previous loading success
          error?: Error; // present if previous loading fail
      }
    | {
          status: 'success';
          data: Data;
          error?: Error; // present if previous loading fail
      }
    | {
          status: 'failure';
          data?: Data; // present if previous loading success
          error: Error;
      };

export function combineLoadingStatuses(...loadingStatuses: LoadingStatus[]): LoadingStatus {
    if (loadingStatuses.find((status) => status === 'failure')) return 'failure';
    else if (loadingStatuses.find((status) => status === 'loading')) return 'loading';
    else if (loadingStatuses.every((status) => status === 'success')) return 'success';
    else if (loadingStatuses.every((status) => status === 'idle')) return 'idle';
    else return 'loading';
}
