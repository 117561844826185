import {
    PaymentRequestList,
    PaymentRequestStatus,
} from '@components/admin/PaymentRequests/interfaces';

import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '../../common/utils';

export interface PaymentRequestsFilter {
    rowsPerPage: number;
    page: number;
    from?: string;
    to?: string;
    status?: PaymentRequestStatus;
    currency?: string;
    program?: string;
    affiliateProfile?: string;
    search?: string;
}

export default async function apiList(filter: PaymentRequestsFilter): Promise<PaymentRequestList> {
    const { rowsPerPage, page, from, to, status, program, affiliateProfile, currency, search } =
        filter;
    // add eventual filters

    const params = new URLSearchParams();
    params.append('limit', rowsPerPage.toString());
    params.append('offset', page <= 1 ? '0' : (rowsPerPage * (page - 1)).toString());

    if (from) params.append('from', from);
    if (to) params.append('to', to);
    if (status) params.append('status', status);
    if (program) params.append('program', program);
    if (affiliateProfile) params.append('affiliateProfile', affiliateProfile);
    if (currency) params.append('currency', currency);
    if (search) params.append('filter', search);

    const url = getAPIBaseUrl() + 'admin/paymentRequests.list?' + params.toString();

    const response = await fetch(url, {
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body.invoices;
}
