import { checkStatus, getAPIBaseUrl } from './utils';

export interface Category {
    _id: string;
    old_id: number;
    title_fr: string;
    title_en: string;
    title_es: string;
    slug_fr: string;
    slug_en: string;
    slug_es: string;
}

export async function fetchCategories() {
    const response = await fetch(getAPIBaseUrl() + 'common/programCategories.list');
    await checkStatus(response, 200);
    const body = await response.json();
    return body.categories as Category[];
}
