export const wait = (ms: number) =>
    new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
export function waitThenTrow(ms) {
    return wait(ms).then(() => {
        throw new Error(`timeout ${ms}`);
    });
}
