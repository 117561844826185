import { Event, EventType } from '@components/admin/Events/interfaces';

import { getJwt } from '@libs/jwt';

import { checkStatus, getAPIBaseUrl } from '../../common/utils';

export interface EventsFilter {
    limit: number;
    from?: string;
    to?: string;
    program?: string;
    profile?: string;
    type?: EventType;
    search?: string;
}

export default async function apiList(filter: EventsFilter): Promise<Event[]> {
    const { limit, from, to, program, profile, type, search } = filter;

    const params = new URLSearchParams();
    params.append('limit', limit.toString());

    if (from) params.append('from', from);
    if (to) params.append('to', to);
    if (program) params.append('program', program);
    if (profile) params.append('profile', profile);
    if (type) params.append('type', type);
    if (search) params.append('search', search);

    const url = getAPIBaseUrl() + 'admin/events.list?' + params.toString();

    const response = await fetch(url, {
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body;
}
