import {
    ADD_ADVERTISER_PRODUCT_IMAGE_FAILURE,
    ADVERTISER_PRODUCTS_LOADING,
    ADVERTISER_PRODUCTS_PRODUCTS_FILTER_CHANGE,
    CHECK_ADVERTISER_PRODUCTS_QUOTA_FAILURE,
    CHECK_ADVERTISER_PRODUCTS_QUOTA_SUCCESS,
    CLOSE_ALL_DIALOGS,
    CREATE_ADVERTISER_PRODUCT_FAILURE,
    CREATE_ADVERTISER_PRODUCT_SUCCESS,
    DELETE_ADVERTISER_PRODUCT_FAILURE,
    DELETE_ADVERTISER_PRODUCT_IMAGES_FAILURE,
    DELETE_ADVERTISER_PRODUCT_SUCCESS,
    FETCH_ADVERTISER_PRODUCT_REQUESTS_FAILURE,
    FETCH_ADVERTISER_PRODUCT_REQUESTS_SUCCESS,
    FETCH_ADVERTISER_PRODUCTS_FAILURE,
    FETCH_ADVERTISER_PRODUCTS_SUCCESS,
    OPEN_DELETE_PRODUCT_DIALOG,
    UPDATE_ADVERTISER_PRODUCT_FAILURE,
    UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_FAILURE,
    UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_SUCCESS,
    UPDATE_ADVERTISER_PRODUCT_STATUS_FAILURE,
    UPDATE_ADVERTISER_PRODUCT_STATUS_SUCCESS,
    UPDATE_ADVERTISER_PRODUCT_SUCCESS,
} from '@actions/advertiserProducts';

const productsEmptyQueryCriteria = {
    programs: [],
    status: '',
    search: '',
};

const productRequestsEmptyQueryCriteria = {
    programs: [],
    status: [],
    affiliateProfile: null,
    product: null,
    search: '',
};

const INITIAL_STATE = {
    loading: false,
    error: null,
    products: {
        data: null,
        queryCriteria: productsEmptyQueryCriteria,
        productsCount: null,
        productsLimit: null,
    },
    productRequests: {
        data: null,
        queryCriteria: productRequestsEmptyQueryCriteria,
    },
    deleteProductDialog: {
        isOpen: false,
        product: null,
    },
    customStatusFilter: '',
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADVERTISER_PRODUCTS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ADVERTISER_PRODUCTS_PRODUCTS_FILTER_CHANGE:
            return {
                ...state,
                customStatusFilter: action.payload,
            };

        case FETCH_ADVERTISER_PRODUCTS_SUCCESS:
        case UPDATE_ADVERTISER_PRODUCT_STATUS_SUCCESS:
        case UPDATE_ADVERTISER_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    data: action.payload,
                },
            };
        case CREATE_ADVERTISER_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    data: action.payload,
                    productsCount: state.products.productsCount + 1,
                },
            };
        case DELETE_ADVERTISER_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    data: action.payload,
                    productsCount: state.products.productsCount - 1,
                },
            };
        case FETCH_ADVERTISER_PRODUCT_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                productRequests: {
                    ...state.productRequests,
                    data: action.payload,
                },
            };

        case UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                productRequests: {
                    ...state.productRequests,
                    data: action.payload,
                },
            };
        case CHECK_ADVERTISER_PRODUCTS_QUOTA_SUCCESS:
            return {
                ...state,
                loading: false,
                products: {
                    ...state.products,
                    productsCount: action.payload.productsCount,
                    productsLimit: action.payload.productsLimit,
                },
            };
        case FETCH_ADVERTISER_PRODUCTS_FAILURE:
        case FETCH_ADVERTISER_PRODUCT_REQUESTS_FAILURE:
        case DELETE_ADVERTISER_PRODUCT_FAILURE:
        case UPDATE_ADVERTISER_PRODUCT_STATUS_FAILURE:
        case UPDATE_ADVERTISER_PRODUCT_REQUEST_STATUS_FAILURE:
        case CHECK_ADVERTISER_PRODUCTS_QUOTA_FAILURE:
        case ADD_ADVERTISER_PRODUCT_IMAGE_FAILURE:
        case DELETE_ADVERTISER_PRODUCT_IMAGES_FAILURE:
        case CREATE_ADVERTISER_PRODUCT_FAILURE:
        case UPDATE_ADVERTISER_PRODUCT_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case OPEN_DELETE_PRODUCT_DIALOG:
            return {
                ...state,
                deleteProductDialog: {
                    isOpen: true,
                    product: action.product,
                },
            };
        case CLOSE_ALL_DIALOGS:
            return {
                ...state,
                deleteProductDialog: {
                    isOpen: false,
                    product: null,
                },
            };
        default:
            break;
    }

    return state;
}
