import { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useIsOnBoardingComplete } from '@hooks/useIsOnBoardingComplete';

import { ParamTypes, skipTutorialStorageKey } from '@libs/getSharedVar';

/** path that comes after /:locale/:side */
export const onBoardingRoutingSubPath = '/steps';

/**  Redirect to onboarding if needed, return true if redirection happened */
export default function useOnBoardingRedirection(): boolean {
    const history = useHistory();
    const location = useLocation();
    const { path, url } = useRouteMatch<ParamTypes>(); // call before AppLogged routing, so path is '/:locale/:side'
    const matchStepperPage = useRouteMatch(path + onBoardingRoutingSubPath);
    const isOnBoardingPageDisplayed = matchStepperPage != null;
    const [hasVisitedOnBoarding, setHasVisitedOnBoarding] = useState(false);
    const isOnBoardingComplete = useIsOnBoardingComplete();
    const matchBillingPage = useRouteMatch(path + '/billing');

    const skipTutorialStorage = localStorage.getItem(skipTutorialStorageKey);
    const skipTutorial: boolean | null = skipTutorialStorage
        ? JSON.parse(skipTutorialStorage)
        : null;

    useEffect(() => {
        if (isOnBoardingPageDisplayed && hasVisitedOnBoarding === false) {
            setHasVisitedOnBoarding(true);
        }
    }, [hasVisitedOnBoarding, isOnBoardingPageDisplayed]);

    const shouldRedirectToOnBoarding =
        isOnBoardingComplete === false &&
        skipTutorial !== true &&
        !hasVisitedOnBoarding &&
        !CONFIG.disableRedirect &&
        !isOnBoardingPageDisplayed &&
        !matchBillingPage;

    if (shouldRedirectToOnBoarding) {
        history.replace({
            pathname: url + onBoardingRoutingSubPath,
            search: location.search, // preserve the query string, needed by example by email validation modal
        });
        return true;
    }

    return false;
}
