import { PartnershipList } from '@components/admin/Partnerships/interfaces';

import { getJwt } from '@libs/jwt';

import { PartnershipStatus } from '@api/common/partnerships/list';

import { checkStatus, getAPIBaseUrl } from '../../common/utils';

export interface PartnershipsFilter {
    rowsPerPage: number;
    page: number;
    from?: string;
    to?: string;
    status?: PartnershipStatus[];
    search?: string;
}

export default async function apiList(filter: PartnershipsFilter): Promise<PartnershipList> {
    const { rowsPerPage, page, from, to, status, search } = filter;

    const params = new URLSearchParams();
    params.append('limit', rowsPerPage.toString());
    params.append('offset', page <= 1 ? '0' : (rowsPerPage * (page - 1)).toString());

    if (from) params.append('from', from);
    if (to) params.append('to', to);
    if (status) {
        status.forEach((x) => {
            params.append('status', x);
        });
    }
    if (search) params.append('search', search);

    const url = getAPIBaseUrl() + 'admin/partnerships.list?' + params.toString();

    const response = await fetch(url, {
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body.partnerships;
}
