export const INIT_CURRENT_ADVERTISER = 'INIT_CURRENT_ADVERTISER';
export const ADVERTISER_SET_PROGRAMS = 'ADVERTISER_SET_PROGRAMS';

export function initCurrentAdvertiser(currentAdvertiser) {
    return {
        type: INIT_CURRENT_ADVERTISER,
        currentAdvertiser: currentAdvertiser,
    };
}

export function advertiserSetPrograms(programs) {
    return {
        type: ADVERTISER_SET_PROGRAMS,
        programs,
    };
}
